import * as React from 'react';
import {useMediaQuery} from "react-responsive";
import {Instance, types} from "mobx-state-tree";


export function MediaQueryHelper():IMediaQueryHelperModel {
    return MediaQueryHelperModel.create({
        desktopBigVersion: useMediaQuery({minWidth: 1378}),
        desktopVersion: useMediaQuery({minWidth: 1024}),
        mediumVersion: useMediaQuery({maxWidth: 1023, minWidth: 769}),
        smallVersion: useMediaQuery({maxWidth: 768})
    });
}

const MediaQueryHelperModel = types.model({
    desktopBigVersion: types.boolean,
    desktopVersion: types.boolean,
    mediumVersion: types.boolean,
    smallVersion: types.boolean,
})
interface IMediaQueryHelperModel extends Instance<typeof MediaQueryHelperModel> {
}