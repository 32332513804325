import React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import Header from '../components/HomeHeader';
import {withTheme} from "react-native-paper";
import {observer} from "mobx-react-lite";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import ProductListSortingSearch from "../components/ProductListSortingSearch";
import i18n from "i18n-js";
import {useNavigation} from "@react-navigation/native";
import {useMst} from "../models/RootModel";
import {runInAction} from "mobx";

const SearchResultPage = observer(() => {
    const state = useMst();
    const styles = StyleSheet.create({
        container: {
            backgroundColor: "white"
        },
        modal: {
            elevation: 0
        },
        product_page: {
            paddingHorizontal: 160, flexDirection: "row", justifyContent: "center", zIndex: -1
        }
    });
    const navigation = useNavigation();

    React.useEffect(() => {
        return navigation.addListener('blur', () => {
            console.log("ON BLUER!!!!");
            runInAction(() => {
                state.searchString.setValue("");
            })
        });
    }, [navigation, state]);

    return (
        <View style={styles.container}>
            <ScrollView style={{height: "100vh", paddingBottom: 32}}>
                <Header/>
                <View style={{paddingHorizontal: 160, zIndex: -1}}>
                    <ProductListSortingSearch title={i18n.t("common.pages.search")}/>
                </View>
                <View style={{zIndex: -1}}>
                    <Footer verticalMargin={50}/>
                    <Copyright/>
                </View>
            </ScrollView>
        </View>
    );
})

export default withTheme(SearchResultPage);