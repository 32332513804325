import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import i18n from '../translations/Translations';
import {observer} from "mobx-react-lite";
import {useMst} from "../models/RootModel";
import {OrderHandler} from "../models/OrderModel";
import CartSummary from "./CartSummary";
import {BreadCrumbModel} from "../models/BreadCrumbModel";
import {NetworkService} from "../services/NetworkService";
const CryptoJS = require("crypto-js");

export interface ChangePasswordProps {
    theme: ReactNativePaper.Theme,
    readonly?: boolean
}

const ChangePassword = observer((props: ChangePasswordProps) => {
    const state = useMst();
    const [oldPassword, setOldPassword] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [changePasswordError, setChangePasswordError] = React.useState("");
    const [messageColor, setMessageColor] = React.useState(props.theme.colors.button_color);

    function onOrderClick() {
        state.orderPopup.setIsPopupVisible(true);
    }

    function orderCancel() {
        const orderHandlerInstance = new OrderHandler(state);
        orderHandlerInstance.cancel();
    }

    const styles = StyleSheet.create({
        cancel_button: {
            fontSize: 9,
            alignSelf: "center",
            color: "white",
            fontFamily: props.theme.fontList.primaryFontBold,
            textTransform: "uppercase",
            letterSpacing: props.theme.cart.cartButtonTextLetterSpacing
        },
        change_password_wrapper: {
            maxWidth: 450
        },
        content_title: {
            fontFamily: props.theme.fontList.primaryFontBold,
            fontSize: 18,
            letterSpacing: 5.5,
            marginTop: 12,
        },
        change_password_description: {
            fontFamily: props.theme.fontList.primaryFontDefault,
            fontSize: 14,
            marginTop: 18,
            marginBottom: 4
        },
        change_password_input: {
            height: 36,
            width: 380,
            outlineColor: "#00000000",
            backgroundColor: "#eeeeee",
            paddingLeft: 16,
            paddingRight: 16,
            fontFamily: props.theme.fontList.primaryFontDefault,
            letterSpacing: 2
        },
        change_password_input_text: {
            fontFamily: props.theme.fontList.primaryFontDefault,
            fontSize: 15,
            letterSpacing: 2,
            marginBottom: 10,
            marginTop: 20
        },
        button: {
            backgroundColor: props.theme.colors.button_color,
            width: 380,
            height: 36
        },
        button_text: {
            color: "white",
            alignSelf: "center",
            marginVertical: "auto",
            fontFamily: props.theme.fontList.primaryFontBold,
            letterSpacing: 3
        },
        change_password_error: {
            marginTop: 28,
            marginBottom: 10,
            fontSize: 14,
            flexWrap: "wrap"
        }
    });



    function changePassword() {
        if (!oldPassword || !newPassword || !confirmPassword) {
            setMessageColor(props.theme.colors.button_color);
            setChangePasswordError(i18n.t("account.change_password.errors.empty"));
            return;
        }
        if (newPassword != confirmPassword) {
            setMessageColor(props.theme.colors.button_color);
            setChangePasswordError(i18n.t("account.change_password.errors.mismatch"));
            return;
        }

        NetworkService.postRequest("api/change_password", {
            id: state.customer.id,
            oldPassword: CryptoJS.SHA1(oldPassword).toString(),
            password: CryptoJS.SHA1(newPassword).toString()
        }).then(response => {
            if (response.status && [200, 201, 204].indexOf(response.status) === -1){
                setMessageColor(props.theme.colors.button_color);
                setChangePasswordError(i18n.t("account.change_password.errors.incorrect"));
                return;
            }
            setConfirmPassword("");
            setNewPassword("");
            setOldPassword("");

            setMessageColor("green");
            setChangePasswordError(i18n.t("account.change_password.success"));
        })
    }

    return (
        <ScrollView style={styles.change_password_wrapper}>
            <Text style={styles.content_title}>{i18n.t("account.change_password.title")}</Text>
            <Text
                style={styles.change_password_description}>{i18n.t("account.change_password.description")}</Text>
            <Text
                style={styles.change_password_input_text}>{i18n.t("account.change_password.old")}</Text>
            <TextInput style={styles.change_password_input}
                       secureTextEntry={true}
                       value={oldPassword}
                       onChangeText={text => {
                           setChangePasswordError("");
                           setOldPassword(text);
                       }}
            />
            <Text
                style={styles.change_password_input_text}>{i18n.t("account.change_password.new")}</Text>
            <TextInput style={styles.change_password_input}
                       secureTextEntry={true}
                       value={newPassword}
                       onChangeText={text => {
                           setChangePasswordError("");
                           setNewPassword(text);
                       }}
            />
            <Text
                style={styles.change_password_input_text}>{i18n.t("account.change_password.confirm")}</Text>
            <TextInput style={styles.change_password_input}
                       secureTextEntry={true}
                       value={confirmPassword}
                       onChangeText={text => {
                           setChangePasswordError("");
                           setConfirmPassword(text);
                       }}
            />
            <Text style={[styles.change_password_error, {color: messageColor}]}>{changePasswordError}</Text>
            <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} style={styles.button}
                              onPress={changePassword}>
                <Text style={styles.button_text}>{i18n.t("account.change_password.change")}</Text>
            </TouchableOpacity>
        </ScrollView>
    );
});

export default withTheme(ChangePassword);