import {types} from "mobx-state-tree";
import i18n from '../translations/Translations';
import {useMst} from "./RootModel";
import {decode} from 'html-entities';

export const TranslatedTextModel = types.model({
    "en-gb": "",
    "fr-fr": "",
    "pl-pl": "",
    "default" : ""
}).actions(self => ({

})).views(self => ({
    getTranslated(): string{
        if (self.default.length > 0){
            return  decode(self.default);
        }

        switch (i18n.locale){
            case "en":
                return decode(self["en-gb"].length > 0 ? self["en-gb"] : this.getFirstNotEmpty());
            case "pl":
                return decode(self["pl-pl"].length > 0 ? self["pl-pl"] : this.getFirstNotEmpty());
            case "fr":
                return decode(self["fr-fr"].length > 0 ? self["fr-fr"] : this.getFirstNotEmpty());
            default:
                return decode(self["fr-fr"].length > 0 ? self["fr-fr"] : this.getFirstNotEmpty());
        }
    },
    getFirstNotEmpty(): string {
        if (self["fr-fr"].length > 0) {
            return self["fr-fr"];
        } else if (self["en-gb"].length > 0) {
            return self["en-gb"];
        } else if (self["pl-pl"].length > 0) {
            return self["pl-pl"];
        }
        return "";
    }
}));