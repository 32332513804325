import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {View} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {useNavigation} from "@react-navigation/native";
import {IProductCategoryModel} from "../models/ProductCategoryModel";
import ProductCategory from "./ProductCategory";
import {OrderHandler} from "../models/OrderModel";
import Cart from "./Cart";

export interface CategoriesListProps {
    theme: ReactNativePaper.Theme,
}

const CategoriesList = observer((props: CategoriesListProps) => {
        const state = useMst();
        useNavigation();
        const [showCart, setCartState] = React.useState(false);
        const filteredCategories = state.categoriesStore.categories.filter((tmpCategory: IProductCategoryModel) => tmpCategory.getProducts(state.productsStore.products).length > 0);
        const categoriesArray = filteredCategories.map((category: IProductCategoryModel) => (
            <ProductCategory key={category.id + "_wrapper"} category={category} maxProducts={12}/>
        ));

        const orderHandler = new OrderHandler(state);
        if (state.customer.orderID.length > 0) {
            if (state.customer.orderID != state.currentOrder.id) {
                orderHandler.resumeOrder(state.customer.orderID, () => {
                    if (state.currentOrder.id.length > 0 && state.currentOrder.items.length > 0 && !showCart) {
                        setCartState(true)
                    }
                });
            } else {
                if (state.currentOrder.id.length > 0 && state.currentOrder.items.length > 0 && !showCart) {
                    setCartState(true)
                }
            }
        }

        console.log("SHOW CART! : " + showCart);

        return <View style={{flexDirection: "row"}}>
            <View style={{maxWidth: 500, marginRight: 30, marginTop: 50}}>
                {showCart && <Cart/>}
            </View>
            <View style={{alignSelf: "center"}}>
                {categoriesArray}
            </View></View>;
    }
);

export default withTheme(CategoriesList);