import React, {useLayoutEffect} from 'react';
import {ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import Header from '../components/ClientHeader';
import {withTheme} from "react-native-paper";
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import i18n from '../translations/Translations';
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import {useNavigation, useRoute} from "@react-navigation/native";
import {action, runInAction} from "mobx";
import {BreadCrumbModel} from "../models/BreadCrumbModel";
import BreadCrumbs from "../components/BreadCrumbs";
import ChangePassword from "../components/ChangePassword";
import MyOrders from "../components/MyOrders";
import MyAddresses from "../components/MyAddresses";
import {useMediaQuery} from "react-responsive";
import {MediaQueryHelper} from "../helpers/MediaQueryHelper";

export interface AccountPageRestaurantProps {
    theme: ReactNativePaper.Theme
}

interface routeParams {
    tab?: string
}

const AccountPageRestaurant = observer((props: AccountPageRestaurantProps) => {
    const state = useMst();
    const navigation = useNavigation();
    const route = useRoute();
    const mediaQueryHelper = MediaQueryHelper();
    const [selectedMenu, setSelectedMenu] = React.useState("");

    if (!state.account.loggedIn){
        navigation.navigate("home");
    }
    const [size, setSize] = React.useState(window.innerHeight - 320);
    useLayoutEffect(() => {
        function updateSize() {
            setSize(window.innerHeight - 320);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    React.useEffect(() => {
        return navigation.addListener('state', e => {
            if (route.params) {
                const routeParams = route.params as routeParams;
                if (routeParams.tab && routeParams.tab != selectedMenu) {
                    setSelectedMenu(routeParams.tab);
                } else if (!routeParams.tab && !selectedMenu.length) {
                    setSelectedMenu("orders");
                }
            } else {
                if (!selectedMenu.length) {
                    setSelectedMenu("orders");
                }
            }
        });
    }, [navigation, route]);

    state.customer.setLanguage(state.customer.language);

    const styles = StyleSheet.create({
        container: {
            backgroundColor: "white"
        },
        modal: {
            elevation: 0
        },
        options_wrapper: {
            flexDirection: "column"
        },
        option_text: {
            fontFamily: props.theme.fontList.primaryFontBold,
            fontSize: 14,
            letterSpacing: 3,
            marginBottom: 14
        },
        option_text_selected: {
            color: props.theme.colors.button_color
        },
        content_wrapper: {
            flexDirection: "column",
            marginLeft: mediaQueryHelper.desktopVersion ? "8%" : "unset"
        },
        page_content:{
            zIndex: -1,
            borderStyle: "solid",
            borderWidth: 1,
            marginTop: 40,
            height: size,
            flexDirection: mediaQueryHelper.desktopVersion ? "row" : "column",
            padding: 30,
            marginHorizontal: mediaQueryHelper.desktopBigVersion ? "15%" : mediaQueryHelper.desktopVersion ? "8%" : mediaQueryHelper.mediumVersion ? 50 : 10
        },
        title: {
            color: props.theme.colors.primary,
            backgroundColor: "white",
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontBlack,
            fontSize: 20,
            position: "absolute",
            top: -15,
            left: 15,
            paddingLeft: 8,
            paddingRight: 8
        }
    });

    function changeTab(v: string) {
        state.searchString.setValue("");
        navigation.navigate("account", {tab: v});
    }

    let breadCrumbsArray = [
        BreadCrumbModel.create({
            routeName: "account",
            label: i18n.t("common.pages.account"),
            params: {tab: "orders"}
        })
    ];

    if (selectedMenu.length > 0) {
        breadCrumbsArray.push(
            BreadCrumbModel.create({
                routeName: "account",
                label: i18n.t("account.menu." + selectedMenu),
                params: {tab: selectedMenu}
            })
        );
    }

    return (
        <View style={styles.container}>
            <ScrollView style={{height: "100vh", paddingBottom: 32}}>
                <Header/>
                <View style={styles.page_content}>
                    <Text style={styles.title}>{i18n.t("login_page.title")}</Text>
                    <View style={styles.options_wrapper}>
                        <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={() => {
                            changeTab("orders");
                        }}>
                            <Text
                                style={[styles.option_text, selectedMenu === "orders" && styles.option_text_selected]}>{i18n.t("account.menu.orders")}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={() => {
                            changeTab("addresses");
                        }}>
                            <Text
                                style={[styles.option_text, selectedMenu === "addresses" && styles.option_text_selected]}>{i18n.t("account.menu.addresses")}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={() => {
                            changeTab("password");
                        }}>
                            <Text
                                style={[styles.option_text, selectedMenu === "password" && styles.option_text_selected]}>{i18n.t("account.menu.password")}</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.content_wrapper}>
                        <BreadCrumbs breadCrumbs={breadCrumbsArray}/>
                        {selectedMenu === "" &&

                        <Text>TODO Main view of my account</Text>
                        }
                        {selectedMenu === "password" &&
                        <ChangePassword />
                        }
                        {selectedMenu === "orders" &&
                        <MyOrders/>
                        }
                        {selectedMenu === "addresses" &&
                        <MyAddresses/>
                        }
                    </View>

                </View>
            </ScrollView>
        </View>
    );
})

export default withTheme(AccountPageRestaurant);