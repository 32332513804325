import {Instance, types} from "mobx-state-tree";

export const AllergenModel = types.model({
    id: "",
    name: ""
}).actions(self => ({

})).views(self => ({
    getIcon(): string{
        return  "assets/icons/allergens/allergen_" + self.id + ".png";
    }
}));