import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {StyleSheet, TouchableOpacity, View, Text, FlatList} from 'react-native';
import {observer} from "mobx-react-lite";
import DisplayItem from "./GenericRectangleProductDisplayItem";
import {IProductModel} from "../models/ProductModel";
import i18n from "../translations/Translations";
import Entypo from "react-native-vector-icons/Entypo";

export interface ProductsGalleryProps {
    theme: ReactNativePaper.Theme,
    products: Array<IProductModel>,
    marginHorizontal: number,
    maxWidth: number
}

const ProductsGallery = observer((props: ProductsGalleryProps) => {
    const scrollRef = React.createRef<FlatList>();
    let itemIndex = 0;
    let productWidth = 182;
    let itemIndexOffSetValue = Math.ceil(props.maxWidth / (productWidth + props.marginHorizontal) / 2) +1;

    const styles = StyleSheet.create({
        container: {
            maxWidth: props.maxWidth ? props.maxWidth : 886,
            borderWidth: 1,
            borderColor: "black",
            borderStyle: "solid",
            marginTop: 50,
            paddingTop: 40,
            paddingBottom: 28,
        },
        title: {
            backgroundColor: "white",
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontBold,
            fontSize: 24,
            letterSpacing: 2.5,
            position: "absolute",
            paddingHorizontal: 18,
            top: -18,
            alignSelf: "center"
        },
        arrow: {
            position: "absolute",
            alignContent: "center",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.25)",
            top: 0,
            justifyContent: "center"
        },
        left_arrow: {
            left: 0
        },
        right_arrow: {
            right: 0
        }
    });

    function setItemIndex(newItemIndex: number) {
        if (newItemIndex <= 0) {
            itemIndex = 0;
            return;
        }
        if (newItemIndex + itemIndexOffSetValue >= props.products.length) {
            return;
        }
        itemIndex = newItemIndex;
    }


    function scrollLeft() {
        setItemIndex(itemIndex - 1);
        scrollRef.current?.scrollToIndex({animated: true, index: itemIndex});
    }

    function scrollRight() {
        setItemIndex(itemIndex + 1);
        scrollRef.current?.scrollToIndex({animated: true, index: itemIndex});
    }

    let renderItem = ({item, index}: { item: IProductModel, index: number }) => (
        (<DisplayItem key={item.id} product={item}
                      extraLeftViewWidth={props.marginHorizontal * 2 + (index == 0 ? 34 : 0)}
                      extraRightViewWidth={(index == props.products.length - 1 ? 70 : 0)} maxWidth={182}/>
        ));

    return (
        <View style={styles.container}>
            <FlatList data={props.products}
                      renderItem={renderItem}
                      keyExtractor={item => item.id}
                      horizontal={true} ref={scrollRef}
                      showsVerticalScrollIndicator={false}
                      showsHorizontalScrollIndicator={false}>
            </FlatList>
            <Text style={styles.title}>{i18n.t("products_proposition.title")}</Text>
            <TouchableOpacity style={[styles.arrow, styles.left_arrow]}
                              activeOpacity={props.theme.defaultTouchableOpacity}
                              onPress={scrollLeft}>
                <Entypo name="chevron-left" size={56}/>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.arrow, styles.right_arrow]}
                              activeOpacity={props.theme.defaultTouchableOpacity}
                              onPress={scrollRight}>
                <Entypo name="chevron-right" size={56}/>
            </TouchableOpacity>
        </View>
    );
});

export default withTheme(ProductsGallery);