import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {View} from 'react-native';
import {observer} from "mobx-react-lite";
import {IProductCategoryModel} from "../models/ProductCategoryModel";
import MenuEntry from "./MenuEntry";

export interface MenuProps {
    theme: ReactNativePaper.Theme,
    categories: Array<IProductCategoryModel>,
    centered?: boolean
}

const Menu = observer((props: MenuProps) => {
    const menuEntries = props.categories.map((category: IProductCategoryModel) => (
        <MenuEntry key={category.id + "_menu"} category={category} centered={props.centered}/>
    ));

    return (
        <View>
            {menuEntries}
        </View>
    );
});


export default withTheme(Menu);