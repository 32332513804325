import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {observer} from "mobx-react-lite";
import {useMst} from "../models/RootModel";
import {IOrderItemModel} from "../models/OrderItemModel";
import {OrderHandler} from "../models/OrderModel";
import {IOrderItemOptionModel} from "../models/OrderItemOptionModel";
import {IOrderItemAddonModel} from "../models/OrderItemAddonModel";

export interface CartItemClientProps {
    theme: ReactNativePaper.Theme,
    orderItem: IOrderItemModel,
    readonly?: boolean
}

const CartItemClient = observer((props: CartItemClientProps) => {
    const state = useMst();
    const plusIcon = require('../../assets/icons/plus.png');

    function orderItemRemove() {
        const orderHandlerInstance = new OrderHandler(state);
        orderHandlerInstance.removeOrderItem(props.orderItem);
    }

    function quantityIncrease() {
        const orderHandlerInstance = new OrderHandler(state);
        orderHandlerInstance.incrementProductQuantity(props.orderItem);
    }

    function quantityDecrease() {
        const orderHandlerInstance = new OrderHandler(state);
        orderHandlerInstance.decrementProductQuantity(props.orderItem);
    }

    const styles = StyleSheet.create({
        container: {
            flexDirection: "row",
            paddingHorizontal: 10,
            paddingVertical: 10,
            // width: "100%"
        },
        name: {
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            marginHorizontal: 10,
            fontSize: 16,
            flexWrap: "wrap",
            flexGrow: 1,
            width: "auto"
        },
        delete_button: {
            backgroundColor: "red",
            width: 25,
            height: 25
        },
        price_wrapper: {
            flexDirection: "column",
            marginLeft: "auto"
        },
        price_text: {
            alignSelf: "center",
            fontFamily: props.theme.fontList.primaryFontDefault,
            fontSize: 16
        },
        quantity_wrapper: {
            flexDirection: "row",
            alignSelf: "center"
        },
        quantity_btn: {
            backgroundColor: props.theme.colors.primary,
            width: 25,
            height: 25
        },
        button_text: {
            textAlignVertical: "center",
            fontFamily: props.theme.fontList.primaryFontDefault,
            color: "white",
            textAlign: "center",
            marginTop: 2
        },
        option_wrapper: {
            marginLeft: 30,
            flexDirection: "row",
        },
        option_plus_icon: {
            width: 5,
            height: 5,
            marginTop: 5
        },
        option_text: {
            marginLeft: 5,
            fontFamily: props.theme.fontList.primaryFontDefault,
            fontSize: 10
        },
        addon_text: {
            marginLeft: 30,
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            fontSize: 10
        }
    });

    return (
        <View style={styles.container}>
            <View style={{flexDirection: "column", flexBasis: "80%", flexGrow: 2}}>
                <View style={{flexDirection: "row", marginBottom: 5}}>
                    {!props.readonly &&
                    <TouchableOpacity style={styles.delete_button} onPress={orderItemRemove}
                                      activeOpacity={0.8}><Text style={styles.button_text}>x</Text></TouchableOpacity>
                    }
                    <Text
                        style={styles.name}>{props.orderItem.quantity}x {props.orderItem.product.name.getTranslated()}</Text>
                </View>
                {props.orderItem.options.map((option: IOrderItemOptionModel) => (
                    <View key={option.id} style={styles.option_wrapper}>
                        <Image style={styles.option_plus_icon} source={plusIcon}/>
                        <Text style={styles.option_text}>
                            {option.groupOption.option.name.getTranslated()}
                        </Text>
                    </View>
                ))}
                {props.orderItem.addons.map((addon: IOrderItemAddonModel) => (
                    <Text key={addon.id} style={styles.addon_text}>{addon.product.name.getTranslated()}</Text>
                ))}
            </View>
            <View style={styles.price_wrapper}>
                <Text
                    style={styles.price_text}>{props.orderItem.getPrice(state.clientStore.client.alwaysShowDecimals)}</Text>
                {!props.readonly &&
                <View style={styles.quantity_wrapper}>
                    <TouchableOpacity style={styles.quantity_btn} onPress={quantityDecrease}><Text
                        style={styles.button_text}>-</Text></TouchableOpacity>
                    <TouchableOpacity style={[styles.quantity_btn, {marginLeft: 5}]}
                                      onPress={quantityIncrease}><Text
                        style={styles.button_text}>+</Text></TouchableOpacity>
                </View>
                }
            </View>
        </View>
    );
});

export default withTheme(CartItemClient);