import { types, cast } from "mobx-state-tree";
import {AddonModel, IAddonModel} from "./AddonModel";

const ErrorPopupModel = types
    .model({
        isError: types.optional(types.boolean, false),
        errorMessage: ""
    })
    .actions(self => ({
        setErrorMessage(v: string){
          self.isError = true;
          self.errorMessage = v;
        },
        clear(){
            self.isError = false;
            self.errorMessage = "";
        }
    })).views(self => ({
    }))

let initialState = ErrorPopupModel.create();

export const errorPopupStore = initialState;

