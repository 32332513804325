import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {ScrollView, StyleSheet, Text, View} from 'react-native';
import i18n from '../translations/Translations';
import {observer} from "mobx-react-lite";
import {useMst} from "../models/RootModel";
import CartItemClient from "./CartItemClient";
import CartItemMarketplace from "./CartItemMarketplace";

export interface CartSummaryProps {
    theme: ReactNativePaper.Theme
    readonly?: boolean
}

const CartSummary = observer((props: CartSummaryProps) => {
    const state = useMst();

    const styles = StyleSheet.create({
        line: {
            width: "95%",
            height: 1,
            backgroundColor: "black",
            alignSelf: "center",
            marginVertical: 10
        },
        items_wrapper: {},
        total_wrapper: {
            paddingVertical: 4,
            paddingHorizontal: 20,
            flexDirection: "row",
            backgroundColor: props.theme.cart.totalBackgroundColor
        },
        total_title: {
            flex: 1,
            alignSelf: "flex-start",
            fontFamily: props.theme.fontList.primaryFontBold,
            fontSize: 18,
            marginVertical: "auto"
        },
        total_amount: {
            flex: 1,
            flexGrow: 1,
            alignSelf: "flex-end",
            textAlign: "right",
            fontFamily: props.theme.fontList.primaryFontBlack,
            fontSize: props.theme.cart.totalAmountFontSize,
            color: props.theme.colors.primary
        }
    });

    let cartItems = [];
    let total = []
    switch (props.theme.cart.style) {
        case "market_place":
            for (let i = 0; i < state.currentOrder.items.length; i++) {
                cartItems.push(<CartItemMarketplace key={state.currentOrder.items[i].id + "." + i}
                                                    readonly={props.readonly}
                                                    orderItem={state.currentOrder.items[i]}/>);
            }

            total.push(<View style={styles.total_wrapper} key={1}>
                <Text style={styles.total_title} key={2}>{i18n.t("cart.total")}</Text>
                <Text style={styles.total_amount} key={3}>{state.currentOrder.getPrice(state.clientStore.client.alwaysShowDecimals)}</Text>
            </View>)
            break;
        case "client":
            for (let i = 0; i < state.currentOrder.items.length; i++) {
                cartItems.push(<CartItemClient key={state.currentOrder.items[i].id + "." + i}
                                               readonly={props.readonly}
                                               orderItem={state.currentOrder.items[i]}/>);
            }
            total.push(<View style={styles.line} key={1}/>)
            total.push(<View style={styles.total_wrapper} key={2}>
                <Text style={styles.total_title}>{i18n.t("cart.total")}</Text>
                <Text style={styles.total_amount}>{state.currentOrder.getPrice(state.clientStore.client.alwaysShowDecimals)}</Text>
            </View>)
            if (!props.readonly) {
                total.push(<View style={styles.line} key={3}/>)
            }
            break;
    }

    return (<View>
            <ScrollView style={{maxHeight: "40vh"}}>
                {cartItems}
            </ScrollView>
            {total}
        </View>
    );
});

export default withTheme(CartSummary);