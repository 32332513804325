import React from 'react';
import {ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import Header from '../components/HomeHeader';
import {withTheme} from "react-native-paper";
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import i18n from '../translations/Translations';
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import {useNavigation, useRoute} from "@react-navigation/native";
import {action, runInAction} from "mobx";
import {BreadCrumbModel} from "../models/BreadCrumbModel";
import BreadCrumbs from "../components/BreadCrumbs";
import ChangePassword from "../components/ChangePassword";
import MyOrders from "../components/MyOrders";
import MyAddresses from "../components/MyAddresses";

export interface AccountPageProps {
    theme: ReactNativePaper.Theme
}

interface routeParams {
    tab?: string
}

const AccountPage = observer((props: AccountPageProps) => {
    const state = useMst();
    const navigation = useNavigation();
    const route = useRoute();
    const [selectedMenu, setSelectedMenu] = React.useState("");

    if (!state.account.loggedIn){
        navigation.navigate("home");
    }


    React.useEffect(() => {
        return navigation.addListener('state', e => {
            if (route.params) {
                const routeParams = route.params as routeParams;
                if (routeParams.tab && routeParams.tab != selectedMenu) {
                    setSelectedMenu(routeParams.tab);
                } else if (!routeParams.tab && !selectedMenu.length) {
                    setSelectedMenu("orders");
                }
            } else {
                if (!selectedMenu.length) {
                    setSelectedMenu("orders");
                }
            }
        });
    }, [navigation, route]);

    state.customer.setLanguage(state.customer.language);

    const styles = StyleSheet.create({
        container: {
            backgroundColor: "white"
        },
        modal: {
            elevation: 0
        },
        page_content: {
            paddingHorizontal: 160,
            paddingVertical: 14,
            flexDirection: "row",
            zIndex: -1
        },
        options_wrapper: {
            flexDirection: "column"
        },
        option_text: {
            fontFamily: props.theme.fontList.primaryFontBold,
            fontSize: 14,
            letterSpacing: 3,
            marginBottom: 14
        },
        option_text_selected: {
            color: props.theme.colors.button_color
        },
        content_wrapper: {
            flexDirection: "column",
            marginLeft: "8%"
        },
    });

    function changeTab(v: string) {
        state.searchString.setValue("");
        navigation.navigate("account", {tab: v});
    }

    let breadCrumbsArray = [
        BreadCrumbModel.create({
            routeName: "AccountPage",
            label: i18n.t("common.pages.account"),
            params: {tab: ""}
        })
    ];

    if (selectedMenu.length > 0) {
        breadCrumbsArray.push(
            BreadCrumbModel.create({
                routeName: "AccountPage",
                label: i18n.t("account.menu." + selectedMenu),
                params: {tab: selectedMenu}
            })
        );
    }

    return (
        <View style={styles.container}>
            <ScrollView style={{height: "100vh", paddingBottom: 32}}>
                <Header/>
                <View style={styles.page_content}>
                    <View style={styles.options_wrapper}>
                        <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={() => {
                            changeTab("password");
                        }}>
                            <Text
                                style={[styles.option_text, selectedMenu === "password" && styles.option_text_selected]}>{i18n.t("account.menu.password")}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={() => {
                            changeTab("orders");
                        }}>
                            <Text
                                style={[styles.option_text, selectedMenu === "orders" && styles.option_text_selected]}>{i18n.t("account.menu.orders")}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={() => {
                            changeTab("addresses");
                        }}>
                            <Text
                                style={[styles.option_text, selectedMenu === "addresses" && styles.option_text_selected]}>{i18n.t("account.menu.addresses")}</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.content_wrapper}>
                        <BreadCrumbs breadCrumbs={breadCrumbsArray}/>
                        {selectedMenu === "" &&

                        <Text>TODO Main view of my account</Text>
                        }
                        {selectedMenu === "password" &&
                        <ChangePassword />
                        }
                        {selectedMenu === "orders" &&
                        <MyOrders/>
                        }
                        {selectedMenu === "addresses" &&
                        <MyAddresses/>
                        }
                    </View>

                </View>
                <View style={{zIndex: -1}}>
                    <Footer verticalMargin={50}/>
                    <Copyright/>
                </View>
            </ScrollView>
        </View>
    );
})

export default withTheme(AccountPage);