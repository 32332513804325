import React from 'react';
import {ScrollView, StyleSheet, TextInput, TouchableOpacity, View} from 'react-native';
import Header from '../components/ClientHeader';
import {Text, withTheme} from "react-native-paper";
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {useMediaQuery} from "react-responsive";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Login from "../components/Login";
import i18n from "../translations/Translations";
import {NetworkService} from "../services/NetworkService";
const CryptoJS = require("crypto-js");

export interface ResetPasswordRestaurantProps {
    theme: ReactNativePaper.Theme
}

const ResetPasswordRestaurant = observer((props: ResetPasswordRestaurantProps) => {
    const state = useMst();
    const desktopVersion = useMediaQuery({minWidth: props.theme.desktopBreakpoint})

    const [password, setPassword] = React.useState("");
    const [repassword, setRePassword] = React.useState("");
    const [message, setMessage] = React.useState("");
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');

    const styles = StyleSheet.create({
        container: {
            backgroundColor: "white"

        },
        title: {
            color: props.theme.colors.primary,
            backgroundColor: "white",
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontBlack,
            fontSize: 20,
            position: "absolute",
            top: -15,
            left: 15,
            paddingLeft: 8,
            paddingRight: 8
        },
        input_label: {
            fontFamily: props.theme.fontList.primaryFontDefault,
            fontSize: 15,
            color: "#aaaaaa",
            letterSpacing: 2,
            marginBottom: 10,
            marginTop: 20
        },
        input: {
            alignSelf: "center",
            height: 36,
            width: "100%",
            outlineColor: "#00000000",
            backgroundColor: "#eeeeee",
            paddingLeft: 16,
            paddingRight: 16,
            fontFamily: props.theme.fontList.primaryFontDefault,
            letterSpacing: 2
        },
        logo: {
            width: 450,
            height: 200,
            marginBottom: 40,
            resizeMode: "contain",
            alignSelf: "center"
        },
        button: {
            marginHorizontal: 10,
            backgroundColor: props.theme.colors.button_color,
            width: "100%",
            height: 36
        },
        button_text: {
            color: "white",
            alignSelf: "center",
            marginVertical: "auto",
            fontFamily: props.theme.fontList.primaryFontBold,
            letterSpacing: 3
        },
        bottom_text_wrapper: {
            flexDirection: "row",
            marginTop: 30,
            width: "100%",
            justifyContent: "space-between"
        },
        login_error: {
            marginTop: 28,
            marginBottom: 10,
            fontSize: 14,
            alignSelf: "center",
            flexWrap: "wrap"
        },
        description: {
            fontFamily: props.theme.fontList.primaryFontDefault,
            fontSize: 14,
            marginTop: 18,
            marginBottom: 4
        },
        content: {
            width: 500,
            elevation: 4,
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: "center",
            zIndex: -1,
            borderStyle: "solid",
            borderWidth: 1,
            marginTop: 40,
            padding: 30,
            paddingHorizontal: 50
        },
        buttons: {
            paddingTop: 6,
            paddingHorizontal: 24,
            paddingBottom: 6,
        },
        buttons_text: {
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            fontSize: 16
        },
        cancel_button: {
            backgroundColor: "#6c6c6c",
        },
        cancel_button_text: {
            color: "#959595"
        }
    });

    function handleResponseError(code: number) {
        switch (code) {
            case 409:
                setMessage(i18n.t("reset_password.errors.token_expired"));
                break;
            case 406:
                setMessage(i18n.t("reset_password.errors.param"));
                break;
            case 404:
            default:
                setMessage(i18n.t("reset_password.errors.missing"));
                break;
        }
    }

    function resetPassword() {
        if (password.length < 4) {
            setMessage(i18n.t("reset_password.errors.not_filled"));
            return;
        }
        if (password != repassword) {
            setMessage(i18n.t("reset_password.errors.match"));
            return;
        }

        NetworkService.postRequest("api/account_reset_password", {
            token: token,
            password: CryptoJS.SHA1(password).toString()
        }).then((response) => {
            setPassword("");
            setRePassword("");
            if (response.status && response.status != 200 && response.status != 201) {
                handleResponseError(response.status);
                return;
            }
            setMessage(i18n.t("reset_password.success"));
        }).catch(error => {
            console.log("Could not add reset password");
            console.log(error);
            handleResponseError(error.status);
        })
    }

    return (
        <View style={styles.container}>
            <ScrollView style={{height: "100vh", paddingBottom: 32}}>
                <Header/>
                <View style={styles.content}>
                    <Text style={styles.title}>{i18n.t("reset_password.title")}</Text>
                    <Text style={styles.input_label}>{i18n.t("reset_password.new_password")}</Text>
                    <TextInput style={styles.input}
                               value={password}
                               secureTextEntry={true}
                               textContentType={"password"}
                               onChangeText={text => {
                                   setMessage("");
                                   setPassword(text);
                               }}
                    />
                    <Text style={styles.input_label}>{i18n.t("reset_password.renew_password")}</Text>
                    <TextInput style={styles.input}
                               value={repassword}
                               secureTextEntry={true}
                               textContentType={"password"}
                               onChangeText={text => {
                                   setMessage("");
                                   setRePassword(text);
                               }}
                    />
                    <Text style={styles.login_error}>{message}</Text>
                    <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} style={styles.button}
                                      onPress={resetPassword}>
                        <Text style={styles.button_text}>{i18n.t("reset_password.btn")}</Text>
                    </TouchableOpacity>
                    {/*<TouchableOpacity style={[styles.buttons, styles.cancel_button, {marginTop: 20}]} onPress={() => {*/}
                    {/*    history.back();*/}
                    {/*}} activeOpacity={props.theme.defaultTouchableOpacity}>*/}
                    {/*    <Text style={[styles.buttons_text, styles.cancel_button_text]}>{i18n.t("common.back")}</Text>*/}
                    {/*</TouchableOpacity>*/}
                </View>
            </ScrollView>
        </View>
    );
})

export default withTheme(ResetPasswordRestaurant);