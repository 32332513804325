export default {
    common: {
        cancel: "Anuluj",
        add: "Dodaj",
        edit: "edycja",
        save: "zapisz",
        back: "Wróć",
        confirm: "Potwierdź",
        ok: "OK",
        pages: {
            home: "Strona główna",
            cart: "Mój koszyk",
            account: "Moje konto",
            search: "Wyniki wyszukiwania"
        },
        more: "więcej",
        log_out: "Wyloguj"
    },
    homePage: {
        menu: "Menu"
    },
    header: {
        cart: "Mój koszyk",
        account: "Moje konto",
        logout: "Wyloguj",
        log_in: "Zaloguj",
        search_placeholder: "szukaj..."
    },
    cart: {
        title: "Twoje zamówienie",
        total: "W sumie",
        order: "Zamów!"
    },
    options_modal: {
        title: "Wybierz opcje",
        min: "min.",
        max: "max."
    },
    addons_modal: {
        title: "Wybierz dodatki"
    },
    order_modal: {
        title: "Zamów",
        contact_form: {
            title: "Twoje dane kontaktowe",
            firstname: "Imię",
            surname: "Nazwisko",
            email: "E-mail",
            phone: "Telefon"
        },
        collect: {
            title: "Odbierz o:",
            asap: "Tak szybko jak to możliwe"
        },
        payment: {
            title: "Płatność:",
            online: "Online",
            on_collect: "Przy odbiorze"
        },
        errors: {
            firstName: "Brak imienia",
            lastName: "Brak nazwy",
            email: "E-mail ma nieprawidłowy format",
            phone: "Brak numeru telefonu",
            street: "Brakująca ulica",
            postal: "Brak kodu pocztowego",
            city: "Brakujące miasto"
        }
    },
    product: {
        buttons: {
            buy: "kup teraz!"
        }
    },
    product_list: {
        load_more_button: "WIĘCEJ PRODUKTÓW"
    },
    products_proposition: {
        title: "Możesz Cię również zainteresować:"
    },
    footer: {
        copyright: "Copyright"
    },
    order_status_popup: {
        preparing: "Witam Pana/Panią %s %s\nRestauracja przyjeła zamówienie\nZamówienie będzie gotowe za:\n%s minut.",
        ready_for_pickup: "Pan/Pani %s %s,\n\nDziękujemy za cierpliwość.\nTwoje zamówienie nr %s jest gotowe.\nMożesz przyjść i je odebrać",
        waiting_approval: "Proszę czekać, restauracja potwierdzi Twoje zamówienie.",
        rejected: "Przepraszamy. Restauracja nie mogła przyjąć Twojego zamówienia. Prosimy o bezpośredni kontakt z restauracją."
    },
    sort_filter_model: {
        srtNameAsc: "Name A-Z",
        srtNameDesc: "Name Z-A",
        srtPriceAsc: "Lowest Price",
        srtPriceDesc: "Highest Price",
    },
    login_page: {
        title: "MOJE KONTO",
        login: "login",
        password: "hasło",
        sign_in: "ZALOGUJ SIĘ",
        create: "Utwórz konto",
        forgot: "Zapomniałeś hasła?",
        errors: {
            missing: "Proszę wypełnić oba pola",
            incorrect: "Niepoprawne dane logowania",
            inactive: "Konto nie jest aktywowane, sprawdź skrzynkę pocztową."
        }
    },
    order_page: {
        tittle: "MÓJ KOSZYK",
        cart_summary_headers: {
            product: "Produkt",
            price: "Cena",
            quantity: "Ilość",
            total: "W Sumie"
        },
        cart_price_summary: {
            sub_total: "Suma częściowa",
            shipping: "Dostawa",
            postal_code: "Twój kod pocztowy:",
            total: "W Sumie",
            order_button: "ZAMÓW!"
        }
    },
    my_address: {
        title: "mój adres",
        choose_button_text: "wybierać",
        name: "Nazwa",
        surname: "Nazwisko",
        email: "E-mail",
        phone: "Numer telefonu",
        address: "Adres",
        postal_code: "Kod pocztowy",
        city: "Miasto"
    },
    account: {
        menu: {
            password: "ZMIEŃ HASŁO",
            orders: "MOJE ZAMÓWIENIA",
            addresses: "MOJE ADRESY"
        },
        change_password: {
            title: "ZMIEŃ MOJE HASŁO",
            description: "Aby zmienić hasło, wpisz stare hasło, nowe hasło i potwierdź je, wpisując ponownie nowe hasło.",
            old: "stare hasło",
            new: "nowe hasło",
            confirm: "potwierdź nowe hasło",
            change: "ZMIEŃ!",
            errors: {
                empty: "Wypełnij wszystkie pola",
                incorrect: "Nieprawidłowe stare hasło",
                mismatch: "Proszę się upewnić, że hasła są takie same",
            },
            success: "Hasło zostało pomyślnie zmienione"
        },
        my_orders: {
            title: "MOJE ZAMÓWIENIA",
            table: {
                number: "Numer zamówienia",
                date: "Data",
                total: "Całkowita kwota",
                details: "detale",
            }
        },
        my_addresses: {
            title: "MOJE ADRESY",
            create_btn: "Dodaj adres",
            remove_btn: "usuń",
            city: "City",
            street: "Street",
            postal: "Postal",
            errors: {
                firstName: "Nieprawidłowe imię",
                name: "Nieprawidłowe nazwisko",
                email: "Nieprawidłowy email",
                phoneNumber: "Nieprawidłowy numer telefonu",
                street: "Nieprawidłowa ulica",
                postal: "Nieprawidłowy kod pocztowy",
                city: "Nieprawidłowe miasto",
                server: "Nie udało się zaktualizować adresu, skontaktuj się z obsługą",
            }
        },
        create_account: {
            title: "UTWÓRZ KONTO",
            btn: "WYSŁAĆ",
            description: "Wypełnij następujące pola",
            email: "e-mail",
            name: "imię",
            surname: "nazwisko",
            password: "hasło",
            rePassword: "Powtórz hasło",
            activation_info: "Link aktywacyjny został wysłany na Twój adres e-mail",
            error: {
                empty: "Musisz wypełnić wszystkie pola",
                email: "Nieprawidłowy format adresu e-mail",
                password_match: "Hasła się nie zgadzają",
                params: "Brak parametrów",
                already_used: "Adres e-mail już używany",
            }
        }
    },
    forgot_password: {
        title: "ZAPOMNIAŁEŚ HASŁA?",
        description: "Wypełnij swój e-mail, aby otrzymać wiadomość z linkiem do resetowania",
        email: "Twój email",
        btn: "WYŚLIJ",
        message: "E-mail wysłany, postępuj zgodnie z podanymi w nim instrukcjami",
        errors: {
            param: "Nieprawidłowy adres e-mail",
            not_found: "Nie znaleziono adresu e-mail",
            not_active: "Konto nie jest aktywowane, sprawdź swoją skrzynkę pocztową."
        }
    },
    articles_list: {
        title: "articles"
    },
    activate: {
        success: "Twoje konto zostało aktywowane. Możesz się teraz zalogować.",
        errors: {
            missing: "Token nie istnieje",
            missing_parameter: "Brak parametru tokena",
            expired: "Token już wygasł"
        }
    },
    reset_password: {
        title: "ZRESETUJ HASŁO",
        btn: "ZRESETUJ",
        new_password: "Nowe hasło",
        renew_password: "Potwierdź nowe hasło",
        success: "Hasło zmienione, możesz się teraz zalogować",
        errors: {
            not_filled: "Oba pola muszą być wypełnione i mieć co najmniej 4 znaki",
            match: "Hasła się nie zgadzają",
            token_expired: "Token już wygasł",
            missing: "Token nie istnieje",
            param: "Brak jednego z parametrów",
        }
    }
}