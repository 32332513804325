import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {StyleSheet, Text, TouchableOpacity} from 'react-native';
import {observer} from "mobx-react-lite";
import {IProductCategoryModel} from "../models/ProductCategoryModel";

export interface MenuEntryProps {
    theme: ReactNativePaper.Theme,
    category: IProductCategoryModel,
    centered?: boolean
}

const MenuEntry = observer((props: MenuEntryProps) => {

    function onMenuEntryPress(){
        window.location.href = "#" + props.category.id.replaceAll("_", "");
    }

    const styles = StyleSheet.create({
        title: {
            backgroundColor: "white",
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontBlack,
            fontSize: 21,
            cursor: "pointer",
            marginTop: 10,
            letterSpacing: 2
        },
    });

    return (
        <TouchableOpacity onPress={onMenuEntryPress}>
        <Text style={[styles.title, props.centered && {alignSelf: "center"}]}>{props.category.name.getTranslated()}</Text>
        </TouchableOpacity>
    );
});

export default withTheme(MenuEntry);