import {RootStore, useMst} from "../models/RootModel";
import {OrderHandler} from "../models/OrderModel";

export default class WebsocketService {
    _state;
    static instance: WebsocketService;
    _connection?: WebSocket;
    // _wsAddress = 'ws://' + window.location.hostname + ':8666';
    _wsAddress = 'wss://daniel_cnc_test.cncdev.aji.digital/websocket';


    constructor(state: RootStore) {
        if (location.protocol === 'https:') {
            this._wsAddress = 'wss://' + window.location.hostname + '/websocket';
        }
        this._state = state;
        this.connect();
    }

    static getInstance(state: RootStore) {
        if (WebsocketService.instance == null) {
            WebsocketService.instance = new WebsocketService(state);
        }

        this.instance.connect();

        return this.instance;
    }

    connect() {
        if (this._connection) {
            return;
        }
        this._connection = new WebSocket(this._wsAddress);
        const localThis = this;
        this._connection.onopen = function (event) {
            localThis.send({m: "wo", woid: localThis._state.currentOrder.id});
        }

        this._connection.onerror = function (event) {
            console.log("WEBSOCKET ERROR")
        }

        this._connection.onclose = function (event) {
        }

        this._connection.onmessage = function (event){
            let responseJSON = event.data;
            responseJSON = responseJSON.replace('^^^', '');
            responseJSON = responseJSON.replace('$$$', '');
            let response = JSON.parse(responseJSON);
            if (response.m && response.m == 'osc') {
                const orderHandlerInstance = new OrderHandler(localThis._state)
                orderHandlerInstance.refresh(true);
            }
        }
    }

    send(message: object) {
        if (this._connection) {
            this._connection.send('^^^' + JSON.stringify(message) + '$$$');
        }
    }

    close() {
        if (this._connection) {
            this._connection.close();
        }
    }


}