import {Instance, types} from "mobx-state-tree";

export const SearchStringModel = types.model({
    value: "",
}).actions(self => ({
    setValue(value: string) {
        if (self.value == value) {
            return;
        }
        console.log("SETTING SEARCH VALUE TO : " + value);
        self.value = value;
    }
})).views(self => ({}));

export interface ISearchStringModel extends Instance<typeof SearchStringModel> {
}