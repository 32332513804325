import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {observer} from "mobx-react-lite";
import {useMst} from "../models/RootModel";
import {IOrderItemModel} from "../models/OrderItemModel";
import {OrderHandler} from "../models/OrderModel";
import {IOrderItemOptionModel} from "../models/OrderItemOptionModel";
import {IOrderItemAddonModel} from "../models/OrderItemAddonModel";
import Entypo from 'react-native-vector-icons/Entypo';
import FontAwesome from "react-native-vector-icons/FontAwesome";
import CartItemClient from "./CartItemClient";

export interface CartItemMarketplaceProps {
    theme: ReactNativePaper.Theme,
    orderItem: IOrderItemModel,
    readonly?:boolean

}

const CartItemMarketplace = observer((props: CartItemMarketplaceProps) => {
    const state = useMst();
    const plusIcon = require('../../assets/icons/plus.png');

    function orderItemRemove() {
        const orderHandlerInstance = new OrderHandler(state);
        orderHandlerInstance.removeOrderItem(props.orderItem);
    }

    function quantityIncrease() {
        const orderHandlerInstance = new OrderHandler(state);
        orderHandlerInstance.incrementProductQuantity(props.orderItem);
    }

    function quantityDecrease() {
        const orderHandlerInstance = new OrderHandler(state);
        orderHandlerInstance.decrementProductQuantity(props.orderItem);
    }

    const styles = StyleSheet.create({
        container: {
            flexDirection: "row",
            paddingHorizontal: 10,
            paddingVertical: 10,
            width: "100%"
        },
        name: {
            fontFamily: props.theme.fontList.primaryFontBold,
            marginLeft: 10,
            fontSize: 16,
            flexWrap: "wrap",
            width: "auto",
            alignSelf: "center"
        },
        price_text: {
            fontFamily: props.theme.fontList.primaryFontBold,
            fontSize: 16,
            alignSelf: "flex-start",
            paddingTop: 4
        },
        quantity_wrapper: {
            flexDirection: "row",
            alignSelf: "flex-start",
            paddingLeft: 10
        },
        quantity_btn: {
            backgroundColor: props.theme.colors.primary,
            alignSelf: "center",
            justifyContent: "center",
            padding: 3
        },
        option_wrapper: {
            flexDirection: "row",
        },
        option_plus_icon: {
            width: 5,
            height: 5,
            marginTop: 5
        },
        option_text: {
            marginLeft: 5,
            fontFamily: props.theme.fontList.primaryFontDefault,
            fontSize: 12
        },
        addon_text: {
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            fontSize: 12
        }
    });


    return (
        <View style={styles.container}>
            <View style={{flexDirection: "column",flexBasis: "80%", flexGrow: 1}}>
                <View style={{flexDirection: "row", marginBottom: 0, alignContent: "center"}}>
                    {!props.readonly &&
                    <TouchableOpacity onPress={orderItemRemove}
                                      activeOpacity={0.8}><Entypo name="cross" color={props.theme.colors.button_color}
                                                                  size={28}/>
                    </TouchableOpacity>
                    }
                    <Text
                        style={styles.name}>{props.orderItem.quantity}x {props.orderItem.product.name.getTranslated()}</Text>
                </View>
                <View style={{marginLeft: 38}}>
                    {props.orderItem.options.map((option: IOrderItemOptionModel) => (
                        <View key={option.id} style={styles.option_wrapper}>
                            <Image style={styles.option_plus_icon} source={plusIcon}/>
                            <Text style={styles.option_text}>
                                {option.groupOption.option.name.getTranslated()}
                            </Text>
                        </View>
                    ))}
                    {props.orderItem.addons.map((addon: IOrderItemAddonModel) => (
                        <Text key={addon.id} style={styles.addon_text}>{addon.product.name.getTranslated()}</Text>
                    ))}
                    <Text style={styles.price_text}>{props.orderItem.getPrice(state.clientStore.client.alwaysShowDecimals)}</Text>
                </View>
            </View>
            {!props.readonly &&
            <View style={styles.quantity_wrapper}>
                <TouchableOpacity style={styles.quantity_btn} onPress={quantityDecrease}>
                    <Entypo name="minus" color={"white"} size={24}/></TouchableOpacity>
                <TouchableOpacity style={[styles.quantity_btn, {marginLeft: 8}]}
                                  onPress={quantityIncrease}><Entypo name="plus" color={"white"}
                                                                     size={24}/></TouchableOpacity>
            </View>
            }
        </View>
    );
});

export default withTheme(CartItemMarketplace);