import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {StyleSheet, Image, View, ImageBackground, TouchableOpacity} from 'react-native';
import i18n from '../translations/Translations';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {useMediaQuery} from "react-responsive";

export interface HeaderProps {
    theme: ReactNativePaper.Theme
}

const LanguagePicker = observer((props: HeaderProps) => {
        const state = useMst();

        const enIcon = require('../../assets/en.svg');
        const frIcon = require('../../assets/fr.svg');
        const plIcon = require('../../assets/pl.svg');
        const smallVersion = useMediaQuery({maxWidth: props.theme.smallBreakpoint});

        const size = 42;
        const iconSize = 36;
        const opacity = 0.9;

        const styles = StyleSheet.create({
            container: {
                flexDirection: smallVersion ? "column" : "row"
            },
            icon_wrapper: {
                width: size,
                height: size,
                borderRadius: size / 2,
                backgroundColor: "white",
                ...!smallVersion && {marginHorizontal: 5},
                ...smallVersion && {marginVertical: 5}
            },
            icon_wrapper_active: {
                backgroundColor: props.theme.colors.primary
            },
            icon: {
                width: iconSize,
                height: iconSize,
                alignSelf: "center",
                marginVertical: "auto"
            }
        });

        function onIconPress(lang: string) {
            state.customer.setLanguage(lang);
        }

        return (
            <View style={styles.container}>
                <TouchableOpacity activeOpacity={opacity}
                                  style={[styles.icon_wrapper, state.customer.language == "en" && styles.icon_wrapper_active]}
                                  onPress={() => {
                                      onIconPress("en")
                                  }}>
                    <Image style={styles.icon} source={enIcon}/>
                </TouchableOpacity>
                <TouchableOpacity activeOpacity={opacity}
                                  style={[styles.icon_wrapper, state.customer.language == "fr" && styles.icon_wrapper_active]}
                                  onPress={() => {
                                      onIconPress("fr")
                                  }}>
                    <Image style={styles.icon} source={frIcon}/>
                </TouchableOpacity>
                <TouchableOpacity activeOpacity={opacity}
                                  style={[styles.icon_wrapper, state.customer.language == "pl" && styles.icon_wrapper_active]}
                                  onPress={() => {
                                      onIconPress("pl")
                                  }}>
                    <Image style={styles.icon} source={plIcon}/>
                </TouchableOpacity>
            </View>
        );
    }
);


export default withTheme(LanguagePicker);