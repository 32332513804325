import React, {createContext} from 'react';
import {Provider, RootStore, useMst} from "./models/RootModel";
import {Provider as PaperProvider} from 'react-native-paper';
import {ClientTheme, MarketPlaceTheme} from './themes/AjiTheme';
import HomePage from "./pages/HomePage";
import ClientPage from "./pages/ClientPage";
import {useFonts} from 'expo-font';
import '@expo/match-media';
import LogRocket from 'logrocket';
import {createStackNavigator} from "@react-navigation/stack";
import {NavigationContainer, useNavigation} from "@react-navigation/native";
import CartPage from "./pages/CartPage";
import ProductPage from "./pages/ProductPage";
import LoginPage from "./pages/LoginPage";
import LoginPageRestaurant from "./pages/LoginPageRestaurant";
import AccountPage from "./pages/AccountPage";
import CategoryPage from "./pages/CategoryPage";
import SearchResultPage from "./pages/SearchResultPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import CreateAccountPage from "./pages/CreateAccountPage";
import AccountPageRestaurant from "./pages/AccountPageRestaurant";
import CreateAccountPageRestaurant from "./pages/CreateAccountPageRestaurant";
import ForgotPasswordPageRestaurant from "./pages/ForgotPasswordPageRestaurant";
import ActivationAccountPageRestaurant from "./pages/ActivationAccountPageRestaurant";
import ResetPasswordRestaurant from "./pages/ResetPasswordRestaurant";

LogRocket.init('dsfc92/marketplace');

const Stack = createStackNavigator();


export default function App() {
    let [fontsLoaded] = useFonts({
        'Poppins-Medium': require('../assets/fonts/Poppins/Poppins-Medium.ttf'),
        'Poppins-Regular': require('../assets/fonts/Poppins/Poppins-Regular.ttf'),
        'Poppins-Bold': require('../assets/fonts/Poppins/Poppins-Bold.ttf'),
        'Poppins-ExtraBold': require('../assets/fonts/Poppins/Poppins-ExtraBold.ttf'),
        'Poppins-Black': require('../assets/fonts/Poppins/Poppins-Black.ttf'),
        'Poppins-Light': require('../assets/fonts/Poppins/Poppins-Light.ttf'),
    });




    const config = {
        screens: {
            home: "home/:category?",
            cart: "cart",
            client_home: "restaurant",
            product_page: {
                path: "product/:id",
                parse: {
                    id: (id: string) => id
                }
            },
            login: "login",
            account: "account/:tab?",
            category: "category/:id",
            search_result_page: "search",
            forgot_password: "forgot_password",
            create_account: "create_account"
        },
    };


    const configRestaurant = {
        screens: {
            home: "home/:id?",
        },
    };


    const linking = {
        prefixes: [window.location.origin + "/"],
        config,
    };


    const linkingRestaurant = {
        prefixes: [window.location.origin + "/"],
        configRestaurant,
    };


    if (!fontsLoaded) {
        // Avoid displaying unloaded fonts
        return null;
    } else if (window.location.hostname.split('.')[0] != 'marketplace') {
        return (
            <Provider value={new RootStore}>
                <PaperProvider theme={ClientTheme}>
                    <NavigationContainer linking={linkingRestaurant}>
                        <Stack.Navigator initialRouteName={"home"} screenOptions={{headerShown: false}}>
                            <Stack.Screen name={"home"} options={{title: "Home"}}>
                                {props => <ClientPage theme={ClientTheme}/>}
                            </Stack.Screen>
                            <Stack.Screen name={"login"} options={{title: "Login"}} component={LoginPageRestaurant}/>
                            <Stack.Screen name={"account"} options={{title: "Account"}} component={AccountPageRestaurant}/>
                            <Stack.Screen name={"forgot_password"} options={{title: "Forgot password"}} component={ForgotPasswordPageRestaurant}/>
                            <Stack.Screen name={"create_account"} options={{title: "Create account"}} component={CreateAccountPageRestaurant}/>
                            <Stack.Screen name={"activate"} options={{title: "Activate account"}} component={ActivationAccountPageRestaurant}/>
                            <Stack.Screen name={"reset_password"} options={{title: "Reset password"}} component={ResetPasswordRestaurant}/>
                        </Stack.Navigator>
                    </NavigationContainer>
                </PaperProvider>
            </Provider>
        );
    } else {
        return (
            <Provider value={new RootStore}>
                <PaperProvider theme={MarketPlaceTheme}>
                    <NavigationContainer linking={linking}>
                        <Stack.Navigator initialRouteName={"home"} screenOptions={{headerShown: false}}>
                            <Stack.Screen name={"home"} component={HomePage}/>
                            <Stack.Screen name={"client_home"}>
                                {props => <ClientPage theme={ClientTheme}/>}
                            </Stack.Screen>
                            <Stack.Screen name={"cart"} component={CartPage}/>
                            <Stack.Screen name={"product_page"} component={ProductPage}/>
                            <Stack.Screen name={"login"} component={LoginPage}/>
                            <Stack.Screen name={"account"} component={AccountPage}/>
                            <Stack.Screen name={"category"} component={CategoryPage}/>
                            <Stack.Screen name={"search_result_page"} component={SearchResultPage}/>
                            <Stack.Screen name={"forgot_password"} component={ForgotPasswordPage}/>
                            <Stack.Screen name={"create_account"} component={CreateAccountPage}/>
                        </Stack.Navigator>
                    </NavigationContainer>
                </PaperProvider>
            </Provider>
        );
    }
}
