import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {Text} from 'react-native-paper';
import {StyleSheet, Image, View, TouchableOpacity} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";

import {IProductModel} from "../models/ProductModel";
import {useNavigation} from "@react-navigation/native";

export interface ProductProps {
    theme: ReactNativePaper.Theme,
    product: IProductModel,
    dummy?: boolean,
    marginHorizontal?: number,
    extraLeftViewWidth?: number,
    extraRightViewWidth?: number,
    maxWidth?: number,
}

const DisplayItem = observer((props: ProductProps) => {
    const state = useMst();
    const navigate = useNavigation();

    function onProductPress() {
        navigate.navigate("product_page", {id: props.product.id});


        // state.setCurrentProduct(props.product);
        // if (props.product.optionsGroups.length > 0) {
        //     state.optionsPopup.setFromAddon(false);
        //     state.optionsPopup.setIsPopupVisible(true);
        // } else {
        //     const orderHandlerInstance = new OrderHandler(state);
        //     if (state.currentOrder.id.length === 0){
        //         orderHandlerInstance.initialize(onProductPress);
        //         return;
        //     }
        //     orderHandlerInstance.addProduct(props.product.id, [], () => {
        //         state.productsStore.loadAddons(props.product, (addons: Array<IAddonModel>) => {
        //             if (addons.length > 0) {
        //                 state.addonsPopup.setAddons(addons);
        //                 state.addonsPopup.setIsPopupVisible(true);
        //             }
        //         });
        //     })
        // }
    }

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            flexGrow: 1,
            minWidth: 182,
            marginHorizontal: props.marginHorizontal ?? 0,
            maxWidth: props.maxWidth ?? undefined
        },
        notDummy: {
            cursor: "pointer",
            padding: 5
        },
        product_image: {
            minWidth: 182,
            minHeight: 182,
        },
        text_container: {
            padding: 4
        },
        product_name: {
            fontSize: 16,
            fontFamily: props.theme.fontList.primaryFontDefault,
            lineHeight: 20
        },
        price_text: {
            fontSize: 20,
            fontFamily: props.theme.fontList.primaryFontBold,
            lineHeight: 24
        },
        discount_container: {
            flexDirection: "row"
        },
        old_price: {
            lineHeight: 18,
            fontSize: 16,
            textDecorationLine: 'line-through', textDecorationStyle: 'solid',
            fontFamily: "Poppins-Regular",
        },
        discount_text: {
            lineHeight: 18,
            marginLeft: 8,
            fontSize: 16,
            fontFamily: props.theme.fontList.primaryFontDefault,
            color: "#ed6908",
        }
    });

    let product = <View style={styles.container}/>;

    if (!props.dummy) {
        let discount;
        let price = props.product.getFormatedPrice(props.product.getPriceValue() * ((100 - props.product.discount) / 100), state.clientStore.client.alwaysShowDecimals);
        if (props.product.discount > 0) {
            discount = <View style={styles.discount_container}>
                <Text
                    style={styles.old_price}>{props.product.getPrice(state.clientStore.client.alwaysShowDecimals)}</Text>
                <Text style={styles.discount_text}>{props.product.discount}%</Text>
            </View>;
        }

        product = <View style={{flexDirection: "row", flex: 1, flexGrow: 1, minWidth: 182}}>
            <View style={{width: props.extraLeftViewWidth}}/>
            <View style={[styles.container, styles.notDummy]}>
                <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={onProductPress}>
                    <View>
                        <Image style={styles.product_image} source={{uri: props.product.getImage()}}/>
                        <View style={styles.text_container}>
                            <Text numberOfLines={2} ellipsizeMode='tail'
                                  style={styles.product_name}>{props.product.name.getTranslated()}</Text>
                            <Text style={styles.price_text}>{price}</Text>
                            {discount}
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
            <View style={{width: props.extraRightViewWidth}}/>
        </View>
    }

    return product;
});


export default withTheme(DisplayItem);