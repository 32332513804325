import {NetworkService} from "../services/NetworkService";
import {flow, Instance, types} from "mobx-state-tree";
import {makeAutoObservable} from "mobx";
import {runInAction} from "mobx";
import {TranslatedTextModel} from "./TranslatedTextModel";
import {AllergenModel} from "./AllergenModel";
import {PriceModel} from "./PriceModel";
import {ProductCategoryModel} from "./ProductCategoryModel";
import {OptionGroupModel} from "./OptionGroupModel";
import {OptionModel} from "./OptionModel";
import {AddonModel, IAddonModel} from "./AddonModel";

export class ProductsStore {
    products = [ProductModel.create()];

    constructor() {

        makeAutoObservable(this);
        this.loadProducts();
    }

    loadProducts() {
        NetworkService.getRequest("api/products").then((productsJson:Array<IProductModel>) => {
            runInAction(() => {
                this.products = [];
                for (let i = 0; i < productsJson.length; i++) {
                    let product = productsJson[i];
                    if (!Object.keys(product.description).length){
                        product.description = TranslatedTextModel.create({});
                    }
                    this.products.push(ProductModel.create(product))
                }
            })
        }).catch(error => {
            console.log("Could not get products");
            console.log(error);
        })
    }

    loadAddons(product: IProductModel, callback?: (addons: Array<IAddonModel>) => void ){
        NetworkService.getRequest("api/product_addons?product=" + product.id).then((response:Array<IAddonModel>) => {
            runInAction(() => {
                let addons = [];
                for (let i = 0; i < response.length; i++) {
                    let addon = response[i];
                    if (!Object.keys(addon.addon.description).length){
                        addon.addon.description = TranslatedTextModel.create({});
                    }
                    addons.push(AddonModel.create(addon))
                }

                if (typeof callback === "function") {
                    callback(addons);
                }
            })
        }).catch(error => {
            console.log("Could not get addons");
            console.log(error);
        })
    }
}


export const ProductModel = types.model({
    id: "",
    name: types.optional(TranslatedTextModel, {}),
    description: types.optional(TranslatedTextModel, {} || []),
    allergens: types.array(AllergenModel),
    price: 0,
    priceCur: "",
    category: types.optional(ProductCategoryModel, {}),
    isAvailable: true,
    index: 0,
    image: "",
    optionsGroups: types.array(OptionGroupModel),
    discount : 0,
}).actions((self) => ({
    setAddons(){

    }
})).views((self => ({
    getPrice(alwaysShowDecimals: boolean): string{
        let price = PriceModel.create({price: self.price, currency: self.priceCur});
        return price.getFormatedPrice(alwaysShowDecimals);
    },
    getImage():string{
        if (!self.image){
            return "";
        }
        return NetworkService.boBaseURL + self.image;
    },
    getPriceValue(): number{
        return self.price;
    },
    getFormatedPrice(amount:number,alwaysShowDecimals: boolean): string{
        let price = PriceModel.create({price: amount, currency: self.priceCur});
        return price.getFormatedPrice(alwaysShowDecimals);
    }
})));

export interface IProductModel extends Instance<typeof ProductModel>{}