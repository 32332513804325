import {Instance, types} from "mobx-state-tree";
import {useNavigation} from "@react-navigation/native";


export const BreadCrumbModel = types.model({
    routeName: "",
    label: "",
    params: types.optional(types.frozen(), {})
}).actions(self => ({

})).views(self => ({

}));

export interface IBreadCrumbModel extends Instance<typeof BreadCrumbModel>{}