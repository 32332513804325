import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {Text} from 'react-native-paper';
import {StyleSheet, Image, View, TouchableOpacity} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {IAddonModel} from "../models/AddonModel";
import {useMediaQuery} from "react-responsive";

export interface AddonProps {
    theme: ReactNativePaper.Theme,
    addon: IAddonModel
}

const Addon = observer((props: AddonProps) => {
    const state = useMst();
    const desktopVersion = useMediaQuery({minWidth: props.theme.desktopBreakpoint});
    const smallVersion = useMediaQuery({maxWidth: props.theme.smallBreakpoint});

    const localProduct = props.addon.getProduct();

    function onAddonPress() {
        if (props.addon.selected) {
            props.addon.toggleSelected();
        } else {
            if (localProduct.optionsGroups.length > 0) {
                state.addonsPopup.setIsPopupVisible(false);
                state.setCurrentProduct(localProduct);
                state.optionsPopup.setFromAddon(true);
                state.optionsPopup.setAddonID(props.addon.id);
                state.optionsPopup.setIsPopupVisible(true);
            } else {
                props.addon.toggleSelected();
            }
        }
    }

    const styles = StyleSheet.create({
        container: {
            flexDirection: "row",
            padding: 20,
            width: "100%",
            cursor: "pointer"
        },
        selected: {
            backgroundColor: "#f6ecce"
        },
        addon_image: {
            width: 120,
            height: 120,
            borderRadius: 100
        },
        addon_info: {
            flexBasis: 500,
            paddingLeft: 20,
            paddingTop: 10,
            flexShrink: 2
        },
        addon_name: {
            fontSize: 16,
            fontWeight: "900",
            fontFamily: props.theme.fontList.primaryFontBold,
            textTransform: "uppercase"
        },
        addon_description: {
            fontSize: 12,
            fontWeight: "300",
            fontFamily: props.theme.fontList.primaryFontMedium,
            fontStyle: "italic"
        },
        black_line: {
            width: "90%",
            height: 2,
            backgroundColor: "black",
            marginTop: 5,
            marginBottom: 10
        },
        allergens_container: {
            flexDirection: "row",
            flexWrap: "wrap",
            paddingTop: 5
        },
        allergen_icon: {
            width: 30,
            height: 30,
            filter: props.theme.allergenColorFilter,
            marginTop: 5
        },
        price_view: {
            paddingTop: 20,
            paddingLeft: 10
        },
        price_text: {
            fontSize: 20,
            fontFamily: props.theme.fontList.primaryFontMedium
        }
    });

    return (
        <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={onAddonPress}>
            <View style={[styles.container, props.addon.selected && styles.selected]}>
                {!smallVersion &&
                <Image style={styles.addon_image} source={{uri: props.addon.addon.getImage()}}/>
                }
                <View style={styles.addon_info}>
                    <Text style={styles.addon_name}>{props.addon.addon.name.getTranslated()}</Text>
                    <View style={styles.black_line}/>
                    <Text style={styles.addon_description}>{props.addon.addon.description.getTranslated()}</Text>
                    <View style={styles.allergens_container}>
                        {localProduct.allergens.map(allergen => (
                            <Image style={styles.allergen_icon} key={allergen.id}
                                   source={require("../../" + allergen.getIcon())}/>
                        ))}
                    </View>
                </View>
                <View style={styles.price_view}>
                    <Text style={styles.price_text}>{props.addon.getPrice(state.clientStore.client.alwaysShowDecimals)}</Text>
                </View>
            </View>
        </TouchableOpacity>
    );
});


export default withTheme(Addon);