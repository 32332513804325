import React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import Header from '../components/ClientHeader';
import {Text, withTheme} from "react-native-paper";
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {useMediaQuery} from "react-responsive";
import i18n from '../translations/Translations';
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import {OrderHandler} from "../models/OrderModel";
import ProductListSortingSearch from "../components/ProductListSortingSearch";
import OrderPage from "../components/OrderPage";
import Login from "../components/Login";

export interface LoginPageRestaurantProps {
    theme: ReactNativePaper.Theme
}

const LoginPageRestaurant = observer((props: LoginPageRestaurantProps) => {
    const state = useMst();
    const desktopVersion = useMediaQuery({minWidth: props.theme.desktopBreakpoint})


    const styles = StyleSheet.create({
        container: {
            backgroundColor: "white"
        },
        login_wrapper:{
            zIndex: -1,
            borderStyle: "solid",
            borderWidth: 1,
            alignSelf: "center",
            marginTop: 40,
            padding: 30
        },
        title: {
            color: props.theme.colors.primary,
            backgroundColor: "white",
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontBlack,
            fontSize: 20,
            position: "absolute",
            top: -15,
            left: 15,
            paddingLeft: 8,
            paddingRight: 8
        }
    });


    return (
            <View style={styles.container}>
                <ScrollView style={{height: "100vh", paddingBottom: 32}}>
                    <Header topMarginEnabled={false}/>
                    <View style={styles.login_wrapper}>
                        <Text style={styles.title}>{i18n.t("login_page.title")}</Text>
                        <Login withoutTitle={true}/>
                    </View>
                </ScrollView>
            </View>
    );
})

export default withTheme(LoginPageRestaurant);