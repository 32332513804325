import React from 'react';
import {ScrollView, StyleSheet, TextInput, TouchableOpacity, View} from 'react-native';
import Header from '../components/HomeHeader';
import {Text, withTheme} from "react-native-paper";
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {useMediaQuery} from "react-responsive";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Login from "../components/Login";
import i18n from "../translations/Translations";
import {NetworkService} from "../services/NetworkService";
import {useNavigation} from "@react-navigation/native";
const CryptoJS = require("crypto-js");

export interface CreateAccountPageProps {
    theme: ReactNativePaper.Theme
}

const CreateAccountPage = observer((props: CreateAccountPageProps) => {
    const state = useMst();
    const navigation = useNavigation();
    const desktopVersion = useMediaQuery({minWidth: props.theme.desktopBreakpoint})

    const [email, setEmail] = React.useState("");
    const [name, setName] = React.useState("");
    const [surname, setSurname] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [rePassword, setRePassword] = React.useState("");
    const [message, setMessage] = React.useState("");

    const styles = StyleSheet.create({
        container: {
            backgroundColor: "white"

        },
        modal: {
            elevation: 0
        },
        product_page: {
            paddingHorizontal: 160, flexDirection: "row", justifyContent: "center", zIndex: -1
        },
        title: {
            fontFamily: props.theme.fontList.primaryFontBold,
            fontSize: 20,
            letterSpacing: 4,
            marginBottom: 16
        },
        input_label: {
            fontFamily: props.theme.fontList.primaryFontDefault,
            fontSize: 15,
            color: "#aaaaaa",
            letterSpacing: 2,
            marginBottom: 10,
            marginTop: 20
        },
        input: {
            alignSelf: "center",
            height: 36,
            width: "100%",
            outlineColor: "#00000000",
            backgroundColor: "#eeeeee",
            paddingLeft: 16,
            paddingRight: 16,
            fontFamily: props.theme.fontList.primaryFontDefault,
            letterSpacing: 2
        },
        logo: {
            width: 450,
            height: 200,
            marginBottom: 40,
            resizeMode: "contain",
            alignSelf: "center"
        },
        button: {
            marginHorizontal: 10,
            backgroundColor: props.theme.colors.button_color,
            width: "100%",
            height: 36
        },
        button_text: {
            color: "white",
            alignSelf: "center",
            marginVertical: "auto",
            fontFamily: props.theme.fontList.primaryFontBold,
            letterSpacing: 3
        },
        bottom_text_wrapper: {
            flexDirection: "row",
            marginTop: 30,
            width: "100%",
            justifyContent: "space-between"
        },
        login_error: {
            marginTop: 28,
            marginBottom: 10,
            fontSize: 14,
            alignSelf: "center",
            flexWrap: "wrap",
            color: props.theme.colors.button_color
        },
        description: {
            fontFamily: props.theme.fontList.primaryFontDefault,
            fontSize: 14,
            marginTop: 18,
            marginBottom: 4
        },
        content: {
            width: 420,
            elevation: 4,
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: "center",
            zIndex: -1
        }
    });


    function createAccount() {
        if (!email.length || !name.length || !surname.length || !password.length || !rePassword.length) {
            setMessage(i18n.t("account.create_account.error.empty"));
            return;
        }

        // Validate email
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!reg.test(email)){
            setMessage(i18n.t("account.create_account.error.email"));
            return;
        }

        // Validate passwords
        if (password != rePassword){
            setMessage(i18n.t("account.create_account.error.password_match"));
            return;
        }

        NetworkService.postRequest("api/create_account", {
            email: email,
            name: name,
            surname: surname,
            password: CryptoJS.SHA1(password).toString()
        }).then(response => {
            if (response.status){
                switch (response.status){
                    case 406:
                        setMessage(i18n.t("account.create_account.error.params"));
                        break;
                    case 409:
                        setMessage(i18n.t("account.create_account.error.already_used"));
                        break;
                }
                return;
            }
            setEmail("");
            setName("");
            setSurname("");
            setPassword("");
            setRePassword("");
            navigation.navigate("login");
        })
    }

    return (
        <View style={styles.container}>
            <ScrollView style={{height: "100vh", paddingBottom: 32}}>
                <Header/>
                <View style={styles.content}>
                    <Text style={styles.title}>{i18n.t("account.create_account.title")}</Text>
                    <Text style={styles.description}>{i18n.t("account.create_account.description")}</Text>
                    <Text style={styles.input_label}>{i18n.t("account.create_account.email")}</Text>
                    <TextInput style={styles.input}
                               value={email}
                               textContentType={"emailAddress"}
                               onChangeText={text => {
                                   setMessage("");
                                   setEmail(text);
                               }}
                    />
                    <Text style={styles.input_label}>{i18n.t("account.create_account.name")}</Text>
                    <TextInput style={styles.input}
                               value={name}
                               textContentType={"name"}
                               onChangeText={text => {
                                   setMessage("");
                                   setName(text);
                               }}
                    />
                    <Text style={styles.input_label}>{i18n.t("account.create_account.surname")}</Text>
                    <TextInput style={styles.input}
                               value={surname}
                               textContentType={"familyName"}
                               onChangeText={text => {
                                   setMessage("");
                                   setSurname(text);
                               }}
                    />
                    <Text style={styles.input_label}>{i18n.t("account.create_account.password")}</Text>
                    <TextInput style={styles.input}
                               value={password}
                               secureTextEntry={true}
                               textContentType={"password"}
                               onChangeText={text => {
                                   setMessage("");
                                   setPassword(text);
                               }}
                    />
                    <Text style={styles.input_label}>{i18n.t("account.create_account.rePassword")}</Text>
                    <TextInput style={styles.input}
                               value={rePassword}
                               secureTextEntry={true}
                               textContentType={"password"}
                               onChangeText={text => {
                                   setMessage("");
                                   setRePassword(text);
                               }}
                    />
                    <Text style={styles.login_error}>{message}</Text>
                    <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} style={styles.button}
                                      onPress={createAccount}>
                        <Text style={styles.button_text}>{i18n.t("account.create_account.btn")}</Text>
                    </TouchableOpacity>
                </View>
                <View style={{zIndex: -1}}>
                    <Footer verticalMargin={50}/>
                    <Copyright/>
                </View>
            </ScrollView>
        </View>
    );
})

export default withTheme(CreateAccountPage);