import {DefaultTheme as PaperDefaultTheme} from 'react-native-paper';
import merge from 'deepmerge';
import {FlexAlignType} from "react-native";

type CartStyle = 'market_place' | 'client';

declare global {
    namespace ReactNativePaper {
        //@used to extend colors interface from -> react-native-paper
        interface ThemeColors {
            secondary: string,
            button_color: string,
        }

        interface FontList {
            primaryFontDefault: string,
            primaryFontLight: string,
            primaryFontMedium: string,
            primaryFontBold: string,
            primaryFontExtraBold: string,
            primaryFontBlack: string,
        }

        interface Cart {
            style: CartStyle,
            cartTitleFont: string,
            cartTitleSize: number,
            cartTitleAlignSelf: FlexAlignType,
            cartTitlePaddingHorizontal: number,
            cartTitleMarginHorizontal: number,
            totalAmountFontSize: number,
            totalBackgroundColor: string,
            cartButtonTextLetterSpacing: number,
        }

        interface Theme {
            logo: string;
            fontList: FontList,
            cart: Cart,
            desktopBreakpoint: number;
            smallBreakpoint: number;
            allergenColorFilter: string;
            defaultTouchableOpacity: number;
        }
    }
}

/**
 * this will contains variables that's share across all themes (or can be overwritten later on and here we will have default values)
 */
const CommonTheme = merge(PaperDefaultTheme, {
    colors: merge(PaperDefaultTheme.colors, {
        accent: '#020054',
        primary: '#2D8FCB',
    }),
    defaultTouchableOpacity: 0.65,
    smallBreakpoint: 768,
    desktopBreakpoint: 1074,
    allergenColorFilter: "invert(36%) sepia(95%) saturate(215%) hue-rotate(\n" +
        "351deg\n" +
        ") brightness(96%) contrast(92%)"
})


/**
 * currently there is no need to differ fonts for client/marketplace but if that changes just copy/paste it to appropriate sections
 */
const Fonts = {
    fontList: {
        primaryFontDefault: "Poppins-Regular",
        primaryFontLight: "Poppins-Light",
        primaryFontMedium: "Poppins-Medium",
        primaryFontBold: "Poppins-Bold",
        primaryFontExtraBold: "Poppins-ExtraBold",
        primaryFontBlack: "Poppins-Black"
    }
}

/**
 * this is reference to all colors declarations that's build in 'react-native-paper'
 * for reference and easy way to overwrite them
 */
// colors: {
//     primary: string;
//     background: string;
//     surface: string;
//     accent: string;
//     error: string;
//     text: string;
//     onSurface: string;
//     onBackground: string;
//     disabled: string;
//     placeholder: string;
//     backdrop: string;
//     notification: string;
// };

const MarketPlaceThemeToMerge = {
    logo: "shopnearby-logo.png",
    colors: {
        primary: '#000000',
        secondary: '#000000',
        button_color: '#ff5948',
        accent: '#eeeeee'
    },
    cart: {
        style: "market_place" as CartStyle,
        totalAmountFontSize: 24,
        totalBackgroundColor: '#eeeeee',
        cartButtonTextLetterSpacing: 3,
        cartTitleFont: Fonts.fontList.primaryFontLight,
        cartTitleSize: 20,
        cartTitleAlignSelf: 'flex-start' as FlexAlignType,
        cartTitlePaddingHorizontal: 24,
        cartTitleMarginHorizontal: 24,
    }
}

const ClientThemeToMerge = {
    logo: 'aji-logo.png',
    colors: {
        primary: '#ddc067',
        secondary: '#ddc067',
        button_color: '#ddc067',
    },
    cart: {
        style: "client" as CartStyle,
        totalAmountFontSize: 30,
        totalBackgroundColor: 'clear',
        cartButtonTextLetterSpacing: 2,
        cartTitleFont: Fonts.fontList.primaryFontBlack,
        cartTitleSize: 20,
        cartTitleAlignSelf: 'center' as FlexAlignType,
        cartTitlePaddingHorizontal: 24,
        cartTitleMarginHorizontal: 0,
    }
}

/**
 * the last in the order is the most important one for example
 * merge(a,merge(b, c)) -> c gets merged into b (where c 'keys' remains as priority) then 'bc' gets merged into a where 'bc' gets keys priority
 * to sum it up : c > b > a
 * when adding more themes client/marketplace theme should always remain at the end of the code (start of the chain) thus with the highest prio
 */
const ClientTheme = merge(Fonts, merge(CommonTheme, ClientThemeToMerge))
const MarketPlaceTheme = merge(Fonts, merge(CommonTheme, MarketPlaceThemeToMerge))

export {ClientTheme, MarketPlaceTheme};