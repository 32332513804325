import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {StyleSheet, Image, Text, View, TouchableOpacity} from 'react-native';
import i18n from '../translations/Translations';
import {observer} from "mobx-react-lite";
import {useMst} from "../models/RootModel";
import {useMediaQuery} from "react-responsive";
import Cart from "./Cart";
import {OrderHandler} from "../models/OrderModel";

export interface OrderStatusPopupProps {
    theme: ReactNativePaper.Theme,
    modalVisible?: boolean
}

const OrderStatusPopup = observer((props: OrderStatusPopupProps) => {
    const state = useMst();
    const desktopVersion = useMediaQuery({minWidth: props.theme.desktopBreakpoint});
    const [deliveryTime, setDeliveryTime] = React.useState(state.currentOrder.getDeliveryTime());

    const [timer, setTimer] = React.useState(() => {
        if (!timer) {
            // 100 ms delay because delivery time on first render is always 0
            setTimeout(() => {
                setDeliveryTime(state.currentOrder.getDeliveryTime());
            }, 100);

            return setInterval(() => {
                if (props.modalVisible) {
                    setDeliveryTime(state.currentOrder.getDeliveryTime());
                }
            }, 1000)
        }
    });

    if (!props.modalVisible) {
        if (timer) {
            clearInterval(timer);
        }
    }


    function onRejectedOKPress() {
        const orderHandlerInstance = new OrderHandler(state);
        orderHandlerInstance.initialize();
    }


    function onCancelPress() {
        const orderHandlerInstance = new OrderHandler(state);
        orderHandlerInstance.cancel();
    }

    function formatString(str: string, args: Array<string>) {
        for (let i = 0; i < args.length; i++) {
            str = str.replace('%s', args[i]);

        }
        return str;
    }


    const styles = StyleSheet.create({
        popup: {
            backgroundColor: "white",
            width: desktopVersion ? 600 : "90%",
            height: "auto",
            alignSelf: "center",
            paddingVertical: 53,
            paddingHorizontal: 84
        },
        status_message: {
            fontSize: 26,
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            alignSelf: "center",
            textAlign: "center"
        },
        ok_button: {
            paddingTop: 6,
            paddingHorizontal: 24,
            paddingBottom: 6,
            backgroundColor: props.theme.colors.primary,
        },
        ok_button_text: {
            color: "#fff",
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            fontSize: 16
        },
        buttons_wrapper: {
            flexDirection: "row",
            alignSelf: "center",
            marginTop: 50
        },
        animation: {
            width: 350,
            height: 250,
            alignSelf: "center",
            resizeMode: "center"
        }
    });

    let message = state.currentOrder.status;
    let animation = [];
    switch (state.currentOrder.status) {
        case "preparing":
            message = formatString(i18n.t("order_status_popup." + state.currentOrder.status), [state.customer.firstName, state.customer.lastName, deliveryTime.toString()]);
            const cookingGif = require('../../assets/cooking.gif');
            animation.push(<Image key={"cooking"} style={styles.animation} source={cookingGif}/>)
            break;
        case "ready_for_pickup":
            message = formatString(i18n.t("order_status_popup." + state.currentOrder.status), [state.customer.firstName, state.customer.lastName, state.currentOrder.number.toString()]);
            break;
        case "waiting_approval":
            const loadingGif = require('../../assets/loading.gif');
            animation.push(<Image key={"loading"} style={[styles.animation, {height: 100}]} source={loadingGif}/>)
            message = i18n.t("order_status_popup." + state.currentOrder.status);
            break;
        case "rejected":
            message = i18n.t("order_status_popup." + state.currentOrder.status);
            break;
    }


    return (
        <View style={styles.popup}>
            <Text style={styles.status_message}>{message}</Text>
            {animation}
            <View style={{marginTop: 50}}>
                <Cart readonly={true}/>
            </View>
            {state.currentOrder.status === 'rejected' &&
            <View style={styles.buttons_wrapper}>
                <TouchableOpacity style={styles.ok_button} onPress={onRejectedOKPress} activeOpacity={0.75}>
                    <Text style={styles.ok_button_text}>{i18n.t("common.ok")}</Text>
                </TouchableOpacity>
            </View>
            }
            {state.currentOrder.status === 'waiting_approval' &&
            <View style={styles.buttons_wrapper}>
                <TouchableOpacity style={styles.ok_button} onPress={onCancelPress} activeOpacity={0.75}>
                    <Text style={styles.ok_button_text}>{i18n.t("common.cancel")}</Text>
                </TouchableOpacity>
            </View>
            }
        </View>
    );
});

export default withTheme(OrderStatusPopup);