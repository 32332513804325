import {Instance, types} from "mobx-state-tree";
import {TranslatedTextModel} from "./TranslatedTextModel";
import {PriceModel} from "./PriceModel";
import {OptionModel} from "./OptionModel";
import {ProductModel} from "./ProductModel";

export const OptionGroupModel = types.model({
    id: "",
    name: types.optional(TranslatedTextModel, {}),
    options: types.array(OptionModel),
    index: 0,
    max: 0,
    min: 0
}).actions(self => ({})).views(self => ({}));

export interface IOptionGroupModel extends Instance<typeof OptionGroupModel>{}