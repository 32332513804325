import {Text, withTheme} from 'react-native-paper';
import * as React from 'react';
import {StyleSheet, TouchableHighlight, View} from 'react-native';

import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";

export interface FooterProps {
    theme: ReactNativePaper.Theme
    verticalMargin? : number
}

const Footer = observer((props: FooterProps) => {
        const state = useMst();

        const styles = StyleSheet.create({
            footer: {
                height: 50,
                paddingHorizontal: 160,
                marginVertical: props.verticalMargin ?? 0
            },
            separator: {
                backgroundColor: "black",
                height: 1,
                width: "100%"
            },
            navigation: {
                height: 50,
                flexDirection: "row",
                justifyContent: "space-between",
                alignSelf: "flex-end",
            },
            navigation_text: {
                fontFamily: props.theme.fontList.primaryFontMedium,
                textTransform: "uppercase",
                fontSize: 18,
                letterSpacing: 2,
            },
            navigation_spacing: {
                width: 36
            },
            navigation_right: {flexDirection: "row", alignSelf: "center"}
        });

    return <View style={styles.footer}>
            <View style={styles.separator}/>
            <View style={styles.navigation}>
                <View style={styles.navigation_right}>
                    <TouchableHighlight><Text style={styles.navigation_text}>Special offers</Text></TouchableHighlight>
                    <View style={styles.navigation_spacing}/>
                    <TouchableHighlight><Text style={styles.navigation_text}>Blog</Text></TouchableHighlight>
                    <View style={styles.navigation_spacing}/>
                    <TouchableHighlight><Text style={styles.navigation_text}>Join Us</Text></TouchableHighlight>
                    <View style={styles.navigation_spacing}/>
                    <TouchableHighlight><Text style={styles.navigation_text}>Contact</Text></TouchableHighlight>
                </View>
            </View>
            <View style={styles.separator}/>
        </View>;
    }
);

export default withTheme(Footer);