import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import {observer} from "mobx-react-lite";
import ArticleItem from "./ArticleItem";
import i18n from "i18n-js";


export interface ArticlesListProps {
    theme: ReactNativePaper.Theme,
    marginVertical?: number,
    maxArticles?: number,
}

const ArticlesList = observer((props: ArticlesListProps) => {
    const styles = StyleSheet.create({
        article_container: {
            marginTop: 40,
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
        },
        title_line: {
            height: 1,
            borderTopWidth: 1,
            borderColor: "black",
            borderStyle: "solid",
        },
        articles_list_title: {
            backgroundColor: "white",
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontBold,
            fontSize: 24,
            letterSpacing: 2.5,
            position: "absolute",
            paddingHorizontal: 18,
            top: -18,
            alignSelf: "center"
        }
    });

    let articlesArray = [];

    let lengthOfData = 10;

    for (let i = 0; i < lengthOfData; i++) {
        articlesArray.push(<ArticleItem key={"article_" + i}/>);
        if (props.maxArticles && (articlesArray.length+1) >= props.maxArticles * 2) {
            break;
        }
        articlesArray.push(<View style={{width: 28}} key={"separator_" + i}/>);
    }

    return (
        <View>
            <View style={styles.title_line}>
                <Text style={styles.articles_list_title}>{i18n.t("articles_list.title")}</Text>
            </View>
            <View style={styles.article_container}>
                {articlesArray}
            </View>
        </View>
    );
});

export default withTheme(ArticlesList);