import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {Text} from 'react-native-paper';
import {
    StyleSheet,
    View
} from 'react-native';

import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import i18n from "../translations/Translations";

export interface CopyrightProps {
    theme: ReactNativePaper.Theme
}

const Copyright = observer((props: CopyrightProps) => {
        const state = useMst();

        const styles = StyleSheet.create({
            container: {
                flexDirection: "row",
                justifyContent: "space-between",
                alignSelf: "flex-start",
                paddingHorizontal: 160
            },
            copyright_text: {
                fontSize: 12,
                textTransform: "uppercase",
                fontFamily: props.theme.fontList.primaryFontLight,
                letterSpacing: 2
            }
        });

        let currentYear = new Date().getFullYear();

        let footer = <View style={styles.container}>
            <Text style={styles.copyright_text}>{i18n.t("footer.copyright")} lorem ipsum {currentYear}</Text>
        </View>
        return footer;
    }
);

export default withTheme(Copyright);