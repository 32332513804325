import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {Animated, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import i18n from '../translations/Translations';
import {observer} from "mobx-react-lite";
import {useMst} from "../models/RootModel";
import {AddressModel, IAddressModel} from "../models/AddressModel";
import Address from "./Address";
import {NetworkService} from "../services/NetworkService";
import {IOrderModel, OrderModel} from "../models/OrderModel";
import {MediaQueryHelper} from "../helpers/MediaQueryHelper";
import {runInAction} from "mobx";

export interface MyAddressesProps {
    theme: ReactNativePaper.Theme
}

const MyAddresses = observer((props: MyAddressesProps) => {
    const state = useMst();
    const [data, setData] = React.useState<IAddressModel[]>([]);
    const [editedAddress, setEditedAddress] = React.useState<IAddressModel>();
    const [newAddress, setNewAddress] = React.useState<IAddressModel>();
    const [editingError, setEditingError] = React.useState("");
    const mediaQueryHelper = MediaQueryHelper();

    const styles = StyleSheet.create({
        content_wrapper: {},
        content_title: {
            fontFamily: props.theme.fontList.primaryFontBold,
            fontSize: 18,
            letterSpacing: 5.5,
            marginTop: 12,
        },
        addresses_wrapper: {
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 40
        },
        buttons: {
            paddingTop: 6,
            paddingHorizontal: 24,
            paddingBottom: 6,
            alignSelf: "flex-start"
        },
        buttons_text: {
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            fontSize: 16
        },
        cancel_button: {
            backgroundColor: "#6c6c6c",
        },
        cancel_button_text: {
            color: "#959595"
        },
        button: {
            backgroundColor: props.theme.colors.button_color,
            paddingHorizontal: 20,
            width: "auto",
            alignSelf: "flex-start",
            flexGrow: 0,
            height: 36,
            marginTop: 10
        },
        button_text: {
            color: "white",
            alignSelf: "center",
            marginVertical: "auto",
            fontFamily: props.theme.fontList.primaryFontBold,
            letterSpacing: 3
        },
        section_title: {
            color: props.theme.colors.primary,
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            fontSize: 18,
        },
        order_summary_title: {
            marginTop: 40,
            marginBottom: 20
        },
        contact_form: {
            flexDirection: "column",
            width: 386,
            paddingRight: 34
        },
        input_title: {
            marginTop: 20,
            marginBottom: 4
        },
        input_style: {
            height: 40,
            borderColor: "black",
            borderRadius: 3,
            borderWidth: 1,
            paddingLeft: 10
        },
        order_summary_wrapper_mobile: {
            flexDirection: "column",
            width: "100%",
            paddingHorizontal: 34
        },
        error_msg: {
            color: "red",
            marginTop: 20,
            fontFamily: props.theme.fontList.primaryFontMedium
        }
    });

    React.useEffect(() => {
        updateData();
    }, []);


    function updateData() {
        NetworkService.getRequest("api/addresses?account=" + state.account.id).then(response => {
            runInAction(() => {
                if (Array.isArray(response)) {
                    state.account.clearAddresses();
                    let updatedData = [];
                    for (let i = 0; i < response.length; i++) {
                        updatedData.push(AddressModel.create(response[i]))
                        state.account.addAddress(updatedData[i]);
                    }
                    setData(updatedData);
                }
            })
        });
    }

    function removeCallback(address: IAddressModel) {
        NetworkService.deleteRequest("api/addresses/" + address.id).then(response => {
            updateData();
        })
    }

    function saveEditedAddress(){
        // Validate data
        if (!editedAddress?.street.length){
            setEditingError(i18n.t("account.my_addresses.errors.street"));
            return;
        }else if (!editedAddress?.postal.length){
            setEditingError(i18n.t("account.my_addresses.errors.postal"));
            return;
        }else if (!editedAddress?.city.length){
            setEditingError(i18n.t("account.my_addresses.errors.city"));
            return;
        }

        NetworkService.putRequest("api/addresses/" + editedAddress?.id, {
            street: editedAddress?.street,
            postal: editedAddress?.postal,
            city: editedAddress?.city
        }).then((response) => {
            if (response.status && response.status != 200 ){
                setEditingError(i18n.t("account.my_addresses.errors.server"));
                return;
            }
            setEditingError("");
            setEditedAddress(undefined);
            updateData();
        }).catch((error) => {
            setEditingError(i18n.t("account.my_addresses.errors.server"));
        });
    }

    function saveNewAddress(){
        // Validate data
        if (!newAddress?.street.length){
            setEditingError(i18n.t("account.my_addresses.errors.street"));
            return;
        }else if (!newAddress?.postal.length){
            setEditingError(i18n.t("account.my_addresses.errors.postal"));
            return;
        }else if (!newAddress?.city.length){
            setEditingError(i18n.t("account.my_addresses.errors.city"));
            return;
        }


        NetworkService.postRequest("api/account_add_address", {
            account: state.account.id,
            street: newAddress?.street,
            postal: newAddress?.postal,
            city: newAddress?.city
        }).then((response) => {
            if (response.status && response.status != 200 ){
                setEditingError(i18n.t("account.my_addresses.errors.server"));
                return;
            }
            setEditingError("");
            setNewAddress(undefined);
            updateData();
        }).catch((error) => {
            setEditingError(i18n.t("account.my_addresses.errors.server"));
        });
    }

    const addressesComponents = data.map((address: IAddressModel) => (
        <Address key={address.id} address={address} callback={setEditedAddress} deleteCallback={removeCallback}/>
    ))

    return (
        <ScrollView style={styles.content_wrapper}>
            <Text style={styles.content_title}>{i18n.t("account.my_addresses.title")}</Text>
            {(!editedAddress && !newAddress) &&
            <View style={{flexDirection: "column"}}>
                <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} style={styles.button}
                                  onPress={() => {
                                      setNewAddress(AddressModel.create({id: "", city: "", street: "", postal: ""}))
                                  }}>
                    <Text style={styles.button_text}>{i18n.t("account.my_addresses.create_btn")}</Text>
                </TouchableOpacity>
                <View style={styles.addresses_wrapper}>
                    {addressesComponents}
                </View>
            </View>
            }
            {editedAddress &&
            <View>
                <View style={mediaQueryHelper.desktopVersion ? styles.contact_form : styles.order_summary_wrapper_mobile}>
                    <Text style={styles.input_title}>{i18n.t("account.my_addresses.street")}</Text>
                    <TextInput style={styles.input_style} value={editedAddress.street} onChangeText={text => {
                        editedAddress.setStreet(text);
                        setEditingError("");
                    }}/>
                    <Text style={styles.input_title}>{i18n.t("account.my_addresses.postal")}</Text>
                    <TextInput style={styles.input_style} value={editedAddress.postal} onChangeText={text => {
                        editedAddress.setPostal(text);
                        setEditingError("");
                    }}/>
                    <Text style={styles.input_title}>{i18n.t("account.my_addresses.city")}</Text>
                    <TextInput style={styles.input_style} value={editedAddress.city} onChangeText={text => {
                        editedAddress.setCity(text);
                        setEditingError("");
                    }}/>
                    <Text style={styles.error_msg}>{editingError}</Text>
                </View>
                <View style={{flexDirection: "row"}}>
                    <TouchableOpacity style={[styles.buttons, styles.cancel_button, {marginTop: 20}]} onPress={() => {
                        setEditedAddress(undefined);
                    }} activeOpacity={props.theme.defaultTouchableOpacity}>
                        <Text style={[styles.buttons_text, styles.cancel_button_text]}>{i18n.t("common.cancel")}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.button, {marginTop: 20,paddingTop: 6, paddingHorizontal: 24, paddingBottom: 6, marginLeft: 10}]} onPress={saveEditedAddress} activeOpacity={props.theme.defaultTouchableOpacity}>
                        <Text style={[styles.buttons_text, {color: "white"}]}>{i18n.t("common.save")}</Text>
                    </TouchableOpacity>
                </View>
            </View>
            }
            {newAddress &&
            <View>
                <View style={mediaQueryHelper.desktopVersion ? styles.contact_form : styles.order_summary_wrapper_mobile}>
                    <Text style={styles.input_title}>{i18n.t("account.my_addresses.street")}</Text>
                    <TextInput style={styles.input_style} value={newAddress.street} onChangeText={text => {
                        newAddress.setStreet(text);
                        setEditingError("");
                    }}/>
                    <Text style={styles.input_title}>{i18n.t("account.my_addresses.postal")}</Text>
                    <TextInput style={styles.input_style} value={newAddress.postal} onChangeText={text => {
                        newAddress.setPostal(text);
                        setEditingError("");
                    }}/>
                    <Text style={styles.input_title}>{i18n.t("account.my_addresses.city")}</Text>
                    <TextInput style={styles.input_style} value={newAddress.city} onChangeText={text => {
                        newAddress.setCity(text);
                        setEditingError("");
                    }}/>
                    <Text style={styles.error_msg}>{editingError}</Text>
                </View>
                <View style={{flexDirection: "row"}}>
                    <TouchableOpacity style={[styles.buttons, styles.cancel_button, {marginTop: 20}]} onPress={() => {
                        setNewAddress(undefined);
                    }} activeOpacity={props.theme.defaultTouchableOpacity}>
                        <Text style={[styles.buttons_text, styles.cancel_button_text]}>{i18n.t("common.cancel")}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.button, {marginTop: 20,paddingTop: 6, paddingHorizontal: 24, paddingBottom: 6, marginLeft: 10}]} onPress={saveNewAddress} activeOpacity={props.theme.defaultTouchableOpacity}>
                        <Text style={[styles.buttons_text, {color: "white"}]}>{i18n.t("common.add")}</Text>
                    </TouchableOpacity>
                </View>
            </View>
            }
        </ScrollView>
    );
}
);

export default withTheme(MyAddresses);