import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {Text} from 'react-native-paper';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {observer} from "mobx-react-lite";
import i18n from "../translations/Translations";
import {AddressModel, IAddressModel} from "../models/AddressModel";

export interface AddressProps {
    theme: ReactNativePaper.Theme
    address: IAddressModel
    callback?: (address: IAddressModel) => void
    deleteCallback?: (address: IAddressModel) => void
    readonly?: boolean
}

const Address = observer((props: AddressProps) => {

        const styles = StyleSheet.create({
            container: {
                paddingRight: 20
            },
            name: {
                letterSpacing: 2.5,
                fontFamily: props.theme.fontList.primaryFontMedium,
            },
            address: {
                letterSpacing: 1,
                lineHeight: 24,
                fontFamily: props.theme.fontList.primaryFontLight,
                marginTop: 6
            },
            edit_btn:{
                color: props.theme.colors.button_color,
                fontFamily: props.theme.fontList.primaryFontDefault,
                letterSpacing: 1.3
            }
        });


        function editCallback() {
            if (typeof props.callback === "function") {
                props.callback(AddressModel.create(JSON.parse(JSON.stringify(props.address))));
            }
        }

        function removeCallback(){
            if (typeof props.deleteCallback === "function") {
                props.deleteCallback(props.address);
            }
        }

        let addressContent = <View style={styles.container}>
            <Text style={styles.address}>{props.address.getFormatted()}</Text>
            {!props.readonly &&
                <View style={{flexDirection: "row"}}>
                    <TouchableOpacity style={{marginTop: 10}} activeOpacity={props.theme.defaultTouchableOpacity} onPress={editCallback}>
                        <Text style={styles.edit_btn}>{i18n.t("common.edit")}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{marginTop: 10, marginLeft: 10}} activeOpacity={props.theme.defaultTouchableOpacity} onPress={removeCallback}>
                        <Text style={styles.edit_btn}>{i18n.t("account.my_addresses.remove_btn")}</Text>
                    </TouchableOpacity>
                </View>

            }
        </View>
        return addressContent;
    }
);

export default withTheme(Address);