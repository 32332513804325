import React from 'react';
import {Text, StyleSheet, View, LayoutChangeEvent} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {withTheme} from "react-native-paper";

export interface CartNumberItemsCircleProps {
    theme: ReactNativePaper.Theme,
    backgroundColor: string,
    fontFamily?: string,
    textColor?: string,
    size?: number,
    top: number,
    left: number
}

const CartNumberItemsCircle = observer((props: CartNumberItemsCircleProps) => {
    const state = useMst();

    const [minWidth, setMinWidth] = React.useState(0);
    const [width, setWidth] = React.useState(0);

    const styles = StyleSheet.create({
        container: {
            backgroundColor: props.backgroundColor,
            minWidth: minWidth,
            borderRadius: 1000,
            position: "absolute",
            transform: [
                {translateX: -width / 2}
            ],
            top: props.top,
            left: props.left
        },
        text: {
            paddingHorizontal: (props.size ? props.size : 16) / 2,
            alignSelf: "center",
            color: props.textColor ? props.textColor : "white",
            fontFamily: props.fontFamily ? props.fontFamily : "Poppins-Medium",
            fontSize: props.size ? props.size : 16
        }
    });

    function onContainerLayout(dimensions: LayoutChangeEvent) {
        setMinWidth(dimensions.nativeEvent.layout.height);
        setWidth(dimensions.nativeEvent.layout.width);
    }

    return (
        <View style={styles.container} onLayout={onContainerLayout}>
            <Text style={styles.text}>{state.currentOrder.items.length}</Text>
        </View>
    );
})

export default withTheme(CartNumberItemsCircle);