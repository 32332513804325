import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {Text} from 'react-native-paper';
import {StyleSheet, Image, View, TouchableOpacity} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";

import {IProductModel} from "../models/ProductModel";
import {useNavigation} from "@react-navigation/native";

export interface ArticleItemProps {
    theme: ReactNativePaper.Theme,
}

const ArticleItem = observer((props: ArticleItemProps) => {
    const state = useMst();
    const navigate = useNavigation();

    function onArticleImagePress() {
        // navigate.navigate("product_page", {id: props.product.id});
    }

    function onArticleTitlePress() {
        // navigate.navigate("product_page", {id: props.product.id});
    }

    const styles = StyleSheet.create({
        container: {
            width: 328,
        },
        article_image: {
            width: 328,
            height: 265
        },
        title: {
            marginTop: 24,
            marginBottom: 18,
            fontFamily: props.theme.fontList.primaryFontMedium,
            fontSize: 18,
            letterSpacing: 2
        },
        article_content: {
            fontFamily: props.theme.fontList.primaryFontDefault,
            letterSpacing: 1.5
        }
    });

    function randomInt(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    let product: IProductModel = state.productsStore.products[randomInt(0, state.productsStore.products.length - 1)];

    return <View style={[styles.container]}>
        <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={onArticleImagePress}>
            <Image style={styles.article_image} source={{uri: product.getImage()}}/>
        </TouchableOpacity>
        <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={onArticleTitlePress}>
            <Text style={styles.title}>Lorem ipsum dolor sit amet</Text>
        </TouchableOpacity>
        <Text style={styles.article_content}>Sed cursus, nisl sed cursus commodo, eros quam scele-risque magna, eget
            fringilla dui erat et lorem. Curabitur at porta ligula. Nunc elit erat, varius iaculis mi vitae, eleifend
            dignissim justosto.</Text>
    </View>
});


export default withTheme(ArticleItem);