import React from 'react';
import {ScrollView, StyleSheet, TextInput, TouchableOpacity, View} from 'react-native';
import Header from '../components/ClientHeader';
import {Text, withTheme} from "react-native-paper";
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {useMediaQuery} from "react-responsive";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Login from "../components/Login";
import i18n from "../translations/Translations";
import {useNavigation} from "@react-navigation/native";
import {NetworkService} from "../services/NetworkService";

export interface ActivationAccountPageRestaurantProps {
    theme: ReactNativePaper.Theme
}

const ActivationAccountPageRestaurant = observer((props: ActivationAccountPageRestaurantProps) => {
    const state = useMst();
    const desktopVersion = useMediaQuery({minWidth: props.theme.desktopBreakpoint});
    const navigation = useNavigation();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');

    const [email, setEmail] = React.useState(state.customer.email.toString());
    const [message, setMessage] = React.useState("");
    const [requestAlreadyDone, setRequestAlreadyDone] = React.useState(false);

    const styles = StyleSheet.create({
        container: {
            backgroundColor: "white"

        },
        button: {
            marginHorizontal: 10,
            backgroundColor: props.theme.colors.button_color,
            width: "100%",
            height: 36,
        },
        button_text: {
            color: "white",
            alignSelf: "center",
            marginVertical: "auto",
            fontFamily: props.theme.fontList.primaryFontBold,
            letterSpacing: 3
        },
        message: {
            marginBottom: 30,
            fontSize: 14,
            alignSelf: "center",
            flexWrap: "wrap"
        },
        content: {
            width: 500,
            elevation: 4,
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: "center",
            zIndex: -1,
            borderStyle: "solid",
            borderWidth: 1,
            marginTop: 40,
            padding: 30,
            paddingHorizontal: 50
        }
    });

    function handleResponseError(code: number) {
        switch (code) {
            case 409:
                setMessage(i18n.t("activate.errors.expired"))
                break;
            case 406:
                setMessage(i18n.t("activate.errors.missing_parameter"))
                break;
            case 404:
            default:
                setMessage(i18n.t("activate.errors.missing"))
                break;
        }
    }

    if (!requestAlreadyDone) {
        setRequestAlreadyDone(true);
        NetworkService.postRequest("api/account_activate", {"token": token}).then((response) => {
            console.log(response)
            if (response.status && response.status != 200 && response.status != 201) {
                handleResponseError(response.status);
                return;
            }
            setMessage(i18n.t("activate.success"))
        }).catch(error => {
            console.log("Could not add activate account");
            console.log(error);
            handleResponseError(error.status);
        })
    }

    return (
        <View style={styles.container}>
            <ScrollView style={{height: "100vh", paddingBottom: 32}}>
                <Header/>
                <View style={styles.content}>
                    <Text style={styles.message}>{message}</Text>
                    <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} style={styles.button}
                                      onPress={() => {
                                          navigation.navigate("login")
                                      }}>
                        <Text style={styles.button_text}>{i18n.t("login_page.login").toUpperCase()}</Text>
                    </TouchableOpacity>
                </View>
            </ScrollView>
        </View>
    );
})

export default withTheme(ActivationAccountPageRestaurant);