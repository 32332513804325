import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {observer} from "mobx-react-lite";
import {IOptionGroupModel} from "../models/OptionGroupModel";
import i18n from '../translations/Translations';
import {IOptionModel} from "../models/OptionModel";
import Option from "./Option";
import {useMst} from "../models/RootModel";

export interface OptionGroupProps {
    theme: ReactNativePaper.Theme,
    group: IOptionGroupModel
}

const OptionGroup = observer((props: OptionGroupProps) => {
    const state = useMst();

    function isDisabled(optionID: string):boolean{
        if (props.group.max === 0){
            return false;
        }

        // We need to be able to unselect option
        if (state.optionsPopup.selectedOptions.indexOf(optionID) > -1){
            return false;
        }

        // Count how many of options from group was already selected
        let selectedOptionsCount = 0;
        props.group.options.forEach((option) => {
            if (state.optionsPopup.selectedOptions.indexOf(option.id) > -1){
                selectedOptionsCount++;
            }
        })

        return selectedOptionsCount >= props.group.max;
    }

    const styles = StyleSheet.create({
        container: {
            marginTop: 10,
        },
        group_name: {
            fontFamily: props.theme.fontList.primaryFontBold,
            fontSize: 20,
            textTransform: "uppercase",
            color: props.theme.colors.primary
        },
        min_max: {
            fontStyle: "italic",
            fontFamily: props.theme.fontList.primaryFontDefault,
            fontSize: 13,
            letterSpacing: 2,
            marginTop: -4
        },
        options: {
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginTop: 20
        }
    });

    return (
        <View style={styles.container}>
            <Text
                style={[styles.group_name]}>{props.group.name.getTranslated()}</Text>
            <Text
                style={styles.min_max}>{props.group.min > 0 && i18n.t("options_modal.min") + " " + props.group.min + "  "}{props.group.max > 0 && i18n.t("options_modal.max") + " " + props.group.max}</Text>
            <View style={styles.options}>
                {props.group.options.map((option: IOptionModel) => (
                    <Option key={option.id} option={option} disabled={isDisabled(option.id)} selected={state.optionsPopup.selectedOptions.indexOf(option.id) > -1}/>
                ))}
            </View>
        </View>
    );
});


export default withTheme(OptionGroup);