import React from 'react';
import {
    StyleSheet, View
} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {withTheme} from "react-native-paper";
import {Text} from 'react-native-paper';
import {IProductModel} from "../models/ProductModel";
import ProductsList from "./ProductsList";
import SortingWidget from "./SortingWidget";
import {IProductCategoryModel} from "../models/ProductCategoryModel";

export interface ProductListSortingSearchProps {
    theme: ReactNativePaper.Theme,
    title?: string,
    category?: IProductCategoryModel
}

const ProductListSortingSearch = observer((props: ProductListSortingSearchProps) => {
    const state = useMst();
    const styles = StyleSheet.create({
        container: {zIndex: -1},
        top_panel: {
            flexDirection: "row",
            marginBottom: 24,
            justifyContent: "space-between"
        },
        top_panel_right_container: {
            flexDirection: "row",
        },
        top_panel_text: {
            fontFamily: props.theme.fontList.primaryFontMedium,
            textTransform: "uppercase",
            fontSize: 14,
            letterSpacing: 2,
            paddingVertical: 12
        }
    });

    function sortByFilter(a: IProductModel, b: IProductModel): number {
        switch (state.selectedSortFilter.key) {
            case "srtNameAsc":
                if (a.name.getTranslated() > b.name.getTranslated()) {
                    return 1;
                } else if (a.name.getTranslated() < b.name.getTranslated()) {
                    return -1;
                }
                return 0;
            case "srtNameDesc":
                if (a.name.getTranslated() > b.name.getTranslated()) {
                    return -1;
                } else if (a.name.getTranslated() < b.name.getTranslated()) {
                    return 1;
                }
                return 0;
            case "srtPriceAsc":
                if (a.price > b.price) {
                    return 1;
                } else if (a.price < b.price) {
                    return -1;
                }
                return 0;
            case "srtPriceDesc":
                if (a.price > b.price) {
                    return -1;
                } else if (a.price < b.price) {
                    return 1;
                }
                return 0;
        }
        return 0;
    }

    function filter(a: IProductModel) {
        return a.name.getTranslated().toLowerCase().indexOf(state.searchString.value.toLowerCase()) > -1;
    }

    let productList = state.productsStore.products;

    if (props.category && props.category.id) {
        let categoryId = props.category.id;
        productList = productList.filter((product: IProductModel) => product.category.id == categoryId);
    }

    return (
        <View style={styles.container}>
            <View style={styles.top_panel}>
                <Text style={[styles.top_panel_text, {fontSize: 18}]}>{props.title}</Text>
                <View style={styles.top_panel_right_container}>
                    <Text style={styles.top_panel_text}>Sort by:</Text>
                    <SortingWidget/>
                </View>
            </View>
            <View style={{zIndex: -1}}>
                <ProductsList products={productList.filter(filter).sort(sortByFilter)} marginHorizontal={30}/>
            </View>
        </View>
    );
})

export default withTheme(ProductListSortingSearch);