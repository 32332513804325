import {types, Instance, onSnapshot} from "mobx-state-tree";
import {IProductModel, ProductModel} from "./ProductModel";
import {NetworkService} from "../services/NetworkService";
import i18n from '../translations/Translations';
import * as Localization from "expo-localization";
import {AddressModel, IAddressModel} from "./AddressModel";

const CustomerModel = types
    .model({
        id: "",
        orderID: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        language: "fr", // Localization.locale
        loggedIn: false,
        address: "",
        postalCode: "00-000",
        city: "",
        addresses: types.optional(types.array(AddressModel), [])
    })
    .actions(self => ({
        setLanguage(lang: string) {
            i18n.locale = lang;
            self.language = lang;
        },
        setId(v: string) {
            self.id = v;
        },
        setFirstName(v: string) {
            self.firstName = v;
        },
        setLastName(v: string) {
            self.lastName = v;
        },
        setPhoneNumber(v: string) {
            self.phoneNumber = v;
        },
        setEmail(v: string) {
            self.email = v;
        },
        setOrderID(v: string) {
            self.orderID = v;
        },
        setLoggedIn(v: boolean) {
            self.loggedIn = v;
        },
        setAddress(v: string) {
            self.address = v;
        },
        setPostalCode(v: string) {
            self.postalCode = v;
        },
        setCity(v: string) {
            self.city = v;
        },
        logOut(){
            self.loggedIn = false;
            self.id = "";
            self.orderID = "";
            self.firstName = "";
            self.lastName = "";
            self.phoneNumber = "";
            self.email = "";
            self.address = "";
            self.postalCode = "";
            self.city = "";
        }
    })).views(self => ({
        getFullName(): string{
            return self.firstName + " " + self.lastName;
        }
    }))

let initialState = CustomerModel.create();

// check if previously saved store is available
const data = localStorage.getItem('customerState');
if (data) {
    const json = JSON.parse(data);
    if (CustomerModel.is(json)) {
        initialState = CustomerModel.create(json);
    }
}

// Set current selected language
i18n.locale = initialState.language;

export const customerStore = initialState;

// store state in local storage on each change
onSnapshot(customerStore, snapshot => {
    localStorage.setItem('customerState', JSON.stringify(snapshot));
});
