import {Text, withTheme} from 'react-native-paper';
import * as React from 'react';
import {StyleSheet, TextInput, TouchableOpacity, View} from 'react-native';

import i18n from '../translations/Translations';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {useNavigation} from "@react-navigation/native";

export interface CustomerAddressProps {
    theme: ReactNativePaper.Theme,
}

const CustomerAddress = observer((props: CustomerAddressProps) => {
        const state = useMst();
        useNavigation();

        const styles = StyleSheet.create({
            title: {
                fontFamily: props.theme.fontList.primaryFontBold,
                textTransform: "uppercase",
                fontSize: 20,
                letterSpacing: 2.5,
            },
            summary_text: {
                alignSelf: "center",
                fontSize: 14,
                fontFamily: props.theme.fontList.primaryFontDefault,
            },
            input_style: {
                alignSelf: "center",
                minHeight: 36,
                maxHeight: 250,
                width: "100%",
                outlineColor: "#00000000",
                backgroundColor: "#eeeeee",
                paddingLeft: 16,
                paddingRight: 16,
                fontFamily: props.theme.fontList.primaryFontDefault,
                letterSpacing: 2,
                paddingVertical: 8,
                marginVertical: 16
            },
            my_address_text_style: {
                fontFamily: props.theme.fontList.primaryFontDefault,
            },
            choose_address_button_text: {
                fontFamily: props.theme.fontList.primaryFontDefault,
                fontSize: 14,
                alignSelf: "center",
                color: props.theme.colors.button_color
            }
        })

        return <View>
            <View style={{flexDirection: "row", paddingVertical: 24}}>
                <Text style={[styles.title, {
                    alignSelf: "center",
                    justifyContent: "center"
                }]}>{i18n.t("my_address.title")}</Text>
                <TouchableOpacity style={{
                    alignSelf: "center",
                    paddingHorizontal: 12,
                    marginLeft: 12,
                    paddingVertical: 6
                }} onPress={() => {
                    //TODO CHOOSE ADDRESS POPUP!
                }}><Text
                    style={styles.choose_address_button_text}>{i18n.t("my_address.choose_button_text")}</Text></TouchableOpacity>
            </View>
            <View style={{flexDirection: "row"}}>
                <View style={{width: 310, marginRight: 36}}>
                    <Text style={styles.my_address_text_style}>{i18n.t("my_address.name")}</Text>
                    <TextInput style={styles.input_style} maxLength={20}
                               onChangeText={(t) => {
                               }} defaultValue={state.customer.firstName}/>
                    <Text style={styles.my_address_text_style}>{i18n.t("my_address.surname")}</Text>
                    <TextInput style={styles.input_style} maxLength={20}
                               onChangeText={(t) => {
                               }} defaultValue={state.customer.lastName}/>
                    <Text style={styles.my_address_text_style}>{i18n.t("my_address.email")}</Text>
                    <TextInput style={styles.input_style} maxLength={36} keyboardType={"email-address"}
                               onChangeText={(t) => {
                               }} defaultValue={state.customer.email}/>
                    <Text style={styles.my_address_text_style}>{i18n.t("my_address.phone")}</Text>
                    <TextInput style={styles.input_style} maxLength={12} keyboardType={"phone-pad"}
                               onChangeText={(t) => {
                               }} defaultValue={state.customer.phoneNumber}/>
                </View>
                <View style={{width: 310}}>
                    <Text style={styles.my_address_text_style}>{i18n.t("my_address.address")}</Text>
                    <TextInput style={styles.input_style}
                               onChangeText={(t) => {
                               }}
                               defaultValue={state.customer.address}/>
                    <Text style={styles.my_address_text_style}>{i18n.t("my_address.postal_code")}</Text>
                    <TextInput style={styles.input_style} maxLength={6}
                               onChangeText={(t) => {
                               }} defaultValue={state.customer.postalCode}/>
                    <Text style={styles.my_address_text_style}>{i18n.t("my_address.city")}</Text>
                    <TextInput style={styles.input_style} maxLength={6}
                               onChangeText={(t) => {
                               }} defaultValue={state.customer.city}/>
                </View>
            </View>
        </View>;
    }
);

export default withTheme(CustomerAddress);