import React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import Header from '../components/HomeHeader';
import {withTheme} from "react-native-paper";
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import {OrderHandler} from "../models/OrderModel";
import Cart from "../components/Cart";
import ProductInfo from "../components/ProductInfo";
import ProductPropositions from "../components/ProductPropositions";
import {IProductModel} from "../models/ProductModel";

export interface ProductPageProps {
    theme: ReactNativePaper.Theme
}

const ProductPage = observer((props: ProductPageProps) => {
    const state = useMst();
    const [showCart, setCartState] = React.useState(false);

    const styles = StyleSheet.create({
        container: {
            backgroundColor: "white"
        },
        modal: {
            elevation: 0
        },
        product_page: {
            paddingHorizontal: 160, flexDirection: "row", justifyContent: "center", zIndex: -1
        }
    });

    const orderHandler = new OrderHandler(state);
    if (state.customer.orderID.length > 0) {
        if (state.customer.orderID != state.currentOrder.id) {
            orderHandler.resumeOrder(state.customer.orderID, () => {
                if (state.currentOrder.id.length > 0 && state.currentOrder.items.length > 0 && !showCart) {
                    setCartState(true)
                }
            });
        } else {
            if (state.currentOrder.id.length > 0 && state.currentOrder.items.length > 0 && !showCart) {
                setCartState(true)
            }
        }
    }

    let productPropositions: Array<IProductModel> = new Array<IProductModel>();
    for (let i = 0; i < state.productsStore.products.length; i++) {
        if (productPropositions.length >= 4) {
            break;
        }
        productPropositions.push(state.productsStore.products[i]);
    }


    return (
            <View style={styles.container}>
                <ScrollView style={{height: "100vh", paddingBottom: 32}}>
                    <Header/>
                    <View style={styles.product_page}>
                        <View style={{maxWidth: 500, marginRight: 30}}>
                            {showCart && <Cart/>}
                        </View>
                        <View style={{width: 886, alignSelf: "flex-start"}}>
                            <ProductInfo/>
                            <ProductPropositions product_list={productPropositions} maxWidth={886}/>
                        </View>
                    </View>
                    <View style={{zIndex: -1}}>
                        <Footer verticalMargin={50}/>
                        <Copyright/>
                    </View>
                </ScrollView>
            </View>
    );
})

export default withTheme(ProductPage);