import React from 'react';
import {
    ScrollView,
    StyleSheet, TouchableOpacity,
    View
} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {withTheme} from "react-native-paper";
import GalleryWithScroll from "./GalleryWithScroll";
import OptionPicker from "./OptionPicker";
import BreadCrumbs from "./BreadCrumbs";
import {Text} from 'react-native-paper';
import {IProductModel} from "../models/ProductModel";
import {IProductCategoryModel} from "../models/ProductCategoryModel";
import i18n from "../translations/Translations";
import {useNavigation, useRoute} from "@react-navigation/native";
import {OrderHandler} from "../models/OrderModel";
import {BreadCrumbModel, IBreadCrumbModel} from "../models/BreadCrumbModel";

export interface OptionPickerProps {
    theme: ReactNativePaper.Theme,
}

interface routeParams {
    id?: string
}

const ProductInfo = observer((props: OptionPickerProps) => {
    const state = useMst();
    const route = useRoute();
    const navigate = useNavigation();
    const routeParams = route.params as routeParams;
    if (!routeParams.id) {
        navigate.navigate("home");
    }


    const styles = StyleSheet.create({
        container: {
            flexDirection: "row",
            zIndex: -1
        },
        product_container: {
            maxWidth: 506,
            paddingLeft: 20
        },
        product_name: {
            fontSize: 20,
            fontFamily: props.theme.fontList.primaryFontBold,
            textTransform: "uppercase",
            letterSpacing: 8,
            marginTop: 8
        },
        shop_name: {
            fontSize: 12,
            fontFamily: props.theme.fontList.primaryFontBold,
            textTransform: "uppercase",
            letterSpacing: 8,
            color: "#ff9087"
        },
        product_description_scroll_view: {
            maxHeight: 288,
            marginVertical: 24,
            flexGrow: 0
        },
        product_description: {
            fontSize: 14,
            fontFamily: props.theme.fontList.primaryFontLight,
            letterSpacing: 1.5,
            lineHeight: 20,
        },
        buy_now_button: {
            backgroundColor: "#ff5948",
            height: 34,
            width: "auto",
            alignSelf: 'flex-start',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 24
        },
        buy_now_text: {
            paddingHorizontal: 16,
            fontSize: 14,
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontBold,
            color: "white",
            letterSpacing: 2,
        }
    });

    let product = state.productsStore.products.find((tmpProduct: IProductModel) => tmpProduct.id == routeParams.id) as IProductModel;
    let category = state.categoriesStore.categories.find((tmpCategory: IProductCategoryModel) => product?.category.id == tmpCategory.id);
    let breadCrumbsArray: IBreadCrumbModel[] = [];
    if (category) {
        breadCrumbsArray.push(BreadCrumbModel.create({
            routeName: "Home",
            label: category.name.getTranslated(),
            params: {category: category.id}
        }));
    }
    let galleryUriList = [""];
    if (product) {
        galleryUriList = [product?.getImage(), product?.getImage(), product?.getImage(), product?.getImage(), product?.getImage()];
    }

    function buyNowPress() {
        const orderHandlerInstance = new OrderHandler(state);
        if (state.currentOrder.id.length === 0) {
            orderHandlerInstance.initialize(buyNowPress);
            return;
        }
        orderHandlerInstance.addProduct(product.id, [])
    }

//TODO ADD PROPER VALUE : shop_name,
    return (
        <View style={styles.container}>
            <GalleryWithScroll backgroundColor={"red"} spacing={20} main_size={380} uriList={galleryUriList}/>
            <View style={styles.product_container}>
                <BreadCrumbs breadCrumbs={breadCrumbsArray}/>
                <Text style={styles.product_name}>{product?.name.getTranslated()}</Text>
                <Text style={styles.shop_name}>shop name</Text>
                <ScrollView style={styles.product_description_scroll_view}>
                    <Text style={styles.product_description}>{product?.description.getTranslated()}</Text>
                </ScrollView>
                <OptionPicker max_width={400}/>
                <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} style={styles.buy_now_button}
                                  onPress={buyNowPress}><Text
                    style={styles.buy_now_text}>{i18n.t("product.buttons.buy")}</Text></TouchableOpacity>
            </View>
        </View>
    );
})

export default withTheme(ProductInfo);