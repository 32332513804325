import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {StyleSheet, View, Text, TouchableOpacity} from 'react-native';
import {observer} from "mobx-react-lite";
import {IOptionModel} from "../models/OptionModel";
import {useMst} from "../models/RootModel";

export interface OptionProps {
    theme: ReactNativePaper.Theme,
    option: IOptionModel,
    disabled: boolean,
    selected: boolean
}

const Option = observer((props: OptionProps) => {
    const state = useMst();

    function onOptionPress() {
        state.optionsPopup.toggleSelected(props.option.id);
    }

    const styles = StyleSheet.create({
        container: {
            flexDirection: "row",
            minWidth: 200,
            marginRight: 10,
            marginBottom: 30
        },
        disabled: {
            opacity: 0.5
        },
        text_part:{
            marginLeft: 10
        },
        name: {
            fontSize: 16,
            fontFamily: props.theme.fontList.primaryFontBold
        },
        price: {
            fontFamily: props.theme.fontList.primaryFontMedium
        },
        checkbox: {
            marginTop: 5,
            width: 17,
            height: 17,
            borderWidth: 1,
            borderColor: props.theme.colors.primary
        },
        selected: {
            backgroundColor: props.theme.colors.primary
        },
    });

    return (
        <TouchableOpacity onPress={onOptionPress} activeOpacity={0.8} disabled={props.disabled}>
            <View style={[styles.container, props.disabled && styles.disabled]}>
                <View style={[styles.checkbox, props.selected && styles.selected]}/>
                <View style={styles.text_part}>
                    <Text style={styles.name}>{props.option.option.name.getTranslated()}</Text>
                    <Text style={styles.price}>+ {props.option.getPrice(state.clientStore.client.alwaysShowDecimals)}</Text>
                </View>
            </View>
        </TouchableOpacity>
    );
});


export default withTheme(Option);