import {Instance, types} from "mobx-state-tree";


export const AddressModel = types.model({
    id: types.string,
    street: types.string,
    postal: types.string,
    city: types.string
}).actions(self => ({
    setStreet(v: string) {
        self.street = v;
    },
    setPostal(v: string) {
        self.postal = v;
    },
    setCity(v: string) {
        self.city = v;
    }
})).views(self => ({
    getFormatted(): string {
        let result = self.street + "\n";
        result += self.postal + " " + self.city;
        return result;
    },
    getDropdownLabel(): string {
        let maxCharacters = 30;
        let result = self.street + " " + self.postal + " " + self.city;
        return result.length > maxCharacters ? result.substr(0, maxCharacters) + "..." : result;
    }
}));

export interface IAddressModel extends Instance<typeof AddressModel> {
}