import {Instance, types} from "mobx-state-tree";
import {TranslatedTextModel} from "./TranslatedTextModel";
import {PriceModel} from "./PriceModel";
import {ProductModel} from "./ProductModel";
import {OptionModel} from "./OptionModel";

export const OrderItemOptionModel = types.model({
    id: "",
    price: 0,
    priceCur: "",
    groupOption: types.optional(OptionModel, {}),
    quantity: 1,

}).actions(self => ({})).views(self => ({
    getPrice(alwaysShowDecimals: boolean): string{
        let price = PriceModel.create({price: self.price, currency: self.priceCur});
        return price.getFormatedPrice(alwaysShowDecimals);
    }
}));

export interface IOrderItemOptionModel extends Instance<typeof OrderItemOptionModel>{}