import {types, Instance, onSnapshot} from "mobx-state-tree";
import i18n from '../translations/Translations';
import * as Localization from "expo-localization";
import {AddressModel, IAddressModel} from "./AddressModel";
import {NetworkService} from "../services/NetworkService";
import {runInAction} from "mobx";

const AccountModel = types
    .model({
        id: "",
        orderID: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        language: Localization.locale,
        loggedIn: false,
        address: "",
        postalCode: "00-000",
        city: "",
        addresses: types.optional(types.array(AddressModel), [])
    })
    .actions(self => ({
        setLanguage(lang: string) {
            i18n.locale = lang;
            self.language = lang;
        },
        setId(v: string) {
            self.id = v;
        },
        setFirstName(v: string) {
            self.firstName = v;
        },
        setLastName(v: string) {
            self.lastName = v;
        },
        setPhoneNumber(v: string) {
            self.phoneNumber = v;
        },
        setEmail(v: string) {
            self.email = v;
        },
        setOrderID(v: string) {
            self.orderID = v;
        },
        setLoggedIn(v: boolean) {
            self.loggedIn = v;
        },
        setAddress(v: string) {
            self.address = v;
        },
        setPostalCode(v: string) {
            self.postalCode = v;
        },
        setCity(v: string) {
            self.city = v;
        },
        logOut() {
            self.loggedIn = false;
            self.id = "";
            self.orderID = "";
            self.firstName = "";
            self.lastName = "";
            self.phoneNumber = "";
            self.email = "";
            self.address = "";
            self.postalCode = "";
            self.city = "";
        },
        clearAddresses() {
            self.addresses.clear();
        },
        addAddress(address: IAddressModel) {
            self.addresses.push(address)
        },
        loadAdresses(callback?: () => void) {
            if (self.id.length) {
                console.log("loading addresses")
                NetworkService.getRequest("api/addresses?account=" + self.id).then(response => {
                    if (Array.isArray(response)) {
                        this.clearAddresses();
                        for (let i = 0; i < response.length; i++) {
                            this.addAddress(AddressModel.create(response[i]));
                        }
                        console.log("Finished loading addresses");
                    }
                    if (typeof callback === "function"){
                        callback();
                    }
                });
            }
        }
    })).views(self => ({
        getFullName(): string {
            return self.firstName + " " + self.lastName;
        }
    }))

let initialState = AccountModel.create();

// check if previously saved store is available
const data = localStorage.getItem('accountState');
if (data) {
    const json = JSON.parse(data);
    if (AccountModel.is(json)) {
        initialState = AccountModel.create(json);
    }
}

// Set current selected language
i18n.locale = initialState.language;

export const accountStore = initialState;

// store state in local storage on each change
onSnapshot(accountStore, snapshot => {
    localStorage.setItem('accountState', JSON.stringify(snapshot));
});
