import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {Text} from 'react-native-paper';
import {ScrollView, StyleSheet, TouchableOpacity, View} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import i18n from "../translations/Translations";
import {IOrderItemModel, OrderItemModel} from "../models/OrderItemModel";
import {DataTable} from 'react-native-paper';
import {NetworkService} from "../services/NetworkService";
import {IOrderModel, OrderHandler, OrderModel} from "../models/OrderModel";
import {useNavigation} from "@react-navigation/native";
import CartItemClient from "./CartItemClient";
import {MediaQueryHelper} from "../helpers/MediaQueryHelper";

export interface MyOrdersProps {
    theme: ReactNativePaper.Theme,
}

const itemsPerPage = 10;


const MyOrders = observer((props: MyOrdersProps) => {
        const state = useMst();
        const [page, setPage] = React.useState(0);
        const [data, setData] = React.useState<IOrderModel[]>([]);
        const [detailedOrder, setDetailedOrder] = React.useState<IOrderModel>();
        const from = page * itemsPerPage;
        const to = (page + 1) * itemsPerPage;
        const mediaQueryHelper = MediaQueryHelper();

        React.useEffect(() => {
            if (state.account.id) {
                console.log(state.account.id);
                NetworkService.getRequest("api/accounts/" + state.account.id + "/orders").then(response => {
                    console.log(response);
                    if (Array.isArray(response)) {
                        let updatedData = [];
                        for (let i = 0; i < response.length; i++) {
                            updatedData.push(OrderModel.create(response[i]))
                        }
                        setData(updatedData);
                    }
                });
            }
        }, []);


        const styles = StyleSheet.create({
            content_title: {
                fontFamily: props.theme.fontList.primaryFontBold,
                fontSize: 18,
                letterSpacing: 5.5,
                marginTop: 12,
            },
            container: {
                flex: 1,
                padding: 16,
                paddingTop: 30,
                backgroundColor: '#fff'
            },
            header: {
                height: 50,
                backgroundColor: '#537791'
            },
            text: {
                textAlign: 'center',
                fontWeight: '100'
            },
            dataWrapper: {
                marginTop: -1
            },
            row: {
                height: 40,
                backgroundColor: '#E7E6E1'
            },
            table: {
                marginTop: 20,
                borderWidth: 1,
                width: mediaQueryHelper.desktopBigVersion ? 600 : mediaQueryHelper.desktopVersion ? 500 : mediaQueryHelper.mediumVersion ? 450 : 420
            },
            table_row: {
                paddingHorizontal: 0,
                borderBottomWidth: 0
            },
            table_row_even: {
                paddingHorizontal: 0,
                borderBottomWidth: 0,
                backgroundColor: "#eeeeee"
            },
            cell_with_right_border: {
                borderRightWidth: 1
            },
            cell: {
                paddingHorizontal: 10
            },
            line: {
                width: "95%",
                height: 1,
                backgroundColor: "black",
                alignSelf: "center",
                marginVertical: 10
            },
            total_wrapper: {
                paddingVertical: 4,
                paddingHorizontal: 20,
                flexDirection: "row",
                backgroundColor: props.theme.cart.totalBackgroundColor
            },
            total_title: {
                flex: 1,
                alignSelf: "flex-start",
                fontFamily: props.theme.fontList.primaryFontBold,
                fontSize: 18,
                marginVertical: "auto"
            },
            total_amount: {
                flex: 1,
                flexGrow: 1,
                alignSelf: "flex-end",
                textAlign: "right",
                fontFamily: props.theme.fontList.primaryFontBlack,
                fontSize: props.theme.cart.totalAmountFontSize,
                color: props.theme.colors.primary
            },
            details_wrapper:{
               paddingTop: 40,
                width: mediaQueryHelper.desktopVersion ? 500 : 400
            },
            buttons: {
                paddingTop: 6,
                paddingHorizontal: 24,
                paddingBottom: 6,
                alignSelf: "flex-start"
            },
            buttons_text:{
                textTransform: "uppercase",
                fontFamily: props.theme.fontList.primaryFontExtraBold,
                fontSize: 16
            },
            cancel_button: {
                backgroundColor: "#6c6c6c",
            },
            cancel_button_text: {
                color: "#959595"
            },
            bold_text:{
                fontFamily: props.theme.fontList.primaryFontExtraBold,
            }
        });

        let cartItems = [];
        let total = []
        if (detailedOrder) {
            for (let i = 0; i < detailedOrder.items.length; i++) {
                cartItems.push(<CartItemClient key={detailedOrder.items[i].id + "." + i}
                                               readonly={true}
                                               orderItem={detailedOrder.items[i]}/>);
            }
            total.push(<View style={styles.line} key={1}/>)
            total.push(<View style={styles.total_wrapper} key={2}>
                <Text style={styles.total_title}>{i18n.t("cart.total")}</Text>
                <Text
                    style={styles.total_amount}>{detailedOrder.getPrice(state.clientStore.client.alwaysShowDecimals)}</Text>
            </View>)
        }

        return (
            <ScrollView style={{width: 700}}>
                {detailedOrder &&
                <View style={styles.details_wrapper}>
                    {cartItems}
                    {total}
                    <TouchableOpacity style={[styles.buttons, styles.cancel_button, {marginTop: 20}]} onPress={() => {
                        setDetailedOrder(undefined);
                    }} activeOpacity={props.theme.defaultTouchableOpacity}>
                        <Text style={[styles.buttons_text, styles.cancel_button_text]}>{i18n.t("common.back")}</Text>
                    </TouchableOpacity>
                </View>
                }
                {!detailedOrder &&
                <View>
                    <Text style={styles.content_title}>{i18n.t("account.my_orders.title")}</Text>
                    <DataTable style={styles.table}>
                        <DataTable.Header style={{borderBottomWidth: 0, paddingHorizontal: 0 }}>
                            <DataTable.Title
                                style={[styles.cell_with_right_border, styles.cell]}>{i18n.t("account.my_orders.table.number")}</DataTable.Title>
                            <DataTable.Title
                                style={[styles.cell_with_right_border, styles.cell]}>{i18n.t("account.my_orders.table.date")}</DataTable.Title>
                            <DataTable.Title
                                style={[styles.cell_with_right_border, styles.cell]}>{i18n.t("account.my_orders.table.total")}</DataTable.Title>
                            <DataTable.Title style={styles.cell}> </DataTable.Title>
                        </DataTable.Header>
                        {data.map((order: IOrderModel, index: number) => (
                            <DataTable.Row style={(index % 2 ? styles.table_row : styles.table_row_even)} key={index}>
                                <DataTable.Cell
                                    style={[styles.cell_with_right_border, styles.cell]}><Text style={{fontFamily: props.theme.fontList.primaryFontBold}}>#{order.number}</Text></DataTable.Cell>
                                <DataTable.Cell
                                    style={[styles.cell_with_right_border, styles.cell]}>{order.getFormattedCreateDate("DD/MM/YYYY")}</DataTable.Cell>
                                <DataTable.Cell
                                    style={[styles.cell_with_right_border, styles.cell]}>{order.getPrice(state.clientStore.client.alwaysShowDecimals)}</DataTable.Cell>
                                <DataTable.Cell style={styles.cell}>
                                    <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity}
                                                      onPress={() => {
                                                          let orderModel = new OrderHandler(state);
                                                          orderModel.getOrder(order.id, (retrievedOrder) => {
                                                              setDetailedOrder(retrievedOrder);
                                                          })
                                                      }}>
                                        <Text
                                            style={{color: (props.theme.colors.button_color)}}>{i18n.t("account.my_orders.table.details")}</Text>
                                    </TouchableOpacity>
                                </DataTable.Cell>
                            </DataTable.Row>
                        ))}
                        <DataTable.Pagination
                            style={{borderTopWidth: 1}}
                            page={page}
                            numberOfPages={Math.ceil(data.length / itemsPerPage)}
                            onPageChange={(page: number) => {
                                setPage(page);
                            }}
                            label={`${from + 1}-${to} of ${data.length}`}
                        />
                    </DataTable>
                </View>
                }
            </ScrollView>
        );
    }
);

export default withTheme(MyOrders);