import React from 'react';
import {
    InteractionManager,
    Pressable,
    StyleSheet, TouchableOpacity,
    View
} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {withTheme} from "react-native-paper";
import {Text} from 'react-native-paper';
import FontAwesome from "react-native-vector-icons/FontAwesome";
import {ISortFilterModel} from "../models/SortFilterModel";

export interface SortingWidgetProps {
    theme: ReactNativePaper.Theme,
}

const SortingWidget = observer((props: SortingWidgetProps) => {
    const state = useMst();

    const [isActive, setIsActive] = React.useState(false);

    const styles = StyleSheet.create({
        container: {
            backgroundColor: "white",
            position: "relative",
            width: 190,
            borderColor: "black",
            paddingHorizontal: 12
        },
        dropdown_button: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
        },
        filter_text: {
            fontFamily: props.theme.fontList.primaryFontLight,
            textTransform: "uppercase",
            fontSize: 14,
            letterSpacing: 2,
            paddingVertical: 12,
            paddingHorizontal: 12,
        },
        selected_filter_text: {
            color: props.theme.colors.button_color
        },
        dropdown: {
            position: "absolute",
            width: "100%",
            top: 40,
            left: 0,
            zIndex: 9,
            backgroundColor: "white"
        }
    })

    //this is local variable and we don't want it to be a state
    let optionPressed = false;

    let dropDownButton = <TouchableOpacity
        style={styles.dropdown_button} activeOpacity={props.theme.defaultTouchableOpacity} onPress={() => {
        setIsActive(!isActive);
    }} onBlur={(e) => {
        setTimeout(() => {
            if (optionPressed) {
                optionPressed = false;
                return;
            }
            setIsActive(false);
        }, 50)
    }}>
        <Text style={[styles.filter_text, {paddingRight: 8}]}>{state.selectedSortFilter.getTranslated()}</Text>
        <FontAwesome style={{marginBottom: 4}} name={isActive ? "angle-up" : "angle-down"}
                     size={28}/>
    </TouchableOpacity>;

    if (!isActive) {
        return <View style={styles.container}>
            {dropDownButton}
        </View>
    }

    return <View style={styles.container}>
        {dropDownButton}
        <View style={styles.dropdown}>
            {state.filterList.map((filter: ISortFilterModel) => (
                <Pressable key={filter.key} onPress={() => {
                    state.selectedSortFilter.setKey(filter.key);
                    setIsActive(false);
                }} onPressIn={() => {optionPressed = true;}}>
                    <Text style={[styles.filter_text, ((filter.key == state.selectedSortFilter.key) ? styles.selected_filter_text : {})]}>{filter.getTranslated()}</Text></Pressable>
            ))}
        </View>
    </View>;
})

export default withTheme(SortingWidget);