import {types} from "mobx-state-tree";
import {useMst} from "./RootModel";

export const PriceModel = types.model({
    price: 0,
    currency: "",
}).actions(self => ({

})).views(self => ({
    // state.clientStore.client.alwaysShowDecimals
    getFormatedPrice(alwaysShowDecimals:boolean): string{
        let formatedInput = self.price.toString();
        if (alwaysShowDecimals) {
            formatedInput = self.price.toFixed(2)
        }
        return (!this.isCurrencySuffix() ? this.getCurrency() : '') + formatedInput + '' + (this.isCurrencySuffix() ? this.getCurrency() : '');
    },
    calculatePrice(quantity: number): number{
        return quantity * self.price;
    },
    getCurrency():string {
        switch (self.currency.toLowerCase()) {
            case 'pln':
                return ' zł';
            case 'eur':
                return ' €';
            default:
                return '$';
        }
    },
    isCurrencySuffix():boolean {
        switch (self.currency.toLowerCase()) {
            case 'pln':
                return true;
            case 'eur':
                return true;
            default:
                return false;
        }
    }
}));