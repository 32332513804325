import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {Surface, TextInput, Button, Checkbox, List} from 'react-native-paper';
import {StyleSheet, Image} from 'react-native';
import i18n from '../translations/Translations';

export interface LoginProps {
    theme: ReactNativePaper.Theme
}

const Login = (props: LoginProps) => {

    const [client, setClient] = React.useState('');
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const logo = require('../../assets/logo/' + props.theme.logo);

    const styles = StyleSheet.create({
        surface: {
            padding: 20,
            width: 450,
            elevation: 4,
            alignItems: 'center',
            justifyContent: 'center',
        },
        input: {
            width: "100%",
            marginBottom: 20,
        },
        logo: {
            width: 450,
            height: 200,
            marginBottom: 40,
            resizeMode: "contain",
            alignSelf: "center"
        },
        button: {
            marginTop: 40
        }
    });

    return (
        <Surface style={styles.surface}>
            <Image style={styles.logo} source={logo}/>
            <TextInput style={styles.input}
                       mode="flat"
                       label={i18n.t("loginPage.client")}
                       value={client}
                       onChangeText={text => setClient(text)}
            />
            <TextInput style={styles.input}
                       mode="flat"
                       label={i18n.t("loginPage.username")}
                       value={username}
                       onChangeText={text => setUsername(text)}
            />
            <TextInput style={styles.input}
                       mode="flat"
                       secureTextEntry={true}
                       label={i18n.t("loginPage.password")}
                       value={password}
                       onChangeText={text => setPassword(text)}
            />

            <List.Item
                title={i18n.t("loginPage.rememberMe")}
                left={props => <Checkbox
                    status={checked ? 'checked' : 'unchecked'}
                    onPress={() => {
                        setChecked(!checked);
                    }}
                />}
            />

            <Button style={styles.button} icon="login" mode="contained" onPress={() => console.log('Pressed')}>
                {i18n.t("loginPage.loginButton")}
            </Button>
        </Surface>
    );
}


export default withTheme(Login);