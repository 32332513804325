import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {ScrollView, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import i18n from '../translations/Translations';
import {observer} from "mobx-react-lite";
import {IOptionGroupModel} from "../models/OptionGroupModel";
import {useMst} from "../models/RootModel";
import OptionGroup from "./OptionGroup";
import {OrderHandler} from "../models/OrderModel";
import {IAddonModel} from "../models/AddonModel";
import {useMediaQuery} from "react-responsive";

export interface ErrorPopupProps {
    theme: ReactNativePaper.Theme,
}

const ErrorPopup = observer((props: ErrorPopupProps) => {
    const state = useMst();
    const desktopVersion = useMediaQuery({minWidth: props.theme.desktopBreakpoint});

    function onClosePress() {
        state.errorPopup.clear();
    }

    const styles = StyleSheet.create({
        popup: {
            backgroundColor: "white",
            width: desktopVersion ? 500 : "60%",
            height: "auto",
            alignSelf: "center",
            paddingVertical: 53,
            paddingHorizontal: 84
        },
        error_message:{
            fontSize: 20,
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            alignSelf: "center"
        },
        ok_button: {
            paddingTop: 6,
            paddingHorizontal: 24,
            paddingBottom: 6,
            backgroundColor: props.theme.colors.primary,
        },
        ok_button_text: {
            color: "#fff",
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            fontSize: 16
        },
        buttons_wrapper:{
            flexDirection: "row",
            alignSelf: "center",
            marginTop: 50
        }
    });


    return (
        <View style={styles.popup}>
            <Text style={styles.error_message}>{state.errorPopup.errorMessage}</Text>
            <View style={styles.buttons_wrapper}>
                <TouchableOpacity style={styles.ok_button} onPress={onClosePress} activeOpacity={0.75}>
                    <Text style={styles.ok_button_text}>{i18n.t("common.ok")}</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
});

export default withTheme(ErrorPopup);