import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {ScrollView, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import i18n from '../translations/Translations';
import {observer} from "mobx-react-lite";
import {useMst} from "../models/RootModel";
import {IAddonModel} from "../models/AddonModel";
import Addon from "./Addon";
import {OrderHandler} from "../models/OrderModel";
import {useMediaQuery} from "react-responsive";
import QRCode from 'react-native-qrcode-svg';
import {MediaQueryHelper} from "../helpers/MediaQueryHelper";

export interface QrPopupProps {
    theme: ReactNativePaper.Theme,
}

const QrPopup = observer((props: QrPopupProps) => {
    const state = useMst();
    const desktopVersion = useMediaQuery({minWidth: props.theme.desktopBreakpoint});
    const mediaQueryHelper = MediaQueryHelper();

    function onClosePress() {
        state.qrPopup.clear();
    }

    function confirmCallback() {
        const orderHandlerInstance = new OrderHandler(state);
        orderHandlerInstance.initialize(onClosePress);
    }

    function getQrLink(): string {
        // return window.location.href + "?order=" + state.currentOrder.id
        return window.location.origin + "/home?id=" + state.qrPopup.currentOrderID
    }

    const styles = StyleSheet.create({
        popup: {
            backgroundColor: "white",
            width: desktopVersion ? 600 : "90%",
            alignSelf: "center",
            paddingVertical: mediaQueryHelper.smallVersion ? 24 : 53,
            paddingHorizontal: mediaQueryHelper.smallVersion ? 8 : 34
        },
        close_button: {
            position: "absolute",
            top: 20,
            right: 30
        },
        close_button_text: {
            fontFamily: props.theme.fontList.primaryFontBold,
            color: "#aaaaaa",
            fontSize: 36
        },
        title: {
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            fontSize: 30,
            alignSelf: "center",
            textTransform: "uppercase"
        },
        buttons_wrapper: {
            flexDirection: "row",
            alignSelf: "center",
            marginTop: 50
        },
        buttons: {
            paddingTop: 6,
            paddingHorizontal: 24,
            paddingBottom: 6,
        },
        buttons_text: {
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            fontSize: 16
        },
        cancel_button: {
            backgroundColor: "#6c6c6c",
        },
        cancel_button_text: {
            color: "#959595"
        },
        add_button: {
            backgroundColor: props.theme.colors.primary,
            marginLeft: 24
        },
        add_button_text: {
            color: "#fff"
        },
        qr_code: {
            marginHorizontal: "auto"
        }
    });


    return (
        <View style={styles.popup}>
            {(!mediaQueryHelper.smallVersion) &&
            <TouchableOpacity style={styles.close_button} onPress={onClosePress}>
                <Text style={styles.close_button_text}>×</Text>
            </TouchableOpacity>
            }
            <Text style={styles.title}>{i18n.t("qr_modal.title")}</Text>
            <View style={styles.qr_code}>
                <QRCode
                    value={getQrLink()}
                    size={300}
                />
            </View>
            <View style={styles.buttons_wrapper}>
                <TouchableOpacity style={[styles.buttons, styles.cancel_button]} onPress={onClosePress}
                                  activeOpacity={0.75}>
                    <Text style={[styles.buttons_text, styles.cancel_button_text]}>{i18n.t("common.cancel")}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.buttons, styles.add_button]} onPress={confirmCallback}
                                  activeOpacity={0.75}>
                    <Text style={[styles.buttons_text, styles.add_button_text]}>{i18n.t("common.ok")}</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
});


export default withTheme(QrPopup);