import React from 'react';
import {
    Text,
    StyleSheet,
    View,
    TouchableHighlight
} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {withTheme} from "react-native-paper";


export interface OptionPickerProps {
    theme: ReactNativePaper.Theme,
    max_width: number,
}

const OptionPicker = observer((props: OptionPickerProps) => {
    const state = useMst();
    //TODO add proper starting value
    const [value, setValue] = React.useState('38');
    const styles = StyleSheet.create({
        container: {
            maxWidth: props.max_width,
            flexDirection: "row",
            flexWrap: "wrap",
            alignSelf: "flex-start"
        },
        toggle_button: {
            borderWidth: 2,
            borderColor: "#f0f0f0",
            width: 60,
            height: 40,
            marginRight: 8,
            marginBottom: 8
        },
        active_button: {
            borderColor: "#ffd3ce"
        },
        toggle_text: {
            justifyContent: "center",
            alignSelf: "center",
            padding: 8,
            fontFamily: props.theme.fontList.primaryFontDefault
        }
    });

    function switchButton() {

    }

    function generateToggleButtonOption(key: string) {
        return <TouchableHighlight underlayColor={"#ffd3ce"}
                                   style={[styles.toggle_button, value == key && styles.active_button]} onPress={() => {
            setValue(key);
        }}>
            <Text style={styles.toggle_text}>{key}</Text>
        </TouchableHighlight>
    }

    return (
        <View style={styles.container}>
            {generateToggleButtonOption("37")}
            {generateToggleButtonOption("38")}
            {generateToggleButtonOption("39")}
            {generateToggleButtonOption("40")}
        </View>
    );
})

export default withTheme(OptionPicker);