import {Instance, types} from "mobx-state-tree";
import {TranslatedTextModel} from "./TranslatedTextModel";
import {PriceModel} from "./PriceModel";
import {ProductModel} from "./ProductModel";
import {OrderItemOptionModel} from "./OrderItemOptionModel";
import {AddonModel} from "./AddonModel";
import {OrderItemAddonModel} from "./OrderItemAddonModel";

export const OrderItemModel = types.model({
    id: "",
    price: 0,
    priceCur: "",
    product: types.optional(ProductModel, {}),
    options: types.optional(types.array(OrderItemOptionModel), []),
    addons: types.optional(types.array(OrderItemAddonModel), []),
    quantity: 1,

}).actions(self => ({})).views(self => ({
    getPrice(alwaysShowDecimals: boolean): string{
        let price = PriceModel.create({price: self.price, currency: self.priceCur});
        return price.getFormatedPrice(alwaysShowDecimals);
    },
    getFormatedPrice(priceNumb:number,alwaysShowDecimals: boolean): string{
        let price = PriceModel.create({price: priceNumb, currency: self.priceCur});
        return price.getFormatedPrice(alwaysShowDecimals);
    }
}));

export interface IOrderItemModel extends Instance<typeof OrderItemModel>{}