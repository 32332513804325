import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import ProductsList from "./ProductsList";
import {IProductModel} from "../models/ProductModel";
import i18n from "../translations/Translations";

export interface ProductPropositionsProps {
    theme: ReactNativePaper.Theme,
    product_list :Array<IProductModel>,
    maxWidth?: number
}

const ProductPropositions = observer((props: ProductPropositionsProps) => {
    const state = useMst();

    const styles = StyleSheet.create({
        container: {
            maxWidth: props.maxWidth ? props.maxWidth : 886,
            borderWidth: 1,
            borderColor: "black",
            borderStyle: "solid",
            marginTop: 50,
            padding: 20
        },
        title: {
            backgroundColor: "white",
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontBold,
            fontSize: 14,
            letterSpacing: 2,
            position: "absolute",
            top: -12,
            left: 15,
            paddingHorizontal: 18
        }
    });

    return (
        <View style={styles.container}>
            <Text style={styles.title}>{i18n.t("products_proposition.title")}</Text>
            <ProductsList products={props.product_list} marginHorizontal={10}/>
        </View>
    );
});


export default withTheme(ProductPropositions);