import { types, Instance, onSnapshot, cast } from "mobx-state-tree";

const OrderPopupModel = types
    .model({
        isPopupVisible: false
    })
    .actions(self => ({
        setIsPopupVisible(v: boolean){
            self.isPopupVisible = v;
        }
    }))

let initialState = OrderPopupModel.create();

export const orderPopupStore = initialState;

