import {Text, withTheme} from 'react-native-paper';
import * as React from 'react';
import {Image, StyleSheet, TextInput, TouchableHighlight, TouchableOpacity, View} from 'react-native';

import i18n from '../translations/Translations';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {useMediaQuery} from "react-responsive";
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import CartNumberItemsCircle from "./CartNumberItemsCircle";
import CategoriesDropDown from "./CategoriesDropDown";
import {useNavigation} from "@react-navigation/native";


export interface HeaderProps {
    theme: ReactNativePaper.Theme,
}

const HomeHeader = observer((props: HeaderProps) => {
        const state = useMst();
        const logo = require('../../assets/logo/' + props.theme.logo,);
        const desktopVersion = useMediaQuery({minWidth: 1074})
        const [showCart, setCartState] = React.useState(false);
        const navigation = useNavigation();
        const [isAccountOptionsVisible, setIsAccountOptionsVisible] = React.useState(false);

        const [timer, setTimer] = React.useState<NodeJS.Timeout>();

        function searchBarOnChangeText(text: string) {
            if (timer) {
                clearTimeout(timer)
            }
            state.searchString.setValue(text);
            setTimer(setTimeout(() => {
                navigation.navigate("search_result_page");
            }, 500));
        }

        const styles = StyleSheet.create({
            header: {
                height: 170,
                paddingHorizontal: 160
            },
            separator: {
                backgroundColor: "black",
                height: 1,
                width: "100%",
                zIndex: -1
            },
            top_header: {
                flexDirection: "row",
                justifyContent: "space-between"
            },
            logo: {
                width: 410,
                maxWidth: 410,
                height: 86,
                alignSelf: "flex-start"
            },
            top_part: {
                marginTop: 26,
                height: 36,
                flexDirection: "row",
            },
            input_style: {
                alignSelf: "center",
                height: 36,
                width: 360,
                outlineColor: "#00000000",
                backgroundColor: "#eeeeee",
                paddingLeft: 16,
                paddingRight: 16,
                fontFamily: props.theme.fontList.primaryFontDefault,
                letterSpacing: 2
            },
            account_container: {
                flexDirection: "row",
            },
            account_button: {
                marginLeft: 76
            },
            account_icon: {
                tintColor: "#000000",
                height: "100%",
                width: 36
            },
            account_log_container: {
                paddingHorizontal: 12,
                letterSpacing: 2
            },
            account_text: {
                fontFamily: props.theme.fontList.primaryFontDefault,
                lineHeight: 18
            },
            logout: {
                fontFamily: props.theme.fontList.primaryFontDefault,
                lineHeight: 18
            },
            cart_button: {
                marginLeft: 88
            },
            cart_container: {
                flexDirection: "row",
            },
            cart_text_container: {
                paddingLeft: 18,
                letterSpacing: 2,
                lineHeight: 4
            },
            cart_items_counter: {
                backgroundColor: "#ff5948",
                borderRadius: 1118,
                position: "absolute",
                left: 28,
                top: 8
            },
            cart_number_items_circle: {
                fontFamily: props.theme.fontList.primaryFontMedium,
                color: "white",
                backgroundColor: "#ff5948",
            },
            cart_items_counter_text: {
                fontFamily: props.theme.fontList.primaryFontMedium,
                color: "white",
                alignSelf: "center",
                fontSize: 12,
                lineHeight: 15
            },
            cart_text: {
                fontFamily: props.theme.fontList.primaryFontDefault,
                lineHeight: 18
            },
            cart_value: {
                fontFamily: props.theme.fontList.primaryFontMedium,
                lineHeight: 18
            },
            navigation: {
                height: 50,
                flexDirection: "row",
                justifyContent: "space-between",
                zIndex: -1
            },
            navigation_text: {
                fontFamily: props.theme.fontList.primaryFontMedium,
                textTransform: "uppercase",
                fontSize: 18,
                letterSpacing: 2,
                alignSelf: "center",
                alignContent: "center",
                justifyContent: "center",
            },
            navigation_spacing: {
                width: 36
            },
            navigation_left: {
                flexDirection: "row",
                paddingRight: 16//this is to extend the touchable zone so it feels more natural
            },
            navigation_right: {
                flexDirection: "row",
                alignSelf: "center"
            },
            account_options_wrapper: {
                position: "absolute",
                top: 40,
                left: 100,
                backgroundColor: "white",
                paddingHorizontal: 20,
                paddingVertical: 15,
                flexDirection: "column",
            },
            account_options_text:{
                fontFamily: props.theme.fontList.primaryFontBold,
                fontSize: 14,
                marginVertical: 5
            }
        });




        return <View style={styles.header}>
            <View style={styles.top_header}>
                <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={() => {
                    //TODO search string doesn't get reseted sometimes no idea why
                    state.searchString.setValue("");

                    navigation.navigate("home")
                }}>
                    <Image style={styles.logo} source={logo}/>
                </TouchableOpacity>
                <TextInput style={styles.input_style} onChangeText={searchBarOnChangeText}
                           placeholder={i18n.t("header.search_placeholder")} value={state.searchString.value}/>
                <View style={styles.top_part}>
                    <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} style={styles.account_button}
                                      onPress={() => {
                                          if (state.account.loggedIn) {
                                              setIsAccountOptionsVisible(!isAccountOptionsVisible);
                                          } else {
                                              state.searchString.setValue("");
                                              navigation.navigate("login");
                                          }
                                      }}>
                        <View style={styles.account_container}>
                            <MaterialCommunityIcons name="account-circle-outline" size={36}/>
                            <View style={styles.account_log_container}>
                                <Text style={styles.account_text}>{i18n.t("header.account")}</Text>
                                <Text style={styles.logout}>{i18n.t("header.logout")}</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
                    {(isAccountOptionsVisible && state.account.loggedIn) &&
                    <View style={styles.account_options_wrapper}>
                        <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={() => {
                            setIsAccountOptionsVisible(false);
                            navigation.navigate("account");
                        }}>
                            <Text style={styles.account_options_text}>{i18n.t("common.pages.account")}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={() => {
                            setIsAccountOptionsVisible(false);
                            state.customer.setLoggedIn(false);
                        }}>
                            <Text style={styles.account_options_text}>{i18n.t("common.log_out")}</Text>
                        </TouchableOpacity>

                    </View>
                    }
                    <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} style={styles.cart_button}
                                      onPress={() => {
                                          state.searchString.setValue("");
                                          navigation.navigate('cart')
                                      }}>
                        <View style={styles.cart_container}>
                            <FontAwesome name="shopping-basket" size={36}/>
                            <CartNumberItemsCircle fontFamily={props.theme.fontList.primaryFontMedium}
                                                   backgroundColor={props.theme.colors.button_color} size={12} top={12}
                                                   left={40}/>
                            <View style={styles.cart_text_container}>
                                <Text style={styles.cart_text}>{i18n.t("header.cart")}</Text>
                                <Text
                                    style={styles.cart_value}>{state.currentOrder.getPrice(state.clientStore.client.alwaysShowDecimals)}</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
            <View style={styles.separator}/>
            <View style={styles.navigation}>
                <View>
                    <CategoriesDropDown/>
                </View>
                <View style={styles.navigation_right}>
                    <TouchableOpacity><Text style={styles.navigation_text}>Special offers</Text></TouchableOpacity>
                    <View style={styles.navigation_spacing}/>
                    <TouchableOpacity><Text style={styles.navigation_text}>Blog</Text></TouchableOpacity>
                    <View style={styles.navigation_spacing}/>
                    <TouchableOpacity><Text style={styles.navigation_text}>Join Us</Text></TouchableOpacity>
                    <View style={styles.navigation_spacing}/>
                    <TouchableOpacity><Text style={styles.navigation_text}>Contact</Text></TouchableOpacity>
                </View>
            </View>
            <View style={styles.separator}/>
        </View>;
    }
);

export default withTheme(HomeHeader);