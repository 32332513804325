import React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import Header from '../components/HomeHeader';
import {withTheme} from "react-native-paper";
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {useMediaQuery} from "react-responsive";
import i18n from '../translations/Translations';
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import {OrderHandler} from "../models/OrderModel";
import ProductListSortingSearch from "../components/ProductListSortingSearch";
import OrderPage from "../components/OrderPage";

export interface CartPageProps {
    theme: ReactNativePaper.Theme
}

const CartPage = observer((props: CartPageProps) => {
    const state = useMst();
    const [showCart, setCartState] = React.useState(false);
    const desktopVersion = useMediaQuery({minWidth: props.theme.desktopBreakpoint})

    const styles = StyleSheet.create({
        container: {
            backgroundColor: "white"
        },
        modal: {
            elevation: 0
        },
        product_page: {
            paddingHorizontal: 160, flexDirection: "row", justifyContent: "center", zIndex: -1
        }
    });

    const orderHandler = new OrderHandler(state);
    if (state.customer.orderID.length > 0 && state.customer.orderID != state.currentOrder.id) {
        orderHandler.resumeOrder(state.customer.orderID, () => {
            if (state.currentOrder.id.length > 0 && state.currentOrder.items.length > 0) {
                setCartState(true)
            }
        });
    }
    return (
            <View style={styles.container}>
                <ScrollView style={{height: "100vh", paddingBottom: 32}}>
                    <Header/>
                    <View style={{paddingHorizontal: 160, zIndex: -1}}>
                        <OrderPage/>
                    </View>
                    <View style={{zIndex: -1}}>
                        <Footer verticalMargin={50}/>
                        <Copyright/>
                    </View>
                </ScrollView>
            </View>
    );
})

export default withTheme(CartPage);