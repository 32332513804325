import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {Text} from 'react-native-paper';
import {StyleSheet, Image, View, TouchableOpacity} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";

import {IProductModel} from "../models/ProductModel";
import {OrderHandler} from "../models/OrderModel";
import {IAddonModel} from "../models/AddonModel";
import {MediaQueryHelper} from "../helpers/MediaQueryHelper";

export interface ProductProps {
    theme: ReactNativePaper.Theme,
    product: IProductModel,
    dummy?: boolean
}

const DisplayItem = observer((props: ProductProps) => {
    const state = useMst();
    const mediaQueryHelper = MediaQueryHelper();

    function onProductPress() {
        state.setCurrentProduct(props.product);
        if (props.product.optionsGroups.length > 0) {
            state.optionsPopup.setFromAddon(false);
            state.optionsPopup.setIsPopupVisible(true);
        } else {
            const orderHandlerInstance = new OrderHandler(state);
            if (state.currentOrder.id.length === 0){
                orderHandlerInstance.initialize(onProductPress);
                return;
            }
            orderHandlerInstance.addProduct(props.product.id, [], () => {
                state.productsStore.loadAddons(props.product, (addons: Array<IAddonModel>) => {
                    if (addons.length > 0) {
                        state.addonsPopup.setAddons(addons);
                        state.addonsPopup.setIsPopupVisible(true);
                    }
                });
            })
        }
    }

    let showCart = false;
    if (state.currentOrder.id.length > 0 && state.currentOrder.items.length > 0) {
        showCart = true;
    }

    const styles = StyleSheet.create({
        container: {
            flexDirection: "row",
            padding: mediaQueryHelper.smallVersion ? 5 : 20,
            width: mediaQueryHelper.desktopBigVersion ? 788 : mediaQueryHelper.desktopVersion ? (showCart ? 600 : 700) : mediaQueryHelper.smallVersion ? 300 : 600,
            cursor: "pointer"
        },
        product_image: {
            width: mediaQueryHelper.smallVersion ? 50 : 130,
            height: mediaQueryHelper.smallVersion ? 50 : 130,
            borderRadius: 100
        },
        product_info: {
            flexBasis: 550,
            paddingLeft: 20,
            paddingTop: 10,
            flexShrink: 1
        },
        product_name: {
            fontSize: mediaQueryHelper.smallVersion ? 12 : 16,
            fontFamily: props.theme.fontList.primaryFontBlack,
            textTransform: "uppercase"
        },
        product_description: {
            fontSize: mediaQueryHelper.smallVersion ? 9 : 12,
            fontFamily: props.theme.fontList.primaryFontMedium,
        },
        black_line: {
            width: "80%",
            height: 2,
            backgroundColor: "black",
            marginTop: 5,
            marginBottom: 10
        },
        allergens_container: {
            flexDirection: "row",
            flexWrap: "wrap",
            paddingTop: 5
        },
        allergen_icon: {
            width: 30,
            height: 30,
            filter: props.theme.allergenColorFilter,
            marginTop: 5
        },
        price_view: {
            paddingTop: mediaQueryHelper.smallVersion ? 10 : 20,
            paddingLeft: 10
        },
        price_text: {
            fontSize: mediaQueryHelper.smallVersion ? 16 : 20,
            fontFamily: props.theme.fontList.primaryFontMedium
        }
    });


    const allergensArray = props.product.allergens.map(allergen => (
        <Image style={styles.allergen_icon} key={allergen.id} source={require("../../" + allergen.getIcon())}/>
    ))

    let product = <View/>;
    if (!props.dummy) {
        product = <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={onProductPress}>
            <View style={styles.container}>
                <Image style={styles.product_image} source={{uri: props.product.getImage()}}/>
                <View style={styles.product_info}>
                    <Text style={styles.product_name}>{props.product.name.getTranslated()}</Text>
                    <View style={styles.black_line}/>
                    <Text style={styles.product_description}>{props.product.description.getTranslated()}</Text>
                    <View style={styles.allergens_container}>
                        {allergensArray}
                    </View>
                </View>
                <View style={styles.price_view}>
                    <Text style={styles.price_text}>{props.product.getPrice(state.clientStore.client.alwaysShowDecimals)}</Text>
                </View>
            </View>
        </TouchableOpacity>
    }
    return product;
});


export default withTheme(DisplayItem);