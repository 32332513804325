import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {ScrollView, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import i18n from '../translations/Translations';
import {observer} from "mobx-react-lite";
import {IOptionGroupModel} from "../models/OptionGroupModel";
import {useMst} from "../models/RootModel";
import OptionGroup from "./OptionGroup";
import {OrderHandler} from "../models/OrderModel";
import {IAddonModel} from "../models/AddonModel";
import {useMediaQuery} from "react-responsive";

export interface OptionsPopupProps {
    theme: ReactNativePaper.Theme,
}

const OptionsPopup = observer((props: OptionsPopupProps) => {
    const state = useMst();
    const desktopVersion = useMediaQuery({minWidth: props.theme.desktopBreakpoint});

    function onClosePress() {
        state.optionsPopup.clear();
        if (state.optionsPopup.isFromAddon){
            state.addonsPopup.setIsPopupVisible(true);
        }
    }

    function addSelectedOptions() {
        if (state.optionsPopup.isFromAddon){
            for (let i = 0; i < state.addonsPopup.addons.length; i++) {
                const addon = state.addonsPopup.addons[i];
                if (addon.id === state.optionsPopup.addonID){
                    let options = [];
                    for (let j = 0; j < state.optionsPopup.selectedOptions.length; j++) {
                        const selectedOption = state.optionsPopup.selectedOptions[j];
                        options.push(selectedOption.toString())
                    }

                    addon.setOptions(options);
                    addon.setSelected(true);
                    break;
                }
            }
            state.optionsPopup.clear();
            state.addonsPopup.setIsPopupVisible(true);
            return;
        }

        const orderHandlerInstance = new OrderHandler(state);

        if (state.currentOrder.id.length === 0){
            orderHandlerInstance.initialize(addSelectedOptions);
            return;
        }
        orderHandlerInstance.addProduct(state.currentProduct.id, state.optionsPopup.selectedOptions, () => {
            state.optionsPopup.clear();
            state.productsStore.loadAddons(state.currentProduct, (addons: Array<IAddonModel>) => {
                if (addons.length > 0){
                    state.addonsPopup.setAddons(addons);
                    state.addonsPopup.setIsPopupVisible(true);
                }
            });
        })
    }

    const styles = StyleSheet.create({
        popup: {
            backgroundColor: "white",
            width: desktopVersion ? 850 : "90%",
            height: "80vh",
            alignSelf: "center",
            paddingVertical: 53,
            paddingHorizontal: desktopVersion ? 84 : 20
        },
        close_button: {
            position: "absolute",
            top: 20,
            right: 30
        },
        close_button_text: {
            fontFamily: props.theme.fontList.primaryFontBold,
            color: "#aaaaaa",
            fontSize: 36
        },
        title: {
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            fontSize: 25,
            alignSelf: "center",
            textTransform: "uppercase",
            letterSpacing: 2
        },
        product_description: {
            fontStyle: "italic",
            fontSize: 16,
            fontFamily: props.theme.fontList.primaryFontDefault,
            marginTop: 32,
            marginBottom: 16
        },
        buttons_wrapper:{
            flexDirection: "row",
            alignSelf: "center",
            marginTop: 50
        },
        buttons: {
            paddingTop: 6,
            paddingHorizontal: 24,
            paddingBottom: 6,
        },
        buttons_text:{
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            fontSize: 16
        },
        cancel_button: {
            backgroundColor: "#6c6c6c",
        },
        cancel_button_text: {
            color: "#959595"
        },
        add_button: {
            backgroundColor: props.theme.colors.primary,
            marginLeft: 24
        },
        add_button_text: {
            color: "#fff"
        }
    });


    return (
        <View style={styles.popup}>
            <TouchableOpacity style={styles.close_button} onPress={onClosePress}>
                <Text style={styles.close_button_text}>×</Text>
            </TouchableOpacity>
            <Text style={styles.title}>{i18n.t("options_modal.title")}</Text>
            <ScrollView style={{height: "70%"}}>
            <Text style={styles.product_description}>{state.currentProduct.description.getTranslated()}</Text>
            <View>
                {state.currentProduct.optionsGroups.map((optionGroup: IOptionGroupModel) => (
                    <OptionGroup key={optionGroup.id} group={optionGroup}/>
                ))}
            </View>
            </ScrollView>
            <View style={styles.buttons_wrapper}>
                <TouchableOpacity style={[styles.buttons, styles.cancel_button]} onPress={onClosePress} activeOpacity={0.75}>
                    <Text style={[styles.buttons_text, styles.cancel_button_text]}>{i18n.t("common.cancel")}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.buttons, styles.add_button]} onPress={addSelectedOptions}  activeOpacity={0.75}>
                    <Text style={[styles.buttons_text, styles.add_button_text]}>{i18n.t("common.add")}</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
});

export default withTheme(OptionsPopup);