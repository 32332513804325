import * as React from 'react';
import {Text, withTheme} from 'react-native-paper';
import {StyleSheet, TextInput, TouchableOpacity, View} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {observer} from "mobx-react-lite";
import {useMst} from "../models/RootModel";

import i18n from '../translations/Translations';
import {NetworkService} from "../services/NetworkService";
import {runInAction} from "mobx";

const CryptoJS = require("crypto-js");

export interface LoginProps {
    theme: ReactNativePaper.Theme,
    withoutTitle?: boolean
}

const Login = observer((props: LoginProps) => {
    // global state
    const state = useMst();
    const navigation = useNavigation();

    // local state
    const [client, setClient] = React.useState(state.account.email.toString());
    const [secretKey, setSecretKey] = React.useState("");
    const [loginError, setLoginError] = React.useState("");

    React.useEffect(() => {
        return navigation.addListener('focus', () => {
            if (state.account.loggedIn) {
                console.log('already logged in')
                navigateToDestination();
            }
        });
    }, [navigation]);


    // functions
    function validate() {
        return (client.length > 0 && secretKey.length > 0)
    }

    function navigateToDestination() {
        state.searchString.setValue("");
        navigation.navigate("account");
    }

    function login() {
        if (validate()) {
            NetworkService.getRequest("api/login/" + client + "/" + CryptoJS.SHA1(secretKey).toString()).then(response => {
                if (response.status && response.status != 200) {
                    switch (response.status) {
                        case 402:
                            setLoginError(i18n.t("login_page.errors.inactive"));
                            break;
                        default:
                            setLoginError(i18n.t("login_page.errors.incorrect"));
                            break;
                    }
                } else {
                    runInAction(() => {
                        state.account.setId(response.id);
                        state.account.setFirstName(response.firstName);
                        state.account.setLastName(response.name);
                        state.account.setPhoneNumber(response.phoneNumber);
                        state.account.setEmail(response.email);
                        state.account.setLoggedIn(true);
                        if (response.customer_id){
                            state.customer.setId(response.customer_id);
                        }else{
                            state.customer.logOut();
                        }
                        setClient("");
                        setSecretKey("");
                        navigateToDestination();
                    })
                }
            })

        } else {
            setLoginError(i18n.t("login_page.errors.missing"));
        }
    }

    function forgotPassword() {
        navigation.navigate("forgot_password");
    }

    function createAccount() {
        navigation.navigate("create_account");
    }

    const styles = StyleSheet.create({
        container: {
            padding: 20,
            width: 420,
            elevation: 4,
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: "center"
        },
        title: {
            fontFamily: props.theme.fontList.primaryFontBold,
            fontSize: 20,
            letterSpacing: 4,
            marginBottom: 16
        },
        input_label: {
            fontFamily: props.theme.fontList.primaryFontDefault,
            fontSize: 15,
            color: "#aaaaaa",
            letterSpacing: 2,
            marginBottom: 10,
            marginTop: 20
        },
        input: {
            alignSelf: "center",
            height: 36,
            width: "100%",
            outlineColor: "#00000000",
            backgroundColor: "#eeeeee",
            paddingLeft: 16,
            paddingRight: 16,
            fontFamily: props.theme.fontList.primaryFontDefault,
            letterSpacing: 2
        },
        logo: {
            width: 450,
            height: 200,
            marginBottom: 40,
            resizeMode: "contain",
            alignSelf: "center"
        },
        button: {
            marginHorizontal: 10,
            backgroundColor: props.theme.colors.button_color,
            width: "100%",
            height: 36
        },
        button_text: {
            color: "white",
            alignSelf: "center",
            marginVertical: "auto",
            fontFamily: props.theme.fontList.primaryFontBold,
            letterSpacing: 3
        },
        bottom_text_wrapper: {
            flexDirection: "row",
            marginTop: 30,
            width: "100%",
            justifyContent: "space-between"
        },
        bottom_text: {
            fontFamily: props.theme.fontList.primaryFontDefault,
            fontSize: 10,
            letterSpacing: 1.8
        },
        login_error: {
            marginTop: 28,
            marginBottom: 10,
            color: props.theme.colors.button_color,
            fontSize: 14,
            alignSelf: "center",
            flexWrap: "wrap"
        }
    });

    return (
        <View style={styles.container}>
            {!props.withoutTitle &&
            <Text style={styles.title}>{i18n.t("login_page.title")}</Text>
            }
            <Text style={styles.input_label}>{i18n.t("login_page.login")}</Text>
            <TextInput style={styles.input}
                       value={client}
                       textContentType={"emailAddress"}
                       onChangeText={text => {
                           setLoginError("");
                           setClient(text);
                       }}
            />
            <Text style={styles.input_label}>{i18n.t("login_page.password")}</Text>
            <TextInput style={styles.input}
                       secureTextEntry={true}
                       textContentType={"password"}
                       value={secretKey}
                       onChangeText={text => {
                           setLoginError("");
                           setSecretKey(text);
                       }}
            />
            <Text style={styles.login_error}>{loginError}</Text>
            <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} style={styles.button} onPress={login}>
                <Text style={styles.button_text}>{i18n.t("login_page.sign_in")}</Text>
            </TouchableOpacity>
            <View style={styles.bottom_text_wrapper}>
                <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={createAccount}>
                    <Text style={[styles.bottom_text]}>{i18n.t("login_page.create")}</Text>
                </TouchableOpacity>
                <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={forgotPassword}>
                    <Text style={styles.bottom_text}>{i18n.t("login_page.forgot")}</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
});

export default withTheme(Login);