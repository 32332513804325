import { types, Instance, onSnapshot, cast } from "mobx-state-tree";
import {IProductModel, ProductModel} from "./ProductModel";

const OptionPopupModel = types
    .model({
        isPopupVisible: false,
        selectedOptions: types.array(types.string),
        isFromAddon: false,
        addonID: types.optional(types.string, "")
    })
    .actions(self => ({
        setIsPopupVisible(v: boolean){
            self.isPopupVisible = v;
        },
        toggleSelected(optionID: string){
            const index = self.selectedOptions.indexOf(optionID);
            if (index > -1){
                self.selectedOptions.splice(index, 1);
            }else{
                self.selectedOptions.push(optionID);
            }
        },
        setFromAddon(v: boolean){
            self.isFromAddon = v;
        },
        setAddonID(v: string){
            self.addonID = v;
        },
        clear(){
            self.isPopupVisible = false;
            self.selectedOptions = cast([]);
        }
    }))

let initialState = OptionPopupModel.create();

export const optionPopupStore = initialState;

