import {Instance, types} from "mobx-state-tree";
import {TranslatedTextModel} from "./TranslatedTextModel";
import {PriceModel} from "./PriceModel";

const OptionRefModel = types.model({
    id: "",
    name: types.optional(TranslatedTextModel, {}),
});

export const OptionModel = types.model({
    id: "",
    option: types.optional(OptionRefModel, {}),
    priceCur: "",
    price: 0,
    selected: false
}).actions(self => ({
    setSelected(v: boolean){
        self.selected = v;
    }
})).views(self => ({
    getPrice(alwaysShowDecimals: boolean): string {
        let price = PriceModel.create({price: self.price, currency: self.priceCur});
        return price.getFormatedPrice(alwaysShowDecimals);
    },
}));

export interface IOptionModel extends Instance<typeof OptionModel>{}