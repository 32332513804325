import React from 'react';
import {
    Image,
    StyleSheet,
    TouchableOpacity,
    View,
    TouchableHighlight, FlatList
} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {withTheme} from "react-native-paper";
import FontAwesome from "react-native-vector-icons/FontAwesome";


export interface GalleryWithScrollProps {
    theme: ReactNativePaper.Theme,
    backgroundColor: string,
    main_size: number,
    spacing?: number,
    uriList: string[]
}

const GalleryWithScroll = observer((props: GalleryWithScrollProps) => {
    const scrollRef = React.createRef<FlatList>();
    const state = useMst();
    let itemIndex = 0;
    let smallGalleryItemWidth = (props.main_size + (props.spacing ? props.spacing : 0)) * 0.25;
    let itemIndexOffSetValue = Math.ceil(props.main_size / (smallGalleryItemWidth + (props.spacing ? props.spacing : 0)) / 2);

    const styles = StyleSheet.create({
        container: {
            width: props.main_size,
            height: (props.main_size + (props.spacing ? props.spacing : 0)) * 1.25,
        },
        main_image: {width: props.main_size, height: props.main_size},
        scroll_view: {
            paddingTop: props.spacing
        },
        small_image_item: {
            width: (props.main_size + (props.spacing ? props.spacing : 0)) * 0.25,
            height: (props.main_size + (props.spacing ? props.spacing : 0)) * 0.25
        },
        item_spacing: {width: props.spacing},
        scroll_button: {
            width: (props.main_size + (props.spacing ? props.spacing : 0)) * 0.075,
            height: (props.main_size + (props.spacing ? props.spacing : 0)) * 0.25,
            position: "absolute",
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            justifyContent: "center"
        },
        scroll_button_left: {
            left: 0,
        },
        scroll_button_right: {
            right: 0
        },
        icon: {
            color: "white",
            alignSelf: "center",
            opacity: 1
        }
    });


    function setItemIndex(newItemIndex: number) {
        if (newItemIndex <= 0) {
            itemIndex = 0;
            return;
        }
        if (newItemIndex + itemIndexOffSetValue >= props.uriList.length) {
            return;
        }
        itemIndex = newItemIndex;
    }

    function scrollLeft() {
        setItemIndex(itemIndex - 1);
        scrollRef.current?.scrollToIndex({animated: true, index: itemIndex});
    }

    function scrollRight() {
        setItemIndex(itemIndex + 1);
        scrollRef.current?.scrollToIndex({animated: true, index: itemIndex});
    }

    let renderItem = ({item, index}: { item: string, index: number }) => (
        (<View style={{
                flexDirection: "row",
            }} key={keyExtractorItem(item, index)}>
                <View style={{width: (index == 0 ? 36 : 0)}}/>
                <TouchableHighlight>
                    <Image style={styles.small_image_item} source={{uri: item}}/>
                </TouchableHighlight>
                <View
                    style={{width: (props.spacing ? props.spacing : 0) + (index == props.uriList.length - 1 ? 16 : 0)}}/>
            </View>
        ));

    function keyExtractorItem(item: string, index: number) {
        return item.split("").reduce(function (a, b) {
            a = ((a << 5) - a) + b.charCodeAt(0);
            return a & a
        }, 0).toString() + index;
    }

    return (
        <View style={styles.container}>
            <Image style={styles.main_image} source={{uri: props.uriList[0]}}/>
            <FlatList data={props.uriList}
                      style={styles.scroll_view}
                      renderItem={renderItem}
                      keyExtractor={keyExtractorItem}
                      horizontal={true} ref={scrollRef}
                      showsVerticalScrollIndicator={false}
                      showsHorizontalScrollIndicator={false}>
            </FlatList>


            <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity}
                              onPress={scrollLeft} style={[styles.scroll_button, styles.scroll_button_left]}>
                <FontAwesome name="caret-left" size={36} style={styles.icon}/>
            </TouchableOpacity>
            <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity}
                              onPress={scrollRight} style={[styles.scroll_button, styles.scroll_button_right]}>
                <FontAwesome name="caret-right" size={36} style={styles.icon}/>
            </TouchableOpacity>
        </View>
    );
})

export default withTheme(GalleryWithScroll);