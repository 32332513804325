import React from 'react';
import {Image, Text, StyleSheet, TouchableOpacity, View} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {withTheme} from "react-native-paper";
import Menu from "./Menu";
import {IProductCategoryModel} from "../models/ProductCategoryModel";
import Cart from "./Cart";
import CartNumberItemsCircle from "./CartNumberItemsCircle";

export interface MobileHeaderProps {
    theme: ReactNativePaper.Theme
}

const MobileHeader = observer((props: MobileHeaderProps) => {
    const [isMenuVisible, setIsMenuVisible] = React.useState(false);
    const [isCartVisible, setIsCartVisible] = React.useState(false);

    const state = useMst();

    const menuImage = require("../../assets/menu.png");
    const cartImage = require("../../assets/cart.png");
    const filteredCategories = state.categoriesStore.categories.filter((tmpCategory: IProductCategoryModel) => tmpCategory.getProducts(state.productsStore.products).length > 0);

    let showCart = false;
    if (state.currentOrder.id.length > 0 && state.currentOrder.items.length > 0) {
        console.log(state.currentOrder.items[0].product)
        showCart = true;
    }

    const styles = StyleSheet.create({
        container: {
            position: "absolute",
            top: 0,
            left: 0,
            height: 90,
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            backgroundColor: "white",
            paddingHorizontal: 20
        },
        menu_image: {
            width: 33,
            height: 42,
        },
        logo: {
            height: 65,
            width: 300,
            resizeMode: "center",
        },
        cart_image: {
            width: 33,
            height: 42,
        },
        menu_view: {
            position: "absolute",
            top: 90,
            alignSelf: "center",
            backgroundColor: "white",
            width: "90%",
            alignItems: "center",
            paddingBottom: 20
        },
        cart_view: {
            position: "absolute",
            top: 90,
            alignSelf: "center",
            backgroundColor: "white",
            width: "100%",
            alignItems: "center",
            paddingHorizontal: 50,
            paddingBottom: 20
        },
        count_wrapper:{
            backgroundColor: props.theme.colors.primary,
            width: 22,
            height: 22,
            borderRadius: 10,
            position: "absolute",
            bottom: -5,
            right: -10
        },
        count_text:{
            alignSelf: "center",
            color: "white",
            fontFamily: props.theme.fontList.primaryFontDefault
        }
    });

    return (
        <View style={styles.container}>
            <TouchableOpacity activeOpacity={0.9} onPress={() => {
                setIsMenuVisible(!isMenuVisible);
                setIsCartVisible(false);
            }}>
                <Image style={styles.menu_image} source={menuImage}/>
            </TouchableOpacity>
            <Image style={styles.logo} source={{uri: state.clientStore.client.getLogoImage()}}/>
            <TouchableOpacity activeOpacity={0.9} onPress={() => {
                if (showCart) {
                    setIsCartVisible(!isCartVisible);
                } else {
                    setIsCartVisible(false);
                }
                setIsMenuVisible(false);
            }}>
                <View>
                    <Image style={styles.cart_image} source={cartImage}/>
                    <CartNumberItemsCircle  size={16} backgroundColor={props.theme.colors.primary} top={30} left={30}/>
                </View>
            </TouchableOpacity>
            {isMenuVisible &&
            <View style={styles.menu_view}>
                <Menu categories={filteredCategories} centered={true}/>
            </View>
            }
            {isCartVisible &&
            <View style={styles.cart_view}>
                <Cart/>
            </View>
            }
        </View>
    );
})

export default withTheme(MobileHeader);