import { types, cast } from "mobx-state-tree";
import {AddonModel, IAddonModel} from "./AddonModel";

const AddonPopupModel = types
    .model({
        isPopupVisible: false,
        addons: types.optional(types.array(AddonModel), []),
        currentOrderItemID: ""
    })
    .actions(self => ({
        setIsPopupVisible(v: boolean){
            self.isPopupVisible = v;
        },
        setAddons(addons: Array<IAddonModel>){
            self.addons = cast(addons);
        },
        clear(){
            self.isPopupVisible = false;
            this.setAddons([]);
        },
        setCurrentOrderItemID(v: string){
            self.currentOrderItemID = v;
        }
    })).views(self => ({
        getSelectedAddons():Array<IAddonModel>{
            return self.addons.filter((addon: IAddonModel) => {return addon.selected});
        }
    }))

let initialState = AddonPopupModel.create();

export const addonPopupStore = initialState;

