import React from 'react';

import {ImageBackground, ScrollView, StyleSheet, TouchableOpacity, View} from 'react-native';
import Header from '../components/HomeHeader';
import {Text, withTheme} from "react-native-paper";
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {useMediaQuery} from "react-responsive";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import {OrderHandler} from "../models/OrderModel";
import Iframe from "react-iframe";
import {IProductCategoryModel} from "../models/ProductCategoryModel";
import {useNavigation} from "@react-navigation/native";
import {IProductModel} from "../models/ProductModel";
import ProductsGallery from "../components/ProductsGallery";
import ArticlesList from "../components/ArticlesList";

export interface HomePageProps {
    theme: ReactNativePaper.Theme
}

const HomePage = observer((props: HomePageProps) => {
    const state = useMst();
    const [showCart, setCartState] = React.useState(false);
    const desktopVersion = useMediaQuery({minWidth: props.theme.desktopBreakpoint})
    const navigation = useNavigation();

    const styles = StyleSheet.create({
        container: {
            backgroundColor: "white"
        },
        categories_container: {
            paddingHorizontal: 160,
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            zIndex: -1
        },
        category_container: {
            flex: 1,
            flexGrow: 1,
            minWidth: 380,
            paddingHorizontal: 26
        },
        category_image: {
            minWidth: 380,
            minHeight: 260,
        },
        notDummy: {
            cursor: "pointer",
            paddingVertical: 26
        },
        category_overlay: {
            position: "absolute",
            width: "100%",
            height: "100%",
            justifyContent: "center"
            // opacity: 0.65
        },
        category_text_bg: {
            alignSelf: "flex-end",
            minWidth: 178,
            maxWidth: 380,
            height: 64,
            opacity: 1,
            justifyContent: "center"
        },
        category_text: {
            color: "white",
            fontFamily: props.theme.fontList.primaryFontBold,
            fontSize: 24,
            letterSpacing: 2,
            alignSelf: "center",
            textTransform: "uppercase",
            paddingHorizontal: 8
        }
    });

    const orderHandler = new OrderHandler(state);
    if (state.customer.orderID.length > 0 && state.customer.orderID != state.currentOrder.id) {
        orderHandler.resumeOrder(state.customer.orderID, () => {
            if (state.currentOrder.id.length > 0 && state.currentOrder.items.length > 0) {
                setCartState(true)
            }
        });
    }

    let colorList = ["#73aa2d", "#ff5948", "#01bdea", "#0e2262", "#f5c5d1", "#ffa800"]

    let colorIterator = -1;

    function getColorFromList() {
        colorIterator++;
        if (colorIterator >= colorList.length) {
            colorIterator = 0;
        }
        return colorList[colorIterator];
    }

    function generateSquareCategory(category: IProductCategoryModel) {
        let bgColor = getColorFromList();
        return <View style={[styles.category_container, styles.notDummy]} key={"category_card_" + category.id}>
            <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={() => {
                state.searchString.setValue("");
                navigation.navigate("category", {id: category.id});
            }}>
                <View>
                    <ImageBackground style={styles.category_image} source={{uri: category.getImage()}}>
                        <View style={[styles.category_overlay, {backgroundColor: bgColor + "A6"}]}>
                            <View style={[styles.category_text_bg, {backgroundColor: bgColor}]}>
                                <Text style={styles.category_text}>{category.name.getTranslated()}</Text>
                            </View>
                        </View>
                    </ImageBackground>
                </View>
            </TouchableOpacity>
        </View>;
    }


    let dummyCategories = [];

    for (let i = 0; i < 8; i++) {
        dummyCategories.push(<View style={[styles.category_container]} key={"dummy_cat_" + i}/>)

    }

    let productPropositions: Array<IProductModel> = new Array<IProductModel>();
    for (let i = 0; i < state.productsStore.products.length; i++) {
        productPropositions.push(state.productsStore.products[i]);
    }

    return (
        <View style={styles.container}>
            <ScrollView style={{height: "100vh", paddingBottom: 32}}>
                <Header/>
                <View style={{zIndex: -1, alignSelf: "center"}}>
                    {/*<CategoriesList/>*/}

                    <View style={styles.categories_container}>
                        {state.categoriesStore.categories.map((category: IProductCategoryModel) => (
                            generateSquareCategory(category)
                        ))}
                        {dummyCategories}
                    </View>
                    <View style={{alignSelf: "center", paddingBottom: 48}}>
                        <ProductsGallery products={productPropositions} maxWidth={1150} marginHorizontal={14}/>
                    </View>
                    <View style={{width: "100vw", height: 650}}>
                        <Iframe url="https://cncmapdev.aji.digital/map"
                                width="100%"
                                id="myId"
                                height="100%"
                        />
                    </View>
                    <View style={{paddingHorizontal: 160, zIndex: -1, alignSelf: "center", marginTop: 40}}>
                        <ArticlesList maxArticles={3}/>
                    </View>
                </View>
                <View style={{zIndex: -1}}>
                    <Footer verticalMargin={50}/>
                    <Copyright/>
                </View>
            </ScrollView>
        </View>
    );
})

export default withTheme(HomePage);
