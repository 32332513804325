import { types, cast } from "mobx-state-tree";

const QrPopupModel = types
    .model({
        isPopupVisible: false,
        currentOrderID: ""
    })
    .actions(self => ({
        setIsPopupVisible(v: boolean){
            self.isPopupVisible = v;
        },
        clear(){
            self.isPopupVisible = false;
            self.currentOrderID = "";
        },
        setCurrentOrderItemID(v: string){
            self.currentOrderID = v;
        }
    })).views(self => ({
    }))

let initialState = QrPopupModel.create();

export const qrPopupStore = initialState;

