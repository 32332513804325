import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {ScrollView, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import i18n from '../translations/Translations';
import {observer} from "mobx-react-lite";
import {useMst} from "../models/RootModel";
import {IAddonModel} from "../models/AddonModel";
import Addon from "./Addon";
import {OrderHandler} from "../models/OrderModel";
import {useMediaQuery} from "react-responsive";

export interface AddonsPopupProps {
    theme: ReactNativePaper.Theme,
}

const AddonsPopup = observer((props: AddonsPopupProps) => {
    const state = useMst();
    const desktopVersion = useMediaQuery({minWidth: props.theme.desktopBreakpoint});

    function onClosePress() {
        state.addonsPopup.clear();
    }

    function addSelectedAddons() {
        const orderHandlerInstance = new OrderHandler(state);
        orderHandlerInstance.addAddons(state.addonsPopup.getSelectedAddons(), () => {
            state.addonsPopup.clear();
        })
    }

    const styles = StyleSheet.create({
        popup: {
            backgroundColor: "white",
            width: desktopVersion ? 850 : "90%",
            height: "80vh",
            alignSelf: "center",
            paddingVertical: 53,
            paddingHorizontal: 34
        },
        close_button: {
            position: "absolute",
            top: 20,
            right: 30
        },
        close_button_text: {
            fontFamily: props.theme.fontList.primaryFontBold,
            color: "#aaaaaa",
            fontSize: 36
        },
        title: {
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            fontSize: 30,
            alignSelf: "center",
            textTransform: "uppercase"
        },
        buttons_wrapper:{
            flexDirection: "row",
            alignSelf: "center",
            marginTop: 50
        },
        buttons: {
            paddingTop: 6,
            paddingHorizontal: 24,
            paddingBottom: 6,
        },
        buttons_text:{
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            fontSize: 16
        },
        cancel_button: {
            backgroundColor: "#6c6c6c",
        },
        cancel_button_text: {
            color: "#959595"
        },
        add_button: {
            backgroundColor: props.theme.colors.primary,
            marginLeft: 24
        },
        add_button_text: {
            color: "#fff"
        }
    });


    return (
        <View style={styles.popup}>
            <TouchableOpacity style={styles.close_button} onPress={onClosePress}>
                <Text style={styles.close_button_text}>×</Text>
            </TouchableOpacity>
            <Text style={styles.title}>{i18n.t("addons_modal.title")}</Text>
            <ScrollView style={{height: "70%"}}>
                {state.addonsPopup.addons.map((addon: IAddonModel) => (
                    <Addon key={addon.id} addon={addon}/>
                ))}
            </ScrollView>
            <View style={styles.buttons_wrapper}>
                <TouchableOpacity style={[styles.buttons, styles.cancel_button]} onPress={onClosePress} activeOpacity={0.75}>
                    <Text style={[styles.buttons_text, styles.cancel_button_text]}>{i18n.t("common.cancel")}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.buttons, styles.add_button]} onPress={addSelectedAddons}  activeOpacity={0.75}>
                    <Text style={[styles.buttons_text, styles.add_button_text]}>{i18n.t("common.add")}</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
});


export default withTheme(AddonsPopup);