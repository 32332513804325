import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import ProductsList from "./ProductsList";
import {IProductCategoryModel} from "../models/ProductCategoryModel";
import {useRoute} from "@react-navigation/native";
import ProductsListRestaurant from "./ProductsListRestaurant";
import {MediaQueryHelper} from "../helpers/MediaQueryHelper";

export interface ProductCategoryProps {
    theme: ReactNativePaper.Theme,
    category: IProductCategoryModel,
    maxProducts?: number,
    restaurantVersion?: boolean
}

const ProductCategory = observer((props: ProductCategoryProps) => {
    const state = useMst();
    const mediaQueryHelper = MediaQueryHelper();

    const styles = StyleSheet.create({
        container: {
            maxWidth: mediaQueryHelper.smallVersion ? "95vw" : 850,
            borderWidth: 1,
            borderColor: "black",
            borderStyle: "solid",
            // margin: 5,
            marginTop: 50,
            padding: 30
        },
        title: {
            color: props.theme.colors.primary,
            backgroundColor: "white",
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontBlack,
            fontSize: 20,
            position: "absolute",
            top: -15,
            left: 15,
            paddingLeft: 8,
            paddingRight: 8
        },
        category_image: {
            width: "100%",
            height: mediaQueryHelper.smallVersion ? 100 : 176,
            marginBottom: 30
        }
    });

    return (
        <View style={styles.container}>
            <Text nativeID={props.category.id.replaceAll("_", "")}
                  style={styles.title}>{props.category.name.getTranslated()}</Text>
            <Image style={styles.category_image} source={{uri: props.category.getImage()}}/>
            {props.restaurantVersion &&
            <ProductsListRestaurant products={props.category.getProducts(state.productsStore.products)}
                                    maxProducts={props.maxProducts} category={props.category}/>
            }
            {!props.restaurantVersion &&
            <ProductsList products={props.category.getProducts(state.productsStore.products)}
                          maxProducts={props.maxProducts} category={props.category}/>
            }
        </View>
    );
});


export default withTheme(ProductCategory);