import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {Text} from 'react-native-paper';
import {StyleSheet, Image, View, ImageBackground, TouchableOpacity} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {useMediaQuery} from "react-responsive";
import LanguagePicker from "./LanguagePicker";
import {useNavigation} from "@react-navigation/native";
import i18n from "../translations/Translations";
import {MediaQueryHelper} from "../helpers/MediaQueryHelper";

export interface HeaderProps {
    theme: ReactNativePaper.Theme,
    topMarginEnabled?: boolean
}

const ClientHeader = observer((props: HeaderProps) => {
        const state = useMst();
        const logo = state.clientStore.client.logo.length > 0 ? {uri: state.clientStore.client.getLogoImage()} : require('../../assets/logo/' + props.theme.logo);
        const locationIcon = require('../../assets/icons/pointer.png');
        const phoneIcon = require('../../assets/icons/phone.png');
        const clockIcon = require('../../assets/icons/clock.png');
        const navigation = useNavigation();
        const mediaQueryHelper = MediaQueryHelper();
        const styles = StyleSheet.create({
            standard_font: {
                fontFamily: props.theme.fontList.primaryFontMedium,
                fontSize: 16
            },
            container: {
                margin: "auto",
            },
            background_image: {
                width: "100%",
                height: 243
            },
            surface: {
                padding: 10,
                alignSelf: "flex-start",
                marginVertical: "auto",
                backgroundColor: "white"
            },
            info_container: {
                borderStyle: "solid",
                borderColor: "black",
                borderWidth: 1,
                flexDirection: "row",
                padding: 20,
            },
            logo_container: {},
            logo: {
                width: 200,
                maxWidth: 300,
                height: 130,
                resizeMode: "contain",
                alignSelf: "center"
            },
            client_name: {
                fontSize: 16,
                fontWeight: "600",
                fontFamily: props.theme.fontList.primaryFontMedium,
                alignSelf: "center"
            },
            client_data_view: {
                marginLeft: 30,
                marginVertical: "auto"
            },
            text_icon: {
                width: 20,
                height: 20,
                resizeMode: "contain",
                marginRight: 10
            },
            client_data_text: {
                paddingBottom: 10,
                flexDirection: "row"
            },
            language_picker: {
                position: "absolute",
                top: 10,
                right: mediaQueryHelper.smallVersion ? 5 : 36
            },
            account_btn_wrapper: {
                position: "absolute",
                top: 70,
                right: 50,
                padding: 10,
                backgroundColor: "white"
            },
            account_btn_wrapper_border: {
                borderStyle: "solid",
                borderWidth: 1,
                padding: 10
            },
            account_btn: {},
            account_btn_text: {
                fontFamily: props.theme.fontList.primaryFontMedium,
                color: props.theme.colors.primary,
                fontSize: 20
            },
            account_log_out: {
                fontFamily: props.theme.fontList.primaryFontBold,
                fontSize: 14,
                color: props.theme.colors.primary,
                marginVertical: 5
            }
        });

        function navigateToAccount() {
            if (state.account.loggedIn) {
                navigation.navigate("account");
            } else {
                navigation.navigate("login");
            }
        }

        return (
            <ImageBackground source={{uri: state.clientStore.client.getHeaderImage()}}
                             style={[styles.background_image, (!mediaQueryHelper.desktopVersion && props.topMarginEnabled) && {marginTop: 90}]}
                             imageStyle={{resizeMode: "cover"}}>
                {state.clientStore.client.name.length > 0 &&
                <View style={[styles.container, mediaQueryHelper.desktopVersion && {width: "70%"}]}>
                    <View style={styles.surface}>
                        <View style={[styles.info_container, !mediaQueryHelper.desktopVersion && {padding: 5, alignSelf: "center"}]}>
                            {mediaQueryHelper.desktopVersion &&
                            <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={() => {
                                navigation.navigate("home");
                            }}>
                                <View style={styles.logo_container}>
                                    <Image style={styles.logo} source={logo}/>
                                    <Text style={styles.client_name}>{state.clientStore.client.name}</Text>
                                </View>
                            </TouchableOpacity>
                            }
                            <View style={styles.client_data_view}>
                                {!mediaQueryHelper.desktopVersion &&
                                <Text style={styles.client_name}>{state.clientStore.client.name}</Text>
                                }
                                <View style={styles.client_data_text}>
                                    <Image style={styles.text_icon} source={locationIcon}/>
                                    <Text style={styles.standard_font}>{state.clientStore.client.address}</Text>
                                </View>
                                <View style={styles.client_data_text}>
                                    <Image style={styles.text_icon} source={phoneIcon}/>
                                    <Text
                                        style={styles.standard_font}>{state.clientStore.client.phoneNumber}{"\n"}{state.clientStore.client.email}</Text>
                                </View>
                                <View style={styles.client_data_text}>
                                    <Image style={styles.text_icon} source={clockIcon}/>
                                    <Text
                                        style={[styles.standard_font, {marginVertical: "auto"}]}>{state.clientStore.client.getTodayOpeningHour()}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                }
                <View style={styles.account_btn_wrapper}>
                    <View style={styles.account_btn_wrapper_border}>
                        <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity}
                                          style={styles.account_btn}
                                          onPress={navigateToAccount}>
                            <Text
                                style={styles.account_btn_text}>{state.account.loggedIn ? state.account.getFullName() : i18n.t("header.log_in")}</Text>
                        </TouchableOpacity>
                        {state.account.loggedIn &&
                        <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity} onPress={() => {
                            state.account.logOut();
                            state.customer.logOut();
                        }}>
                            <Text style={styles.account_log_out}>{i18n.t("common.log_out")}</Text>
                        </TouchableOpacity>
                        }
                    </View>
                </View>
                <View style={styles.language_picker}>
                    <LanguagePicker/>
                </View>
            </ImageBackground>
        );
    }
);


export default withTheme(ClientHeader);