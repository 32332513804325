import React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import Header from '../components/HomeHeader';
import {withTheme} from "react-native-paper";
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {useMediaQuery} from "react-responsive";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import ProductListSortingSearch from "../components/ProductListSortingSearch";
import {useNavigation, useRoute} from "@react-navigation/native";
import {IProductCategoryModel} from "../models/ProductCategoryModel";

export interface CategoryPageProps {
    theme: ReactNativePaper.Theme
}

interface routeParams {
    id: string
}

const CategoryPage = observer((props: CategoryPageProps) => {
    const state = useMst();
    const desktopVersion = useMediaQuery({minWidth: props.theme.desktopBreakpoint})

    const route = useRoute();
    const navigation = useNavigation();

    const routeParams = route.params as routeParams;
    if (!routeParams || !routeParams.id) {
        if (navigation.canGoBack()) {
            navigation.goBack();
        }
        return null;
    }

    const category = state.categoriesStore.categories.find((tmpCategory: IProductCategoryModel) => {
        return tmpCategory.id == routeParams.id;
    });

    if (!category) {
        if (navigation.canGoBack()) {
            navigation.goBack();
        }
        return null;
    }

    const styles = StyleSheet.create({
        container: {
            backgroundColor: "white"
        },
        modal: {
            elevation: 0
        },
        product_page: {
            paddingHorizontal: 160, flexDirection: "row", justifyContent: "center", zIndex: -1
        }
    });

    return (
        <View style={styles.container}>
            <ScrollView style={{height: "100vh", paddingBottom: 32}}>
                <Header/>
                <View style={{paddingHorizontal: 160, zIndex: -1}}>
                    <ProductListSortingSearch title={category.name.getTranslated()} category={category}/>
                </View>
                <View style={{zIndex: -1}}>
                    <Footer verticalMargin={50}/>
                    <Copyright/>
                </View>
            </ScrollView>
        </View>
    );
})

export default withTheme(CategoryPage);