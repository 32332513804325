import {Instance, types} from "mobx-state-tree";
import {useMst} from "./RootModel";
import i18n from "i18n-js";

export const SortFilterModel = types.model({
    key: "",
}).actions(self => ({
    setKey(key: string) {
        self.key = key;
    }
})).views(self => ({
    getTranslated(): string {
        return i18n.t("sort_filter_model."+self.key);
    }
}));

export interface ISortFilterModel extends Instance<typeof SortFilterModel> {
}