import React from 'react';
import {
    StyleSheet, TouchableOpacity,
    View
} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {withTheme} from "react-native-paper";
import {Text} from 'react-native-paper';
import {IBreadCrumbModel} from "../models/BreadCrumbModel";
import {useNavigation} from "@react-navigation/native";

export interface BreadCrumbsProps {
    theme: ReactNativePaper.Theme,
    breadCrumbs: IBreadCrumbModel[]
}

const BreadCrumbs = observer((props: BreadCrumbsProps) => {
    const state = useMst();
    const navigation = useNavigation();

    const styles = StyleSheet.create({
        container: {
            flexDirection: "row"
        },
        bread_crumb_view: {
            flexDirection: "row"
        },
        text: {
            fontSize: 10,
            fontFamily: props.theme.fontList.primaryFontDefault,
            textTransform: "uppercase",
            letterSpacing: 3
        }
    });

    function generateBreadCrumb(breadCrumb: IBreadCrumbModel, i: number, arr: IBreadCrumbModel[]) {
        let sign = " > "
        if (i == arr.length - 1) {
            sign = "";
        }
        return <View key={i} style={styles.bread_crumb_view}>
            <TouchableOpacity activeOpacity={0.85} onPress={() => {
                state.searchString.setValue("");
                console.log(breadCrumb.params);
                if (Object.keys(breadCrumb.params).length > 0) {
                    navigation.navigate(breadCrumb.routeName, breadCrumb.params);
                }else{
                    navigation.navigate(breadCrumb.routeName);
                }
            }}>
            <Text style={styles.text}>
                {breadCrumb.label}
            </Text>
        </TouchableOpacity>
            <Text style={styles.text}>
                {sign}
            </Text>
        </View>
    }

    return (
        <View style={styles.container}>
            {props.breadCrumbs.map((breadCrumb, i, arr) => (
                generateBreadCrumb(breadCrumb, i, arr)
            ))}
        </View>
    );
})

export default withTheme(BreadCrumbs);